export const I18N_STATE = 'i18n'

export const I18N_ACTIONS = {
  SET: `${I18N_STATE}/set`,
}

export const AUTH_STATE = 'auth'

export const AUTH_ACTIONS = {
  SET: `${AUTH_STATE}/set`,
  CLEAR: `${AUTH_STATE}/clear`,
}

export const PERSIST_STATE = 'persist'

export const PERSIST_ACTIONS = {
  SET: `${PERSIST_STATE}/set`,
  CLEAR: `${PERSIST_STATE}/clear`,
}

export const MODAL_STATE = 'modal'

export const MODAL_TYPES = {
  DELETE_TAG_GLOBALLY: 'deleteTagGlobally',
  LOCK_MILESTONE: 'lockMilestone',
  UNLOCK_MILESTONE: 'unlockMilestone',
  ADD_PARTICIPANTS: 'addParticipants',
}

export const MODAL_ACTIONS = {
  OPEN_DELETE_TAG_GLOBALLY: `${MODAL_STATE}/deleteTagGlobally`,
  LOCK_MILESTONE: `${MODAL_STATE}/lockMilestone`,
  UNLOCK_MILESTONE: `${MODAL_STATE}/unlockMilestone`,
  ADD_PARTICIPANTS: `${MODAL_STATE}/addParticipants`,
  CLOSE: `${MODAL_STATE}/close`,
}
