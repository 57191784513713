import { useCallback } from 'react'

import { StoreonModule } from 'storeon'
import { useStoreon } from 'storeon/react'

import isBoolean from 'lodash/isBoolean'
import isEqual from 'lodash/isEqual'
import pickBy from 'lodash/pickBy'

import { validLanguages } from 'Services/I18n'

import { Themes, themes } from 'Theme/v2'

const KEY = 'ui'

interface StateValues {
  isChatFullscreen: boolean
  isChatOpened: boolean
  isFavoritesProjectsOnly?: boolean
  isHelpWidgetOpened: boolean
  isNotificationBannerClosed?: boolean
  isProjectNavigationOpened: boolean
  isSidebarOpened: boolean
  isTaskWidgetOpened: boolean
  isTextTyping: boolean
  isTrackerCollapsed: boolean
  locale?: string
  taskWidgetId?: string
  theme: keyof Themes
}

export interface State {
  [KEY]: StateValues
}

export interface Events {
  updateUi: Partial<StateValues>
  clear: void
}

const INITIAL_STATE: StateValues = {
  isChatFullscreen: false,
  isChatOpened: true,
  isFavoritesProjectsOnly: false,
  isHelpWidgetOpened: false,
  isProjectNavigationOpened: true,
  isSidebarOpened: true,
  isTaskWidgetOpened: false,
  isTextTyping: false,
  isTrackerCollapsed: false,
  theme: 'default',
}

export const uiModule: StoreonModule<State, Events> = store => {
  store.on('@init', () => ({
    [KEY]: INITIAL_STATE,
  }))

  store.on('updateUi', (state, variables) => {
    const values = pickBy(variables, (value, name) => {
      switch (name) {
        case 'locale':
          return validLanguages.includes(value as string)
        case 'theme':
          return Object.keys(themes).includes(value as keyof Themes)
        default:
          return isBoolean(value)
      }
    })

    const nextUi = { ...state[KEY], ...values }

    if (isEqual(nextUi, state[KEY])) return null

    return { [KEY]: nextUi }
  })

  store.on('clear', () => ({
    [KEY]: INITIAL_STATE,
  }))
}

function useUI() {
  const { [KEY]: uiState, dispatch } = useStoreon<State, Events>(KEY)

  const setSidebarOpened = useCallback(
    (opened: boolean) => {
      dispatch('updateUi', { isSidebarOpened: opened })
    },
    [dispatch],
  )

  const setProjectNavigationOpened = useCallback(
    (opened: boolean) => {
      dispatch('updateUi', { isProjectNavigationOpened: opened })
    },
    [dispatch],
  )

  const setTrackerCollapsed = useCallback(
    (collapsed: boolean) => {
      dispatch('updateUi', { isTrackerCollapsed: collapsed })
    },
    [dispatch],
  )

  const setChatFullscreen = useCallback(
    (fullscreen: boolean) => {
      dispatch('updateUi', { isChatFullscreen: fullscreen })
    },
    [dispatch],
  )

  const setTheme = useCallback(
    (theme: keyof Themes) => {
      dispatch('updateUi', { theme })
    },
    [dispatch],
  )

  const setChatOpened = useCallback(
    (opened: boolean) => {
      dispatch('updateUi', {
        isChatOpened: opened,
        isTaskWidgetOpened: false,
        isHelpWidgetOpened: false,
      })
    },
    [dispatch],
  )

  const setHelpWidgetOpened = useCallback(
    (opened: boolean) => {
      dispatch('updateUi', {
        isTaskWidgetOpened: false,
        isHelpWidgetOpened: opened,
        isChatOpened: false,
      })
    },
    [dispatch],
  )

  const setTaskWidgetOpened = useCallback(
    (opened: boolean) => {
      dispatch('updateUi', {
        isTaskWidgetOpened: opened,
        isHelpWidgetOpened: false,
        isChatOpened: false,
      })
    },
    [dispatch],
  )

  const setTextTyping = useCallback(
    (typing: boolean) => {
      dispatch('updateUi', { isTextTyping: typing })
    },
    [dispatch],
  )

  const setNotificationBannerClosed = useCallback(
    (isClosed: boolean) => {
      dispatch('updateUi', { isNotificationBannerClosed: isClosed })
    },
    [dispatch],
  )

  const setFavoritesProjectsOnly = useCallback(
    (isFavoritesOnly: boolean) => {
      dispatch('updateUi', { isFavoritesProjectsOnly: isFavoritesOnly })
    },
    [dispatch],
  )

  return {
    ui: uiState,
    setChatFullscreen,
    setChatOpened,
    setHelpWidgetOpened,
    setNotificationBannerClosed,
    setProjectNavigationOpened,
    setSidebarOpened,
    setTaskWidgetOpened,
    setTextTyping,
    setTheme,
    setTrackerCollapsed,
    setFavoritesProjectsOnly,
  }
}

export default uiModule
export { KEY, useUI }
