import Address from './address'
import Arrays from './array'
import Colors from './colors'
import Components from './components'
import Currency from './currency'
import Dates from './dates'
import Dom from './dom'
import Errors from './errors'
import Files from './files'
import Form from './form'
import LocalStorage from './localStorage'
import Location from './location'
import Mutations from './mutations'
import NumberFormatter from './numberFormatting'
import Numbers from './numbers'
import Percent from './percent'
import Period from './period'
import Price from './price'
import Promises from './promises'
import Reactions from './reactions'
import Routes from './routes'
import SessionStorage from './sessionStorage'
import Strings from './strings'
import Time from './time'
import URLs from './urls'
import UserProfile from './userProfile'

export default {
  Address,
  Arrays,
  Colors,
  Components,
  Currency,
  Dates,
  Dom,
  Errors,
  Files,
  Form,
  LocalStorage,
  Location,
  Mutations,
  NumberFormatter,
  Numbers,
  Percent,
  Period,
  Price,
  Promises,
  Reactions,
  Routes,
  SessionStorage,
  Strings,
  Time,
  URLs,
  UserProfile,
}
