import React from 'react'

import { Span } from 'Components/UI/_v2/Text'

import { useScopedI18n } from 'Services/I18n'

function Optional() {
  const s = useScopedI18n('clientsPage')

  return (
    <Span fontWeight="500" muted>
      ({s('optional')})
    </Span>
  )
}

export default Optional
