import React from 'react'

import { X } from 'phosphor-react'

import { CircleButton } from 'Components/UI/_v2/Buttons'

import {
  Header,
  MainIconHolder,
  ModalButtons,
  StyledModal,
  StyledModalProps,
  Title,
} from './styles'

interface Props extends Omit<StyledModalProps, 'isOpen' | 'overlayClassName'> {
  closeButtonDisabled?: boolean
  danger?: boolean
  isOpen?: boolean
  mainIcon?: React.ReactNode
  primary?: boolean
  withHeader?: boolean
  noPadding?: boolean
  success?: boolean
  title?: React.ReactNode
  warning?: boolean
  withoutCloseButton?: boolean
  onAfterOpen?: () => void
  onClose?: () => void
}

const rootElement =
  (window && window.document && window.document.getElementById('root')) ??
  undefined

function Modal({
  closeButtonDisabled,
  children,
  isOpen = false,
  shouldCloseOnOverlayClick,
  title,
  mainIcon,
  danger,
  primary,
  noPadding = false,
  withHeader = true,
  success,
  warning,
  withoutCloseButton,
  onAfterOpen,
  onClose,
}: Props) {
  return (
    <StyledModal
      appElement={rootElement}
      isOpen={isOpen}
      noPadding={noPadding}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      onAfterOpen={onAfterOpen}
      onRequestClose={onClose}
    >
      {withHeader && (
        <Header>
          <Title>{title}</Title>
          {!withoutCloseButton && (
            <CircleButton
              disabled={closeButtonDisabled}
              secondary
              onClick={onClose}
            >
              <X weight="bold" />
            </CircleButton>
          )}
        </Header>
      )}

      {mainIcon && (
        <MainIconHolder
          danger={danger}
          mt={!title ? '-44px' : '-10px'}
          primary={primary}
          success={success}
          warning={warning}
        >
          {mainIcon}
        </MainIconHolder>
      )}
      {children}
    </StyledModal>
  )
}

Modal.Buttons = ModalButtons

export default Modal
