import React, { Component } from 'react'
import PropTypes from 'prop-types'

import * as Sentry from '@sentry/react'

import { IS_PRODUCTION, IS_STAGING } from 'Config/app'

import { Container, OpenReport, Panel } from './styles'

class ErrorBoundary extends Component {
  state = { hasError: false, eventId: null }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    if (error.name === 'ChunkLoadError') {
      window.location.reload()
    } else if (IS_PRODUCTION || IS_STAGING) {
      Sentry.withScope(scope => {
        scope.setExtras(errorInfo)
        const eventId = Sentry.captureException(error)
        this.setState({ eventId })
      })
    }
  }

  handleReport = () => {
    const { eventId } = this.state

    Sentry.showReportDialog({ eventId })
  }

  render() {
    const { hasError } = this.state
    const { children } = this.props

    return hasError ? (
      <Container>
        <Panel>
          <h4>Hmmm... Something gone wrong.</h4>
          <p>
            Our team has been notified, but you can{' '}
            <OpenReport onClick={this.handleReport}>click here</OpenReport> fill
            out a report.
          </p>
        </Panel>
      </Container>
    ) : (
      children
    )
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ErrorBoundary
