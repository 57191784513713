import React from 'react'
import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { Flex } from 'rebass'

import { loadingGlyph } from 'Assets/Svg'

import Icon from '../Icon'

const containerRelativeCss = ({ relative }) =>
  relative &&
  css`
    position: relative;
  `

export const Container = styled(Flex)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 64px;
  height: 64px;
  border-radius: 32px;
  justify-content: center;
  align-items: center;

  svg {
    stroke: ${themeGet('colors.aluminium')};
  }

  ${containerRelativeCss}
`
function Loader({ size, relative }) {
  return (
    <Container relative={relative}>
      <Icon glyph={loadingGlyph} size={size} />
    </Container>
  )
}

Loader.displayName = 'Loader'

Loader.defaultProps = {
  relative: false,
  size: 40,
}
Loader.propTypes = {
  relative: PropTypes.bool,
  size: PropTypes.number,
}

export default Loader
