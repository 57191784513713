import styled, { css } from 'styled-components'
import { flexbox, layout, LayoutProps } from 'styled-system'

import { authImage } from 'Assets/Images'

import { themeGet } from 'Theme/v2'

type ContainerProps = {
  electron?: boolean
}
const electronCss = ({ electron }: ContainerProps) =>
  electron &&
  css`
    padding-top: ${themeGet('sizes.electronHeaderHeight')}px;
  `

export const Container = styled.section.attrs({
  flexDirection: ['column', 'column', 'column', 'row'],
})<ContainerProps>`
  position: relative;
  display: flex;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  background-color: ${themeGet('colors.background.block')};
  margin-top: -2px;
  transition: all ${themeGet('defaultTransitionTime')};

  ${flexbox}

  ${electronCss}
`

export const Content = styled.div.attrs({
  width: ['100%', '100%', '100%', '50%'],
})`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  flex-shrink: 0;
  height: 100dvh;

  ${layout}
`

export const FormHolder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

export const Background = styled.div.attrs({
  display: ['none', 'none', 'none', 'block'],
})<LayoutProps>`
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  background: url(${authImage}) no-repeat;
  background-size: cover;

  ${layout}
`
