import { LOGIN } from 'Constants/paths'

import meQuery from 'Graphql/Queries/me.graphql'

import shared from 'Services/Shared'
import { STORE_CLEAR } from 'Services/Store'
import { setAuth } from 'Services/Store/Modules/auth'

async function signOut() {
  const store = shared.getStore()
  await store.dispatch(STORE_CLEAR)

  const client = shared.getApolloClient()
  await client.stop()
  await client.resetStore()

  window.location.replace(LOGIN)
}

async function signIn({
  accessToken,
  refreshToken,
  withRefresh,
  refetch,
}: {
  accessToken?: string
  refreshToken?: string
  withRefresh?: boolean
  refetch?: boolean
}) {
  const client = shared.getApolloClient()

  setAuth({ accessToken, refreshToken, rememberMe: withRefresh })

  if (refetch) await client.query({ query: meQuery })
}

export default {
  signIn,
  signOut,
}
