import React from 'react'

import { X } from 'phosphor-react'

import { Button, CircleButton } from 'Components/UI/_v2/Buttons'
import { Row } from 'Components/UI/_v2/Flex'

import { _ } from 'Services/I18n'

import {
  ChildrenHolder,
  MainIconHolder,
  StyledModal,
  StyledModalProps,
  Title,
} from './styles'

type Props = StyledModalProps & {
  actionText?: string
  danger?: boolean
  icon?: React.ReactNode
  isOpen?: boolean
  title?: React.ReactNode
  onAction?: () => void
  onClose?: () => void
}

const rootElement =
  (window && window.document && window.document.getElementById('root')) ??
  undefined

function Modal({
  actionText,
  children,
  isOpen = false,
  shouldCloseOnOverlayClick,
  title,
  icon,
  danger,
  onAction,
  onClose,
}: Props) {
  return (
    <StyledModal
      appElement={rootElement}
      isOpen={isOpen}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      onRequestClose={onClose}
    >
      <CircleButton secondary onClick={onClose}>
        <X weight="bold" />
      </CircleButton>

      {title && (
        <Row center mb={6}>
          <Title>{title}</Title>
        </Row>
      )}

      {icon && (
        <MainIconHolder danger={danger} mb={4}>
          {icon}
        </MainIconHolder>
      )}

      <ChildrenHolder>{children}</ChildrenHolder>

      <Row fullWidth gap={4} mt={6}>
        <Button secondary width={1} onClick={onClose}>
          {_('cancel')}
        </Button>
        <Button danger={danger} width={1} onClick={onAction}>
          {actionText}
        </Button>
      </Row>
    </StyledModal>
  )
}

export default Modal
