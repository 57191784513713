import React, { useCallback, useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player'
import { useResizeDetector } from 'react-resize-detector'

import playAltGlyph from 'Assets/Svg/play-alt.svg'

import { Button, Icon, Modal, Text } from 'Components/UI/_v2'

import { EXTERNAL_LINKS } from 'Constants/ids'

import { Container, Content, TextHolder, VideoHolder } from './styles'

const VIDEO_RATIO = 1.777777777777778
const VIDEO_WIDTH = 2560

function IntroVideo() {
  const { width: resizeDetectorWidth, ref: resizeDetectorRef } =
    useResizeDetector()

  const videoHolderRef = useRef<HTMLDivElement>(null)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [playerWidth, setPlayerWidth] = useState(VIDEO_WIDTH)

  const handleModalOpen = useCallback(() => {
    setPlayerWidth(videoHolderRef.current?.offsetWidth || VIDEO_WIDTH)
  }, [])

  useEffect(() => {
    setPlayerWidth(videoHolderRef?.current?.offsetWidth || VIDEO_WIDTH)
  }, [resizeDetectorWidth])

  return (
    <Container ref={resizeDetectorRef}>
      <Content>
        <Button height={56} width={56} onClick={() => setIsModalOpen(true)}>
          <Icon glyph={playAltGlyph} />
        </Button>

        <TextHolder>
          <Text muted subheader1>
            Check our short demo overview of the Worklair
          </Text>
        </TextHolder>
      </Content>

      <Modal
        isOpen={isModalOpen}
        onAfterOpen={handleModalOpen}
        onClose={() => setIsModalOpen(false)}
      >
        <VideoHolder
          height={playerWidth / VIDEO_RATIO}
          ref={videoHolderRef}
          width="80dvw"
        >
          <ReactPlayer
            height="100%"
            url={EXTERNAL_LINKS.INTRO_VIDEO_URL}
            width="100%"
          />
        </VideoHolder>
      </Modal>
    </Container>
  )
}

export default IntroVideo
