export type HelpCenterSection = {
  id: string
  title: string
  slug: string
  text?: string
  path?: string
  articles: HelpCenterArticle[]
  sections: HelpCenterSection[]
}
export type HelpCenterSectionBySlug = {
  [slug: string]: HelpCenterSection
}

export type TargetHelpCenterNode = {
  article?: HelpCenterArticle
  section?: HelpCenterSection
  adjacentNodes?: [HelpCenterArticle | HelpCenterSection]
}

export type HelpCenterArticleVideoLink = {
  providerUid?: string
}

export type HelpCenterArticle = {
  id: string
  title: string
  slug: string
  text: string
  videoLink?: HelpCenterArticleVideoLink
  path?: string
  parentSectionTitle?: string
  _updatedAt: string
}
export type HelpCenterArticleBySlug = {
  [slug: string]: HelpCenterArticle
}

export type HelpCenterProductUpdate = {
  id: string
  date: string
  title: string
  articleLink: {
    id: string
    slug: string
  }
  section: {
    id: string
    slug: string
  }
}

export type HelpCenterParams = {
  section: string
  article: string
}

export enum TableOfContentKind {
  Primary = 'primary',
  Secondary = 'secondary',
}

export enum HelpCenterSearchInputKind {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
}

export const HELP_CENTER_SECTION_SLUGS = {
  helpCenter: 'help-center',
  gettingStarted: 'getting-started',
  knowledgeBase: 'knowledge-base',
  videoTutorials: 'video-tutorials',
  productUpdates: 'knowledge-base/product-updates',
}

export const HELP_CENTER_RECENTLY_ADDED_COUNT = 5
export const HELP_CENTER_PRODUCT_UPDATES_COUNT = 5

export const HELP_CENTER_URL = {
  COMMUNITY:
    'https://community.worklair.io/join?invitation_token=dd30e66d1c7601fea16ebf2c9792903b20df6e16-6ee28d29-adb2-4bdf-b5b2-540851a903ba',
  REQUEST: 'https://worklair.canny.io/',
}
