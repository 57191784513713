import ReactSelect, { Props as ReactSelectProps } from 'react-select'
import ReactSelectAsync from 'react-select/async'
import ReactSelectAsyncCreatable from 'react-select/async-creatable'
import ReactSelectCreatable from 'react-select/creatable'

import styled from 'styled-components'
import { layout, LayoutProps, margin, MarginProps } from 'styled-system'

import { ComponentVariants, createGetParam, themeGet } from 'Theme/v2'

import {
  ControlWrapper,
  Indicators,
  ValueContainerWrapper,
} from './Components/styles'

export interface SelectVariantProps {
  danger?: boolean
}

type SelectVariants = ComponentVariants<SelectVariantProps>

export type WrapperProps = MarginProps & LayoutProps

export interface SelectParams {
  'border-color': SelectVariants
}

export type SizeProps = {
  small?: boolean
  medium?: boolean
  large?: boolean
}

const sizes = {
  fontSize: {
    large: themeGet('fontSizes.2'),
    default: themeGet('fontSizes.1'),
    small: themeGet('fontSizes.0'),
  },
  indicatorPadding: {
    large: '4px',
    default: '4px',
    small: '2px',
  },
  valueContainerPadding: {
    large: '0 12px',
    default: '0 10px',
    small: '0 8px',
  },
  controlWrapperMinHeight: {
    large: '48px',
    default: '40px',
    small: '32px',
  },
  labelFontSize: {
    large: themeGet('fontSizes.2'),
    default: themeGet('fontSizes.1'),
    small: themeGet('fontSizes.0'),
  },
}

export type StyledSelectProps = ReactSelectProps &
  LayoutProps &
  MarginProps &
  SelectVariantProps &
  SizeProps & {
    async?: boolean
    creatable?: boolean
    className?: string
    classNamePrefix?: string
    // TODO: workaround
    onChange?: any
    // --
  }

const params: SelectParams = {
  'border-color': {
    default: themeGet('colors.divider.default'),
    danger: themeGet('colors.danger500'),
  },
}

const getParam = createGetParam<SelectParams, SelectVariantProps>(params)
export const getSizeParam = createGetParam<typeof sizes, SizeProps>(sizes)

function getSelectType({ async, creatable }: StyledSelectProps) {
  if (async && creatable) {
    return ReactSelectAsyncCreatable
  }
  if (async) {
    return ReactSelectAsync
  }
  if (creatable) {
    return ReactSelectCreatable
  }

  return ReactSelect
}

export const StyledSelect = styled.div.attrs(
  ({
    async,
    creatable,
    className = 'react-select-container',
    classNamePrefix = 'react-select',
  }: StyledSelectProps) => ({
    as: getSelectType({ async, creatable }),
    className,
    classNamePrefix,
  }),
)<StyledSelectProps>`
  font-size: ${getSizeParam('fontSize')}px;

  // These styles applies only for child components, not working for items inside PORTAL
  ${ControlWrapper} {
    border-color: ${getParam('border-color')} !important;
  }

  ${Indicators} {
    padding: ${getSizeParam('indicatorPadding')};
  }

  ${ValueContainerWrapper} {
    padding: ${getSizeParam('valueContainerPadding')} !important;
  }

  ${ControlWrapper} {
    &.react-select__control {
      min-height: ${getSizeParam('controlWrapperMinHeight')};
    }
  }

  ${layout};
`

export const Wrapper = styled.div<WrapperProps>`
  width: 100%;

  ${layout};
  ${margin};
  ${layout};
`

export const Caption = styled.div<MarginProps>`
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 12px;
  color: ${themeGet('colors.danger400')};
  ${margin};
`

export const Label = styled.div<MarginProps & SizeProps>`
  user-select: none;
  cursor: text;
  font-weight: ${themeGet('fontWeights.2')};
  color: ${themeGet('colors.text.heading')};
  font-size: ${getSizeParam('labelFontSize')}px;
  ${margin};
`
