import styled, { css } from 'styled-components'
import { styleFn } from 'styled-system'

import { themeGet } from 'Theme/v2'

type ContainerProps = {
  secondary?: boolean
  tertiary?: boolean
}

type DropdownRowProps = {
  interactive?: boolean
}

const containerSecondaryCss: styleFn = ({ secondary }: ContainerProps) =>
  secondary &&
  css`
    background-color: ${themeGet('colors.background.block')};
    height: 48px;
  `

const containerTertiaryCss: styleFn = ({ tertiary }: ContainerProps) =>
  tertiary &&
  css`
    background-color: ${themeGet('colors.background.block')};
    height: 40px;
    padding-inline: ${themeGet('space.4')}px;
    flex-direction: row-reverse;
    gap: ${themeGet('space.4')}px;
    flex-shrink: 0;

    > input {
      font-size: ${themeGet('fontSizes.1')}px;
    }

    > svg {
      width: 16px;
      height: 16px;
    }
  `

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 64px;
  width: 100%;
  border-radius: 6px;
  background-color: ${themeGet('colors.neutral900')};
  border: 1px solid ${themeGet('colors.divider.default')};
  padding-inline: ${themeGet('space.7')}px;

  &:hover {
    border-color: ${themeGet('colors.neutral100')};
  }

  ${containerSecondaryCss}
  ${containerTertiaryCss}
`

export const StyledInput = styled.input`
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
  color: ${themeGet('colors.text.heading')};
  font-weight: ${themeGet('fontWeights.1')};
  font-size: ${themeGet('fontSizes.2')}px;
  border: none;
  background: none;

  &::placeholder {
    color: ${themeGet('colors.text.muted')};
  }
`

export const Dropdown = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -12px;
  transform: translateY(100%);
  border-radius: 10px;
  background-color: ${themeGet('colors.background.block')};
  box-shadow: ${themeGet('shadow.large')};
  overflow: hidden;
  z-index: 11;
`

const dropdownRowInteractiveCss: styleFn = ({
  interactive,
}: DropdownRowProps) =>
  interactive &&
  css`
    &:hover {
      background-color: ${themeGet('colors.primary900')};
    }
  `

export const DropdownRow = styled.button<DropdownRowProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  padding: 0 ${themeGet('space.4')}px;
  height: 48px;
  width: 100%;
  border: none;
  background-color: transparent;
  cursor: pointer;

  ${dropdownRowInteractiveCss}
`

export const StyledSpan = styled.span`
  background-color: ${themeGet('colors.primary800')};
`
