import MaskedInput from 'react-text-mask'

import styled, { css } from 'styled-components'
import {
  layout,
  LayoutProps,
  margin,
  MarginProps,
  space,
  SpaceProps,
  styleFn,
} from 'styled-system'

import { CurrencyDollarSimple } from 'phosphor-react'

import { createGetParam, themeGet } from 'Theme/v2'

type InputProps = SpaceProps

export const StyledMaskedInput = styled(MaskedInput)<InputProps>`
  position: relative;
  margin: 0;
  border: none;
  outline: none;
  appearance: none;
  background: transparent;
  color: ${themeGet('colors.text.heading')};
  width: 100%;

  ::placeholder {
    color: ${themeGet('colors.text.muted')};
  }

  ${space}
`

export type VariantProps = {
  active?: boolean
  success?: boolean
  danger?: boolean
  disabled?: boolean
}

export type SizeProps = {
  small?: boolean
  medium?: boolean
  large?: boolean
}

export type ContainerProps = VariantProps & SizeProps

export type WrapperProps = ContainerProps & MarginProps & LayoutProps

const variants = {
  borderColor: {
    default: themeGet('colors.divider.default'),
    active: themeGet('colors.primary500'),
    success: themeGet('colors.success500'),
    danger: themeGet('colors.danger500'),
  },
  captionColor: {
    default: themeGet('colors.text.muted'),
    success: themeGet('colors.success400'),
    danger: themeGet('colors.danger400'),
  },
}

const sizes = {
  minHeight: {
    large: 48,
    default: 40,
    small: 32,
  },
  padding: {
    large: 12,
    default: 10,
    small: 8,
  },
  labelFontSize: {
    large: themeGet('fontSizes.2'),
    default: themeGet('fontSizes.1'),
    small: themeGet('fontSizes.0'),
  },
  inputFontSize: {
    large: themeGet('fontSizes.2'),
    default: themeGet('fontSizes.1'),
    small: themeGet('fontSizes.0'),
  },
}

const getSizeParam = createGetParam<typeof sizes, SizeProps>(sizes)
const getParam = createGetParam<typeof variants, VariantProps>(variants)

const disabledCss: styleFn = ({ disabled }: ContainerProps) =>
  disabled &&
  css`
    color: ${themeGet('colors.text.muted')};
    input {
      color: ${themeGet('colors.text.muted')};
    }
  `

const hoverCss: styleFn = ({ active }: ContainerProps) =>
  !active &&
  css`
    :hover {
      border-color: ${themeGet('colors.divider.contrast')} !important;
    }
  `

export const Label = styled.div<MarginProps & SizeProps>`
  user-select: none;
  cursor: text;
  font-weight: ${themeGet('fontWeights.2')};
  color: ${themeGet('colors.text.heading')};
  font-size: ${getSizeParam('labelFontSize')}px;
  ${margin};
`

export const Caption = styled.div<
  MarginProps & {
    danger?: boolean
    success?: boolean
  }
>`
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 12px;
  color: ${getParam('captionColor')};

  ${margin};

  > svg {
    fill: ${getParam('captionColor')};
  }
`

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;

  & input {
    font-size: ${getSizeParam('inputFontSize')}px;
  }

  ${margin}
  ${layout}
`

export const Container = styled.div<ContainerProps>`
  display: flex;
  border: 1px solid ${getParam('borderColor')};
  border-radius: 6px;
  align-items: center;
  padding: ${getSizeParam('padding')}px;
  min-height: ${getSizeParam('minHeight')}px;

  &:disabled {
    cursor: default;
  }

  svg {
    fill: ${themeGet('colors.text.body')};
  }
  ${disabledCss};
  ${hoverCss};
`

export const DollarIcon = styled(CurrencyDollarSimple)<MarginProps>`
  color: ${themeGet('colors.text.body')};
  flex-shrink: 0;

  ${margin};
`
