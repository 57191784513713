const isChildOf = (childNode, parentNode) => {
  if (childNode.parentNode === parentNode) {
    return true
  }
  if (childNode.parentNode === null) {
    return false
  }
  return isChildOf(childNode.parentNode, parentNode)
}

export default { isChildOf }
