import { DateTime } from 'luxon'
import Cookies from 'universal-cookie'

import { APP_ENV, COOKIE } from 'Config/app'

const defaultOptions = {
  path: '/',
  expires: DateTime.local().plus({ year: 1 }).toJSDate(),
}

defaultOptions.domain = COOKIE.SHARED_DOMAIN

if (APP_ENV !== 'development') {
  defaultOptions.secure = true
}

const keyWithPrefix = key => `${COOKIE.PREFIX}${key}`

let cookie = new Cookies()

function reinitialize(cookies) {
  cookie = new Cookies(cookies)
}

function setItem(key, value) {
  cookie.set(keyWithPrefix(key), value, defaultOptions)
}

function getItem(key) {
  return cookie.get(keyWithPrefix(key), { doNotParse: true })
}

export const CookieStorage = { setItem, getItem, reinitialize }
