// @ts-strict-ignore
import React, { useMemo } from 'react'

import { CaretLeft, CaretRight } from 'phosphor-react'

import noop from 'lodash/noop'

import { Row, Text } from 'Components/UI/_v2'

import { useScopedI18n } from 'Services/I18n'

import { Container, ContainerProps, NavButton, PageButton } from './styles'

type Props = ContainerProps & {
  currentPage?: number
  itemsCount?: number
  itemsPerPage?: number
  maxPagesVisible?: number
  onPageClick?: (pageNumber: number) => void
  onPrevClick?: () => void
  onNextClick?: () => void
}

function Pagination({
  currentPage = 0,
  itemsCount,
  itemsPerPage,
  large,
  maxPagesVisible = 5,
  small,
  onNextClick,
  onPageClick,
  onPrevClick,
  ...rest
}: Props) {
  const s = useScopedI18n('blocks.pagination')

  const items = useMemo(() => {
    const pagesCount = Math.ceil(itemsCount / itemsPerPage)

    const length = maxPagesVisible < pagesCount ? maxPagesVisible : pagesCount

    const startIndex =
      currentPage < length / 2 ? 0 : Math.ceil(currentPage - length / 2)

    const increment =
      currentPage + length >= pagesCount ? pagesCount - length : startIndex

    return Array.from({ length }, (_, index) => index + increment)
  }, [currentPage, itemsCount, itemsPerPage, maxPagesVisible])

  if (itemsCount < itemsPerPage) return null

  const lastPageIndex = Math.floor(itemsCount / itemsPerPage)

  return (
    <Container {...rest}>
      <NavButton
        disabled={currentPage === 0}
        onClick={currentPage === 0 ? noop : onPrevClick}
      >
        <CaretLeft size={large ? 20 : 16} weight="bold" />
        <Text action3={large} action4={!large}>
          {s('prev')}
        </Text>
      </NavButton>

      <Row>
        {items.map(page => (
          <PageButton
            active={page === currentPage}
            key={page}
            large={large}
            small={small}
            onClick={() => onPageClick(page)}
          >
            <Text action3={large} action4={!large && !small} action5={small}>
              {page + 1}
            </Text>
          </PageButton>
        ))}
      </Row>

      <NavButton
        disabled={currentPage === lastPageIndex}
        onClick={currentPage !== lastPageIndex ? onNextClick : noop}
      >
        <Text action3={large} action4={!large}>
          {s('next')}
        </Text>
        <CaretRight size={large ? 20 : 16} weight="bold" />
      </NavButton>
    </Container>
  )
}

export default Pagination
