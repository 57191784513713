import React, { ComponentProps } from 'react'

import { Container, ProgressRing } from './styles'

type Props = ComponentProps<typeof Container> & {
  progress?: number
}

function CircleProgressBar({ progress, ...rest }: Props) {
  return (
    <Container {...rest}>
      <ProgressRing progress={progress} />
    </Container>
  )
}

export default CircleProgressBar
