import styled, { css } from 'styled-components'
import { space, SpaceProps, styleFn } from 'styled-system'

import Tippy from '@tippyjs/react'
import { CaretDoubleRight } from 'phosphor-react'

import { createGetParam, themeGet } from 'Theme/v2'

export interface SizeProps {
  small?: boolean
  large?: boolean
}

interface StyledDropdownProps extends SizeProps, SpaceProps {
  active: boolean
  disabled?: boolean
}

interface ChevronProps extends SizeProps {
  open?: boolean
}

const sizes = {
  fontSize: {
    large: themeGet('fontSizes.2'),
    default: themeGet('fontSizes.1'),
    small: themeGet('fontSizes.0'),
  },
  lineHeight: {
    large: 24,
    default: 20,
    small: 12,
  },
  paddingY: {
    large: 12,
    default: 8,
    small: 8,
  },
  paddingX: {
    large: 16,
    default: 12,
    small: 8,
  },
  maxHeight: {
    large: 48,
    default: 36,
    small: 32,
  },
  chevronIconFontSize: {
    large: themeGet('fontSizes.4'),
    default: themeGet('fontSizes.3'),
    small: themeGet('fontSizes.2'),
  },
}

const getSizeParam = createGetParam<typeof sizes, SizeProps>(sizes)

const chevronIconOpenCss: styleFn = ({ open }: ChevronProps) =>
  open &&
  css`
    transform: rotate(90deg);
  `

export const ChevronIcon = styled(CaretDoubleRight)<ChevronProps>`
  transition: all ${themeGet('defaultTransitionTime')};
  font-size: ${getSizeParam('chevronIconFontSize')}px;

  ${chevronIconOpenCss}
`

const dropdownActiveCss: styleFn = ({ active }: StyledDropdownProps) =>
  active &&
  css`
    background-color: ${themeGet('colors.primary500')};
    color: ${themeGet('colors.white')};
    &:hover {
      color: ${themeGet('colors.white')};
      & svg {
        fill: ${themeGet('colors.white')};
      }
    }

    &:focus-within {
      outline: 0;
    }

    & svg {
      fill: ${themeGet('colors.white')};
    }
  `

const dropdownDisabledCss: styleFn = ({ disabled }: StyledDropdownProps) =>
  disabled &&
  css`
    background-color: ${themeGet('colors.background.block')};
    color: ${themeGet('colors.text.muted')};
    & svg {
      fill: ${themeGet('colors.text.muted')};
    }
    &:hover {
      color: ${themeGet('colors.text.muted')};
      & svg {
        fill: ${themeGet('colors.text.muted')};
      }
    }
  `

export const StyledDropdown = styled.div<StyledDropdownProps>`
  position: relative;
  border-radius: 6px;
  font-weight: ${themeGet('fontWeights.1')};
  background-color: ${themeGet('colors.background.block')};
  color: ${themeGet('colors.text.heading')};
  width: 100%;
  cursor: pointer;
  font-size: ${getSizeParam('fontSize')}px;
  line-height: ${getSizeParam('lineHeight')}px;
  padding: ${getSizeParam('paddingY')}px ${getSizeParam('paddingX')}px;
  max-height: ${getSizeParam('maxHeight')}px;

  &:focus-within {
    outline: 2px solid ${themeGet('colors.primary700')};
    outline-offset: -2px;
  }

  &:hover {
    color: ${themeGet('colors.primary500')};
    & svg {
      fill: ${themeGet('colors.primary500')};
    }
  }

  ${dropdownDisabledCss}
  ${dropdownActiveCss}
  ${space};
`

export const ValueContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  & svg {
    color: inherit;
  }
`

export const Container = styled.div<SpaceProps>`
  width: 100%;
  position: relative;
`

export const StyledTippy = styled(Tippy)`
  width: 100% !important;

  & .tippy-content {
    padding: 0;
  }

  & .tippy-arrow {
    color: ${themeGet('colors.background.block')};
    z-index: 1;
  }
`

interface StyledMenuProps extends SizeProps {
  hasDivider?: boolean
}

const dividerStyleCss: styleFn = ({ hasDivider }: StyledMenuProps) =>
  hasDivider &&
  css`
    & li {
      border-bottom: 1px solid ${themeGet('colors.divider.default')};
    }

    & li:last-child {
      border: none;
    }
  `

export const StyledMenu = styled.ul<StyledMenuProps>`
  box-shadow: 0 12px 32px -2px rgba(0, 0, 0, 0.08);
  background-color: ${themeGet('colors.background.block')};
  width: 100%;
  list-style-type: none;
  padding: 4px;
  margin: 0;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  max-height: 300px;
  font-size: ${getSizeParam('fontSize')}px;
  ${dividerStyleCss}
`
interface MenuItemProps extends SizeProps {
  selected?: boolean
}

const selectedStyle: styleFn = ({ selected }: MenuItemProps) =>
  selected &&
  css`
    color: ${themeGet('colors.primary500')};
  `

export const MenuItem = styled.li<MenuItemProps>`
  padding: 4px;
  color: ${themeGet('colors.text.heading')};
  white-space: nowrap;
  cursor: pointer;
  &:hover {
    color: ${themeGet('colors.primary500')};
  }
  ${selectedStyle}
`

export const Label = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
`
