// @ts-strict-ignore
import React, { ReactElement, useMemo } from 'react'

import { SpaceProps } from 'styled-system'

import numeral from 'numeral'

import isNil from 'lodash/isNil'
import isObject from 'lodash/isObject'

import {
  CURRENCY,
  CURRENCY_FORMATS as BASE_CURRENCY_FLOAT_FORMATS,
  CURRENCY_SIGNS,
} from 'Constants/currencies'
import { HIDE_NUMBERS_STRING } from 'Constants/ids'

enum ValueKind {
  currencyInline = 'currencyInline',
  currencyInlineFloat = 'currencyInlineFloat',
  currencyInlinePercent = 'currencyInlinePercent',
  currencyInlinePercentFloat = 'currencyInlinePercentFloat',
}

type RatioValue = {
  current: number
  total?: number
}

type Props = SpaceProps & {
  alwaysHidden?: boolean
  alwaysVisible?: boolean
  currency?: string
  kind: ValueKind
  value: RatioValue | number
  alwaysWithSign?: boolean
}

const PERCENT_FORMAT = '0.00%'
const PERCENT_FORMAT_WITHSIGN = '+0.00%'

const CURRENCY_FORMATS = {
  FLOAT: BASE_CURRENCY_FLOAT_FORMATS,
  INTEGER: {
    [CURRENCY.USD]: `${CURRENCY_SIGNS[CURRENCY.USD]}0,0`,
    [CURRENCY.RUB]: `0,0${CURRENCY_SIGNS[CURRENCY.RUB]}`,
  },
}

function formatCurrency(value: number, format: string, alwaysWithSign = false) {
  const currencyFormatted = numeral(value / 100).format(format)
  if (alwaysWithSign) {
    return `${value >= 0 ? '+' : ''}${currencyFormatted}`
  }
  return currencyFormatted
}

function formatPercent(value: number, total: number, alwaysWithSign = false) {
  if (alwaysWithSign) {
    return numeral(value / total).format(PERCENT_FORMAT_WITHSIGN)
  }
  return numeral(value / total).format(PERCENT_FORMAT)
}

function InlineCurrencyFormatter({
  alwaysHidden = false,
  alwaysVisible = false,
  currency = CURRENCY.USD,
  kind,
  value,
  alwaysWithSign,
}: Props): ReactElement {
  const hideNumbers = !alwaysVisible || alwaysHidden

  const currencyFormat = useMemo(() => {
    switch (kind) {
      case ValueKind.currencyInline:
      case ValueKind.currencyInlinePercent:
        return CURRENCY_FORMATS.INTEGER[currency]

      case ValueKind.currencyInlinePercentFloat:
      case ValueKind.currencyInlineFloat:
        return CURRENCY_FORMATS.FLOAT[currency]

      default:
        return CURRENCY_FORMATS.INTEGER[currency]
    }
  }, [kind, currency])

  if (isNil(value)) return null

  if (hideNumbers) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{HIDE_NUMBERS_STRING}</>
  }

  const valueIsObject = isObject(value)

  switch (kind) {
    case ValueKind.currencyInline:
    case ValueKind.currencyInlineFloat:
      return (
        <>
          {formatCurrency(
            valueIsObject ? 0 : value,
            currencyFormat,
            alwaysWithSign,
          )}
        </>
      )

    case ValueKind.currencyInlinePercent:
    case ValueKind.currencyInlinePercentFloat: {
      const current = valueIsObject ? value.current : 0
      const total = valueIsObject ? value.total : 0
      return (
        <>
          {formatCurrency(current, currencyFormat, alwaysWithSign)} (
          {formatPercent(current, total, alwaysWithSign)})
        </>
      )
    }

    default:
      return null
  }
}

export default Object.assign(InlineCurrencyFormatter, { ValueKind })
