// @ts-strict-ignore
import styled from 'styled-components'
import { layout, WidthProps } from '@styled-system/layout'
import { margin, MarginProps } from '@styled-system/space'

import ReactCodeInput from '@acusti/react-code-input'

import { themeGet } from 'Theme/v2'

type Props = MarginProps & WidthProps

// TODO: take font settings from the theme
const CodeInput = styled(ReactCodeInput).attrs(({ theme, ...rest }) => ({
  style: {
    ...layout.width(rest),
    display: 'flex',
    gap: '8px',
    overflow: 'hidden',
  },
  inputStyle: {
    display: 'flex',
    flex: 1,
    width: '50px',
    height: '40px',
    outline: 'none',
    textAlign: 'center',
    border: `1px solid ${themeGet('colors.divider.default')({ theme })}`,
    borderRadius: '6px',
    margin: 0,
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
    color: themeGet('colors.text.heading')({ theme }),
  },
}))<Props>`
  ${margin}
`

export default CodeInput
