import styled, { css } from 'styled-components'
import { margin, MarginProps, styleFn, width, WidthProps } from 'styled-system'

import { ComponentVariants, createGetParam, themeGet } from 'Theme/v2'

export type ContainerVariantProps = {
  active?: boolean
  disabled?: boolean
}
type TabVariants = ComponentVariants<ContainerVariantProps>

export type SizeProps = {
  small?: boolean
  large?: boolean
}

export type ContainerParams = {
  color: TabVariants
}

export type ContainerProps = SizeProps &
  ContainerVariantProps &
  MarginProps &
  WidthProps

const params: ContainerParams = {
  color: {
    default: themeGet('colors.text.body'),
    active: themeGet('colors.primary500'),
    disabled: themeGet('colors.text.muted'),
  },
}
const getParam = createGetParam<ContainerParams, ContainerVariantProps>(params)

const sizes = {
  padding: {
    large: 10,
    default: 9,
    small: 8,
  },
  lineHeight: {
    large: 24,
    default: 20,
    small: 20,
  },
  fontSize: {
    large: themeGet('fontSizes.2'),
    default: themeGet('fontSizes.1'),
    small: themeGet('fontSizes.0'),
  },
}

const getSizeParam = createGetParam<typeof sizes, SizeProps>(sizes)

export const Inner = styled.div<SizeProps>`
  width: 100%;
  border-radius: 6px;
  font-weight: ${themeGet('fontWeights.2')};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${getSizeParam('padding')}px;
  line-height: ${getSizeParam('lineHeight')}px;
  font-size: ${getSizeParam('fontSize')}px;
`

const containerActiveCss: styleFn = ({ active }: ContainerProps) =>
  active &&
  css`
    :hover {
      ${Inner} {
        background-color: transparent;
      }
    }
    :after {
      height: 2px;
      background-color: ${themeGet('colors.primary500')};
    }
  `

const containerDisabledCss: styleFn = ({ disabled }: ContainerProps) =>
  disabled &&
  css`
    :hover {
      ${Inner} {
        background-color: transparent;
      }
    }
  `

export const IconContainer = styled.div<MarginProps>`
  display: flex;
  ${margin};
`

export const Container = styled.button<ContainerProps>`
  display: flex;
  flex-direction: column;
  background: transparent;
  margin: 0;
  padding: 0;
  box-shadow: unset;
  min-width: 146px;
  border: 0;
  justify-content: center;

  :hover {
    ${Inner} {
      background-color: ${themeGet('colors.primary900')};
    }
  }

  ${Inner} {
    color: ${getParam('color')};
  }

  :after {
    display: block;
    content: '';
    width: 100%;
    margin-top: 8px;
    height: 1px;
    background-color: ${themeGet('colors.divider.default')};
  }

  ${margin};
  ${width};
  ${containerActiveCss};
  ${containerDisabledCss};
`
