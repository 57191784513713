import React, { Component } from 'react'

const deprecated = (InnerComponent, msg = '') =>
  class extends Component {
    componentDidMount() {
      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.warn(`${InnerComponent.name} is deprecated`, msg)
      }
    }

    render() {
      return <InnerComponent {...this.props} />
    }
  }

export default { deprecated }
