import React, { ReactNode, useMemo } from 'react'

import styled, { css, ThemeProvider } from 'styled-components'

import { useUI } from 'Services/Store/Modules/ui'

import themes from './themes'

import defaultTheme, { font } from '../defaultTheme'

export interface ThemeProviderProps {
  children: ReactNode
}

const Base = styled.div`
  ${props =>
    css`
      font-family: ${props.theme.font || font};
    `}
`

function Provider({ ...rest }: ThemeProviderProps) {
  const {
    ui: { theme: themeName },
  } = useUI()

  const theme = useMemo(() => {
    return {
      ...(defaultTheme as any),
      ...(themes[themeName] || themes.default),
    }
  }, [themeName])

  return (
    <ThemeProvider theme={theme}>
      <Base {...rest} />
    </ThemeProvider>
  )
}

export default Provider
