import loadable from '@loadable/component'

import {
  ChatSDKCommunity,
  FeaturePage,
  HomePage,
  PricingPage,
  UseCasePage,
} from 'Components/Pages/_v3'

import * as paths from 'Constants/paths'

import {
  Affiliate,
  BecomePartner,
  HelpCenter,
  Solutions,
  SubmitReferral,
} from 'Containers/Pages/Home'

const RequestDemo = loadable(() => import('Containers/Pages/RequestDemo'))

export default [
  {
    path: paths.PRICING,
    exact: true,
    component: PricingPage,
  },
  {
    path: paths.HOME,
    exact: true,
    component: HomePage,
  },
  {
    path: paths.AFFILIATE,
    exact: true,
    component: Affiliate,
  },
  {
    path: paths.BECOME_PARTNER,
    exact: true,
    component: BecomePartner,
  },
  {
    path: paths.SUBMIT_REFERRAL,
    exact: true,
    component: SubmitReferral,
  },
  {
    path: paths.HELP_CENTER,
    exact: true,
    component: HelpCenter,
  },
  {
    path: paths.API,
    exact: true,
    component: Solutions,
  },
  {
    path: paths.HELP_CENTER_SECTION(),
    exact: true,
    component: HelpCenter,
  },
  { path: paths.REQUEST_DEMO, exact: true, component: RequestDemo },
  {
    path: paths.FEATURE(),
    exact: true,
    component: FeaturePage,
  },
  {
    path: paths.USE_CASE(),
    exact: true,
    component: UseCasePage,
  },
  {
    path: paths.CHAT_SDK_COMMUNITY,
    exact: true,
    component: ChatSDKCommunity,
  },
]
