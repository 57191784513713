// @ts-strict-ignore
import React, {
  ComponentProps,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { useApolloClient, useMutation } from '@apollo/client'

import { Modal } from 'Components/UI/_v2'

import enablePhoneVerificationMutation from 'Graphql/Mutations/User/enablePhoneVerification.graphql'
import requestPhoneConfirmationMutation from 'Graphql/Mutations/User/requestPhoneConfirmation.graphql'

import toast from 'Services/Toast'
import Utils from 'Services/Utils'

import { CodeContent, PhoneContent, SuccessContent } from './Contents'

enum Step {
  Phone = 1,
  Code,
  Success,
}

type Props = Pick<ComponentProps<typeof Modal>, 'isOpen' | 'onClose'> & {
  successSubtitle?: string
  successTitle?: string
  onFinish?: () => void
}

const INITIAL_STEP = Step.Phone

// TODO: localize
function VerifyPhoneCoordinatorModal({
  isOpen,
  successSubtitle,
  successTitle,
  onClose,
  onFinish,
}: Props) {
  const [loading, setLoading] = useState(false)
  const [step, setStep] = useState(INITIAL_STEP)
  const [phoneNumber, setPhoneNumber] = useState<string>()

  const client = useApolloClient()
  const [enablePhoneVerification] = useMutation<
    MainMutationData<'enablePhoneVerification'>,
    MainSchema.MutationEnablePhoneVerificationArgs
  >(enablePhoneVerificationMutation)

  useEffect(() => {
    if (!isOpen) return

    setStep(INITIAL_STEP)
    setPhoneNumber(undefined)
  }, [isOpen])

  const title = useMemo(
    () => ({
      [Step.Phone]: 'Enter your phone number',
      [Step.Code]: 'Verify your phone number',
    }),
    [],
  )

  const handleStepBack = useCallback(() => {
    setStep(prev => Math.max(Step.Phone, prev - 1))
  }, [])

  const handlePhoneContentFinish = useCallback(
    async value => {
      try {
        setLoading(true)
        const result = await client.mutate<
          MainMutationData<'requestPhoneConfirmation'>,
          MainSchema.MutationRequestPhoneConfirmationArgs
        >({
          mutation: requestPhoneConfirmationMutation,
          variables: {
            phoneNumber: value,
          },
        })

        if (result.data?.requestPhoneConfirmation.ok) {
          setPhoneNumber(value)
          setStep(Step.Code)
        }
      } catch (error: any) {
        const [graphQLError] = Utils.Errors.getGraphQLErrors(error)
        toast.error({ text: graphQLError })
      } finally {
        setLoading(false)
      }
    },
    [client],
  )

  const handleCodeContentFinish = useCallback(
    async value => {
      if (!phoneNumber) return

      try {
        setLoading(true)
        await enablePhoneVerification({
          variables: {
            phoneNumber,
            code: value,
          },
        })
        setStep(Step.Success)
      } catch (error: any) {
        //
      } finally {
        setLoading(false)
      }
    },
    [phoneNumber, enablePhoneVerification],
  )

  return (
    <Modal
      closeButtonDisabled={loading}
      isOpen={isOpen}
      title={title[step]}
      onClose={onClose}
    >
      {step === Step.Phone && (
        <PhoneContent
          loading={loading}
          phoneNumber={phoneNumber}
          onCancel={onClose}
          onFinish={handlePhoneContentFinish}
        />
      )}
      {step === Step.Code && (
        <CodeContent
          loading={loading}
          onCancel={handleStepBack}
          onFinish={handleCodeContentFinish}
        />
      )}
      {step === Step.Success && (
        <SuccessContent
          subtitle={successSubtitle}
          title={successTitle}
          onConfirm={onFinish}
        />
      )}
    </Modal>
  )
}

export default VerifyPhoneCoordinatorModal
