import React from 'react'
import ReactModal from 'react-modal'

interface Props extends ReactModal.Props {
  className?: string
  modalClassName?: ReactModal.Classes | string
}

const Adapter: React.FC<Props> = ({ className, modalClassName, ...props }) => (
  <ReactModal
    {...props}
    className={modalClassName}
    portalClassName={className}
  />
)

export default Adapter
