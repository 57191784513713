import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client'

import { API } from 'Config/app'

import {
  createAuthLink,
  createErrorLink,
  createHttpLink,
} from 'Services/Apollo/Links'
import shared from 'Services/Shared'

function createApolloClient() {
  const cache = new InMemoryCache()

  const links = [
    createErrorLink(),
    createAuthLink(),
    createHttpLink(`${API.URL}/graphql`),
  ]

  const clientOptions = {
    link: ApolloLink.from(links),
    connectToDevTools: true,
    cache,
  }

  const client = new ApolloClient(clientOptions)

  shared.setApolloClient(client)

  return client
}

export default createApolloClient
