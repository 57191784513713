// @ts-strict-ignore
import React, { useCallback, useState } from 'react'
import { Form } from 'react-final-form'

import { useMutation } from '@apollo/client'
import validate from 'validate.js'

import { Button, PhoneInput, Row, Text } from 'Components/UI/_v2'
import { InputField } from 'Components/UI/_v2/Forms'

import { PRICING_FAQ_SECTION_ID } from 'Constants/landing'
import { PRICING } from 'Constants/paths'

import contactSalesMutation from 'Graphql/Mutations/Communication/contactSales.graphql'

import { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'
import Utils from 'Services/Utils'

import { Container, Divider, StyledLink } from './styles'

enum Field {
  FullName = 'fullName',
  CompanyEmail = 'email',
  CompanySize = 'size',
  Message = 'message',
}

type Props = {
  onCancel: () => void
}

function ContactSalesForm({ onCancel }: Props) {
  const s = useScopedI18n('blocks.modals.contactSales')

  const [phoneNumber, setPhoneNumber] = useState(null)

  const constraints = {
    [Field.FullName]: {
      presence: true,
    },
    [Field.CompanyEmail]: {
      presence: true,
      email: true,
    },
    [Field.CompanySize]: {
      presence: true,
      numericality: {
        onlyInteger: true,
        greaterThan: 0,
      },
    },
    [Field.Message]: {
      presence: true,
    },
  }

  const [contactSales] = useMutation<
    MainMutationData<'contactSales'>,
    MainSchema.MutationContactSalesArgs
  >(contactSalesMutation)

  const submit = useCallback(
    async values => {
      try {
        await contactSales({
          variables: {
            fullname: values[Field.FullName],
            companyEmail: values[Field.CompanyEmail],
            companySize: Number(values[Field.CompanySize]) ?? 0,
            message: values[Field.Message],
            phoneNumber: phoneNumber ?? '',
          },
        })

        toast.success({ text: s('successMessage') })

        onCancel()
      } catch (error) {
        const [graphQLError] = Utils.Errors.getGraphQLErrors(error)
        toast.error({ text: graphQLError })
      }
    },
    [contactSales, onCancel, phoneNumber, s],
  )

  const renderForm = useCallback(
    ({ handleSubmit }) => (
      <Container>
        <Text body body4>
          {s('subtitle')}
          &nbsp;
          <StyledLink
            href={`${PRICING}#${PRICING_FAQ_SECTION_ID}`}
            onClick={onCancel}
          >
            {s('checkFaq')}
          </StyledLink>
        </Text>

        <InputField
          label={s('fullName')}
          name={Field.FullName}
          placeholder={s('fullNamePlaceholder')}
        />

        <InputField
          label={s('companyEmail')}
          name={Field.CompanyEmail}
          placeholder={s('companyEmailPlaceholder')}
        />

        <PhoneInput
          label={s('phoneNumber')}
          placeholder={s('phoneNumberPlaceholder')}
          value={phoneNumber}
          onChange={value => setPhoneNumber(value)}
        />

        <InputField
          label={s('companySize')}
          min={1}
          name={Field.CompanySize}
          placeholder={s('companySizePlaceholder')}
          type="number"
        />

        <InputField
          isMulti
          label={s('yourMessage')}
          name={Field.Message}
          placeholder={s('yourMessagePlaceholder')}
          resize="none"
          rows={3}
        />

        <Divider />

        <Row fullWidth gap={4}>
          <Button secondary width={1} onClick={onCancel}>
            {s('cancel')}
          </Button>
          <Button width={1} onClick={handleSubmit}>
            {s('send')}
          </Button>
        </Row>
      </Container>
    ),
    [onCancel, phoneNumber, s],
  )

  return (
    <Form
      render={renderForm}
      validate={values => validate(values, constraints)}
      onSubmit={submit}
    />
  )
}

export default ContactSalesForm
