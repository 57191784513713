import styled from 'styled-components'

import { themeGet } from 'Theme/v3'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: ${themeGet('colors.background.screen')};
`
