import { useEffect, useMemo } from 'react'
import { useRouteMatch } from 'react-router'

import { useQuery } from '@apollo/client'

import sortBy from 'lodash/sortBy'

import { FRONTEND_URL } from 'Config/app'

import { HELP_CENTER, HELP_CENTER_SECTION } from 'Constants/paths'

import organizationsQuery from 'Graphql/Queries/organizations.graphql'

import useAppContext from './useAppContext'
import useSingleInstance from './useSingleInstance'

const excludedPaths = new Set([HELP_CENTER, HELP_CENTER_SECTION()])

export default function useOrganizationRedirect() {
  useSingleInstance('useOrganizations')

  const routeMatch = useRouteMatch()

  const { me } = useAppContext()

  const { data } = useQuery<MainQueryData<'organizations'>>(
    organizationsQuery,
    { skip: !me },
  )

  const sortedOrganizations = useMemo(
    () => sortBy(data?.organizations || [], 'createdAt'),
    [data],
  )

  useEffect(() => {
    if (
      excludedPaths.has(routeMatch.path) ||
      sortedOrganizations.length === 0 ||
      !me
    )
      return

    const organization = sortedOrganizations[0]

    window.location.href = organization?.slug
      ? FRONTEND_URL.replace('//app', `//${organization.slug}`)
      : FRONTEND_URL
  }, [routeMatch, me, sortedOrganizations])

  return null
}
