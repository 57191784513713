// @ts-strict-ignore
import React, { ComponentProps, useMemo } from 'react'

import { DateTime } from 'luxon'

import isString from 'lodash/isString'

import { Column, Row, Text } from 'Components/UI/_v2'

import { useScopedI18n } from 'Services/I18n'

import { DelimiterBg, DelimiterFg } from './styles'

const DATE_FORMAT = 'dd LLL yyyy'

enum ValueKind {
  date = 'date',
  dateDaysLeft = 'dateDaysLeft',
  dateRange = 'dateRange',
  dateRangeDaysLeft = 'dateRangeDaysLeft',
  dateInline = 'dateInline',
  dateInlineRange = 'dateInlineRange',
}

type Props = ComponentProps<typeof Column> & {
  kind: ValueKind
  startDate?: string | DateTime
  value?: string | DateTime
}

function DateFormatter({ kind, startDate, value, ...rest }: Props) {
  const s = useScopedI18n('valueFormatter')
  const data = useMemo(() => {
    const date = isString(value) ? DateTime.fromISO(value) : value

    if (!date || !date.isValid) return null

    const startDateTime = isString(startDate)
      ? DateTime.fromISO(startDate)
      : startDate

    const today = DateTime.now().startOf('day')
    const dateFormatted = date.toFormat(DATE_FORMAT)
    const daysLeft = date.startOf('day').diff(today, 'days').toObject().days

    const isOver = daysLeft < 0
    const ratioColor = isOver ? 'danger500' : 'success500'

    let progress = 0
    let startDateFormatted: string = s('noDate')
    if (startDateTime && startDateTime.isValid) {
      startDateFormatted = startDateTime.toFormat(DATE_FORMAT)
      const daysTotal = date
        .startOf('day')
        .diff(startDateTime.startOf('day'), 'days')
        .toObject().days
      progress = daysTotal ? Math.min(Math.abs(daysLeft / daysTotal), 1) : 0
    }

    return {
      dateFormatted,
      startDateFormatted,
      daysLeft,
      isOver,
      ratioColor,
      progress,
    }
  }, [s, startDate, value])

  if (kind === ValueKind.dateInline) {
    return data.dateFormatted ?? s('noDate')
  }

  if (kind === ValueKind.dateInlineRange) {
    return `${data.startDateFormatted} - ${data.dateFormatted}`
  }

  if (!data)
    return (
      <Column {...rest} squeeze>
        {s('noDate')}
      </Column>
    )

  const daysLeftVisible =
    kind === ValueKind.dateDaysLeft || kind === ValueKind.dateRangeDaysLeft
  const progressVisible = daysLeftVisible && !!startDate
  const isRange =
    kind === ValueKind.dateRange || kind === ValueKind.dateRangeDaysLeft

  return (
    <Column {...rest} squeeze>
      {isRange ? (
        <Row center gap={3} squeeze>
          <Text body caption2 nowrap>
            {data.startDateFormatted}
          </Text>
          <Text body caption2 color="divider.default">
            &ndash;
          </Text>
          <Text body caption2 nowrap>
            {data.dateFormatted}
          </Text>
        </Row>
      ) : (
        <Text body caption2>
          {data.dateFormatted}
        </Text>
      )}

      {progressVisible && (
        <DelimiterBg my={1}>
          <DelimiterFg bg={data.ratioColor} width={`${data.progress * 100}%`} />
        </DelimiterBg>
      )}

      {daysLeftVisible && (
        <Text
          caption3
          color={data.ratioColor}
          lowercase
          mt={!progressVisible ? '6px' : 0}
        >
          <>
            {Math.abs(data.daysLeft)}{' '}
            {data.isOver ? s('daysOver') : s('daysLeft')}
          </>
        </Text>
      )}
    </Column>
  )
}

export default Object.assign(DateFormatter, { ValueKind })
