// @ts-strict-ignore
import React, { ReactNode, useCallback, useMemo, useRef, useState } from 'react'

import { WarningCircle } from 'phosphor-react'

import map from 'lodash/map'

import { Column, Row } from 'Components/UI/_v2/Flex'

import Utils from 'Services/Utils'

import { ErrorText, Label, StyledInput } from './styles'

const PLACEHOLDER_CHAR = ' '

function normalizePin(text: string, length: number) {
  return (text ?? '').slice(0, length).padEnd(length, PLACEHOLDER_CHAR)
}

function isPinComplete(pin: string) {
  return pin.indexOf(PLACEHOLDER_CHAR) === -1
}

type Props = {
  value?: string
  disabled?: boolean
  length?: number
  label?: ReactNode
  error?: string
  onComplete?: () => void
  onChange?: (pin: string) => void
}

function PinInput({
  disabled,
  length = 6,
  value,
  label,
  error,
  onComplete,
  onChange,
}: Props) {
  const [completed, setCompleted] = useState(false)

  const pin = useMemo(() => normalizePin(value, length), [value, length])

  const inputRefs = useRef<HTMLInputElement[]>([])

  const notifyIfCompleted = useCallback(
    (pin: string) => {
      if (isPinComplete(pin) && !completed) {
        onComplete?.()
        setCompleted(true)
      } else if (completed && !isPinComplete(pin)) {
        setCompleted(false)
      }
    },
    [completed, onComplete],
  )

  const handleChangeChar = useCallback(
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = event.target.value
      if (inputValue.length > 2) return

      const currentValue = pin[index]

      let newChar

      if (inputValue.length === 1) {
        newChar = inputValue
      } else if (inputValue.length > 1) {
        newChar = currentValue === inputValue[0] ? inputValue[1] : inputValue[0]
      }

      const chars = Array.from(pin)
      chars[index] = newChar ?? PLACEHOLDER_CHAR
      const newPin = chars.join('')
      onChange?.(newPin)

      if (newChar) inputRefs.current?.[index + 1]?.focus?.()
      notifyIfCompleted(newPin)
    },
    [pin, notifyIfCompleted, onChange],
  )

  const handleKeyDown = useCallback(
    (index: number) => (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.code === 'Backspace') {
        event.preventDefault()
        event.stopPropagation()
        inputRefs.current?.[index - 1]?.focus?.()

        const chars = Array.from(pin)
        chars[index] = PLACEHOLDER_CHAR
        onChange?.(chars.join(''))
      }
    },
    [pin, onChange],
  )

  const handlePaste = useCallback(
    (event: React.ClipboardEvent<HTMLInputElement>) => {
      const pastedText = event.clipboardData.getData('text').trim()
      const normalizedPin = normalizePin(pastedText, length)
      onChange?.(normalizedPin)
      notifyIfCompleted(normalizedPin)
    },
    [length, notifyIfCompleted, onChange],
  )

  const setInputRef = useCallback(
    (index: number) => (input: HTMLInputElement) => {
      if (!inputRefs.current) inputRefs.current = []
      inputRefs.current[index] = input
    },
    [],
  )

  return (
    <Column gap={3}>
      {label && <Label>{label}</Label>}

      <Row gap={3} spaceBetween>
        {map(pin, (char, index) => (
          <StyledInput
            disabled={disabled}
            key={`${char}:${index}`}
            large
            ref={setInputRef(index)}
            value={Utils.Strings.isEmptyOrSpaces(char) ? undefined : char}
            onChange={handleChangeChar(index)}
            onKeyDown={handleKeyDown(index)}
            onPaste={handlePaste}
          />
        ))}
      </Row>

      {error && (
        <ErrorText>
          <WarningCircle weight="bold" />
          <span>{error}</span>
        </ErrorText>
      )}
    </Column>
  )
}

export default PinInput
