import React from 'react'
import PropTypes from 'prop-types'

import { Button } from 'Components/UI/_v2'

import { _ } from 'Services/I18n'

import { Container, Content, ImageHolder, Text, Title } from './styles'

function VerificationEmail({ email, onClose }) {
  return (
    <Container>
      <Content>
        <Title>{_('verificationEmail')}</Title>
        <Text>
          {_('isSentTo')} {email} {_('pleaseCheck')}
        </Text>
        <Button mt={4} onClick={onClose}>
          {_('gotIt')}
        </Button>
      </Content>
      <ImageHolder />
    </Container>
  )
}

VerificationEmail.propTypes = {
  email: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default VerificationEmail
