import React from 'react'
import { renderRoutes, RouteConfig } from 'react-router-config'

import { VerifyPhoneCoordinator, VerifyTOTPCoordinator } from 'Coordinators'

import { HelmetTitle } from 'Components/Blocks'
import { Header } from 'Components/Blocks/_v2'

import { useAppContext } from 'Hooks'

import { useScopedI18n } from 'Services/I18n'

import { Background, Container, Content, FormHolder } from './styles'

type Props = {
  route: RouteConfig
}

function Auth({ route: currentRoute }: Props) {
  const s = useScopedI18n('auth')
  const { electron } = useAppContext()

  return (
    <Container electron={electron}>
      <HelmetTitle title={s('helmet.default')} />

      <Content>
        <Header />

        <FormHolder>{renderRoutes(currentRoute.routes)}</FormHolder>
      </Content>

      <Background />

      <VerifyPhoneCoordinator />

      <VerifyTOTPCoordinator />
    </Container>
  )
}

export default Auth
