// @ts-strict-ignore
import styled from 'styled-components'
import { margin, MarginProps } from '@styled-system/space'

import { Icon } from 'Components/UI/_v2'

import { themeGet } from 'Theme/v2'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 420px;
`

export const Item = styled.a.attrs({
  target: '_blank',
})<MarginProps>`
  display: flex;
  align-items: center;
  width: 100%;
  padding: ${themeGet('space.3')}px ${themeGet('space.4')}px;
  background-color: ${themeGet('colors.background.screen')};
  text-decoration: none;
  border: 1px solid ${themeGet('colors.divider.default')};
  border-radius: 6px;
  cursor: pointer;

  ${margin}
`

export const StoreIcon = styled(Icon)`
  fill: ${themeGet('colors.text.muted')};
`
