import omit from 'lodash/omit'
import values from 'lodash/values'

import { theme } from 'Theme'

const getRandomColor = () =>
  `rgb(${Math.floor(Math.random() * 255)},${Math.floor(
    Math.random() * 255,
  )},${Math.floor(Math.random() * 255)})`

const filterColors = [
  'alabaster',
  'alto',
  'aluminium',
  'blackOpacity',
  'blackOpacityLight',
  'ebony',
  'grey',
  'mineShaft',
  'white',
  'whiteLilac',
  'whiteOpacity',
  'whiteOpacityLight',
  'wildSand',
  'shadow',
  'font',
  'athensGray',
]

const projectColors = () => values(omit(theme.colors, filterColors))

export default { getRandomColor, projectColors }
