import React, { useCallback, useEffect, useRef, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { Copy } from 'phosphor-react'

import { Copied, Email, EmailWrapper } from './styles'

type Props = {
  email: string
}

function CopyEmail({ email }: Props) {
  const timeout = useRef<NodeJS.Timeout | undefined>(undefined)
  const [copied, setCopied] = useState(false)

  const handleCopy = useCallback(() => {
    setCopied(true)

    timeout.current = setTimeout(() => {
      setCopied(false)
    }, 1000)
  }, [])

  useEffect(() => () => clearTimeout(timeout.current), [])

  return (
    <CopyToClipboard text={email} onCopy={handleCopy}>
      <EmailWrapper title={email}>
        <Email>{email}</Email>
        <Copy weight="bold" />
        {copied && <Copied>copied!</Copied>}
      </EmailWrapper>
    </CopyToClipboard>
  )
}

export default CopyEmail
