// @ts-strict-ignore
import { DateTime, Duration } from 'luxon'
import ms from 'ms'

import map from 'lodash/map'
import lodashRange from 'lodash/range'

const convertToPrettyTime = millis => {
  const hours = millis / 1000 / 60 / 60
  const roundedHours = Math.floor(hours)
  const prettyHours = roundedHours < 10 ? `0${roundedHours}` : roundedHours

  const minutes = (millis / 1000 / 60) % 60
  const roundedMinutes = Math.floor(minutes)
  const prettyMinutes = `0${roundedMinutes}`.slice(-2)

  return `${prettyHours}:${prettyMinutes}`
}

const startAndEndOfWeek = () => {
  const dt = DateTime.local()
  const startDate = dt.startOf('week')
  const endDate = dt.endOf('week')

  return [startDate, endDate]
}

const convertTimeToMillis = time => {
  const [hours, minutes] = time.split(':').map(parseFloat)
  return (hours || 0) * ms('1h') + (minutes || 0) * ms('1m')
}

const convertTimeToSeconds = time => {
  const [hours, minutes] = time.split(':').map(parseFloat)
  return (hours || 0) * 3600 + (minutes || 0) * 60
}

const convertSecondsToTime = (seconds, format = 'hh:mm:ss') =>
  Duration.fromObject({ seconds }).toFormat(format)

function daysRange(startDate, endDate) {
  const startDateTime = DateTime.fromISO(startDate).startOf('day')
  const endDateTime = DateTime.fromISO(endDate).endOf('day')

  if (
    !startDateTime?.isValid ||
    !endDateTime?.isValid ||
    endDateTime < startDateTime
  ) {
    return []
  }

  const result = []
  let date = startDateTime.plus({ hours: 12 })
  while (endDateTime > date) {
    result.push(date)
    date = date.plus({ days: 1 })
  }

  return result
}

function dateTimeToMinutes(dateTime: DateTime) {
  return dateTime.hour * 60 + dateTime.minute + Math.round(dateTime.second / 60)
}

function ISOTimeToMinutes(timeStr, zone = 'utc') {
  const date = DateTime.fromISO(`1970-01-01T${timeStr}`, { zone })

  if (!date.isValid) return null

  return dateTimeToMinutes(date)
}

function minutesToISOTime(minutes, zone = 'utc') {
  if (!Number.isFinite(minutes)) return null

  let date = DateTime.utc(1970, 1, 1).plus({ minutes })

  if (minutes >= 24 * 60) {
    date = DateTime.utc(1970, 1, 1).endOf('day')
  }

  return date.setZone(zone, { keepLocalTime: true }).toUTC().toISOTime()
}

function createTimeOptions() {
  return map(lodashRange(0, 24 * 60 + 1, 30), minutes => {
    const milliseconds = minutes * 60 * 1000 - (minutes === 24 * 60 ? 1 : 0)
    return {
      value: milliseconds,
      label: DateTime.local(1970, 1, 1)
        .plus(milliseconds)
        .toLocaleString(DateTime.TIME_SIMPLE),
    }
  })
}

function millisecondsToISOTime(milliseconds, zone = 'utc') {
  if (!Number.isFinite(milliseconds)) {
    return null
  }
  return DateTime.utc(1970, 1, 1)
    .setZone(zone, { keepLocalTime: true })
    .plus(milliseconds)
    .toUTC()
    .toISOTime()
}

function ISOTimeToMilliseconds(timeStr, zone = 'utc') {
  const date = DateTime.fromISO(`1970-01-01T${timeStr}`, { zone })
  if (!date.isValid) {
    return null
  }
  return date.diff(date.startOf('day')).toObject().milliseconds
}

function isToday(date) {
  return date.hasSame(DateTime.local(), 'day')
}

function timeToLocalPrettyTime(timeISO: string, format: string = 't') {
  const innerTime = DateTime.fromISO(timeISO).toLocal()
  if (!innerTime.isValid) return null

  return innerTime.toFormat(format)
}

export default {
  convertToPrettyTime,
  startAndEndOfWeek,
  convertTimeToMillis,
  convertTimeToSeconds,
  convertSecondsToTime,
  daysRange,
  dateTimeToMinutes,
  ISOTimeToMinutes,
  minutesToISOTime,
  createTimeOptions,
  millisecondsToISOTime,
  ISOTimeToMilliseconds,
  isToday,
  timeToLocalPrettyTime,
}
