// @ts-strict-ignore
import React, { useState } from 'react'
import ReactInputRange, { InputRangeProps } from 'react-input-range'

import { pick } from '@styled-system/props'

import { Row } from 'Components/UI/_v2/Flex'

import { Container, ContainerProps, Label } from './styles'

type Props = ContainerProps &
  InputRangeProps & {
    label?: string
    labelLeft?: string
    labelRight?: string
    className?: string
    showValues?: boolean
    color?: string
  }

function InputRange({
  label,
  className,
  showValues,
  backgroundColor,
  bg,
  labelLeft,
  labelRight,
  ...rest
}: Props) {
  const [sliderActive, setSliderActive] = useState(false)

  return (
    <Container
      {...pick(rest)}
      active={sliderActive}
      backgroundColor={backgroundColor}
      bg={bg}
      className={className}
    >
      {label && <Label>{label}</Label>}
      <ReactInputRange
        {...rest}
        onChangeComplete={() => setSliderActive(false)}
        onChangeStart={() => setSliderActive(true)}
      />
      {showValues && (
        <Row spaceBetween>
          <Label>{labelLeft ?? rest.minValue}</Label>
          <Label>{labelRight ?? rest.maxValue}</Label>
        </Row>
      )}
    </Container>
  )
}

export default InputRange
