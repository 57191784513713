import { ThemeProps } from 'styled-components'

import OldMainTheme from 'Theme/Main'
import Sizes from 'Theme/v2/Default/sizes'
import paletteV2 from 'Theme/v2/palette'
import paletteV3 from 'Theme/v3/palette'

export const DEFAULT_VARIANT = 'default'

type PaletteV2 = typeof paletteV2
type PaletteV3 = typeof paletteV3

export type BaseColors = {
  black: '#000'
  white: '#fff'
}

export interface ThemeColorsV2 extends PaletteV2 {
  primary: string
  secondary: string
  bg: string
  transparent: string

  background: {
    screen: string
    block: string
    subblock: string
  }

  divider: {
    default: string
    darker: string
    lighter: string
    contrast: string
  }

  text: {
    body: string
    heading: string
    important: string
    muted: string
  }
}

export interface ThemeColorsV3 extends PaletteV3 {
  primary: string
  secondary: string
  bg: string
  transparent: string

  background: {
    screen: string
    block: string
    subBlock: string
  }

  divider: {
    default: string
    darker: string
    lighter: string
    contrast: string
  }

  text: {
    body: string
    heading: string
    important: string
    muted: string
  }
}

export type ThemeFontSizes = number[]
export type ThemeSpaces = number[]
export type ThemeFontWeights = number[]
export type ThemeBreakpoints = number[] | string[]
export type ThemeStyledBreakpoints = any
export type ThemeControlHeights = number[]
export type ThemeSizes = typeof Sizes

export type ThemeShadow = {
  large: string
  medium: string
  small: string
  down: {
    large: string
    medium: string
    small: string
  }
  up: {
    large: string
    medium: string
    small: string
  }
}

export type ZIndices = {
  blocker: number
  blockerOverlay: number
  chatPanel: number
  dropdown: number
  header: number
  inboxPanel: number
  modal: number
  modalFullscreen: number
  navigationPanel: number
  projectHeader: number
  projectNavigation: number
}

export type ThemeBase = {
  breakpoints: ThemeBreakpoints
  controlHeights: ThemeControlHeights
  defaultTransitionTime: string
  font: string
  fontSizes: ThemeFontSizes
  fontWeights: ThemeFontWeights
  name: string
  shadow: ThemeShadow
  sizes: ThemeSizes
  space: ThemeSpaces
  webfonts: string[]
  zIndices: ZIndices
}

export type ThemeV2 = ThemeBase & { colors: ThemeColorsV2 }

export type ThemeV3 = ThemeBase & {
  colors: ThemeColorsV3
  styledBreakpoints: ThemeStyledBreakpoints
}

export type Theme = typeof OldMainTheme & {
  v2?: ThemeV2
  v3?: ThemeV3
}

export interface Themes {
  default: Theme
  dark?: Theme
}

export type ThemeGetter =
  | ((props: ThemeProps<Theme>) => string)
  | string
  | number

export type ComponentVariants<T> = {
  [DEFAULT_VARIANT]: ThemeGetter
} & {
  [P in keyof T]?: ThemeGetter
}
