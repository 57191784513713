// @ts-strict-ignore
import React, { useCallback, useRef } from 'react'
import Collapse from 'react-css-collapse'

type Props = {
  children?: React.ReactNode
  isOpen?: boolean
}

function CollapseLazy({ children, isOpen }: Props) {
  const renderRef = useRef(isOpen)

  if (isOpen && !renderRef.current) {
    renderRef.current = true
  }

  const handleAnimationFinish = useCallback(() => {
    renderRef.current = isOpen
  }, [isOpen])

  return (
    <Collapse isOpen={isOpen} onRest={handleAnimationFinish}>
      {renderRef.current && children}
    </Collapse>
  )
}

export default CollapseLazy
