import loadable from '@loadable/component'

const CreatePassword = loadable(() => import('./CreatePassword'))
const ForgotPassword = loadable(() => import('./ForgotPassword'))
const Login = loadable(() => import('./Login'))
const PhoneVerification = loadable(() => import('./PhoneVerification'))
const ResetPassword = loadable(() => import('./ResetPassword'))
const SignUp = loadable(() => import('./SignUp'))
const TOTPVerification = loadable(() => import('./TOTPVerification'))
const VerifyAccount = loadable(() => import('./VerifyAccount'))
const VerifyEmail = loadable(() => import('./VerifyEmail'))

export default {
  CreatePassword,
  ForgotPassword,
  Login,
  PhoneVerification,
  ResetPassword,
  SignUp,
  TOTPVerification,
  VerifyAccount,
  VerifyEmail,
}
