import styled from 'styled-components'
import {
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
} from 'styled-system'

import { ComponentVariants, createGetParam, themeGet } from 'Theme/v2'

interface VariantProps {
  secondary?: boolean
  tertiary?: boolean
  danger?: boolean
  success?: boolean
}

type Variants = ComponentVariants<VariantProps>

interface Params {
  background: Variants
  color: Variants
  border: Variants
  hover: Variants
  active: Variants
  focus: Variants
  disabledBackground: Variants
  disabledColor: Variants
  disabledBorder: Variants
}

const params: Params = {
  background: {
    default: themeGet('colors.primary500'),
    secondary: themeGet('colors.transparent'),
    tertiary: themeGet('colors.text.heading'),
    danger: themeGet('colors.danger500'),
    success: themeGet('colors.success400'),
  },
  color: {
    default: themeGet('colors.white'),
    secondary: themeGet('colors.text.heading'),
    tertiary: themeGet('colors.background.block'),
    danger: themeGet('colors.white'),
    success: themeGet('colors.white'),
  },
  border: {
    default: themeGet('colors.primary500'),
    secondary: themeGet('colors.divider.default'),
    tertiary: themeGet('colors.text.heading'),
    danger: themeGet('colors.danger500'),
    success: themeGet('colors.success400'),
  },
  hover: {
    default: themeGet('colors.primary400'),
    secondary: themeGet('colors.divider.default'),
    tertiary: themeGet('colors.text.body'),
    danger: themeGet('colors.danger400'),
    success: themeGet('colors.success300'),
  },
  active: {
    default: themeGet('colors.primary300'),
    secondary: themeGet('colors.divider.default'),
    tertiary: themeGet('colors.text.body'),
    danger: themeGet('colors.danger300'),
    success: themeGet('colors.success200'),
  },
  focus: {
    default: themeGet('colors.primary700'),
    secondary: themeGet('colors.neutral600'),
    tertiary: themeGet('colors.neutral600'),
    danger: themeGet('colors.danger800'),
    success: themeGet('colors.success700'),
  },
  disabledBackground: {
    default: themeGet('colors.primary900'),
    secondary: themeGet('colors.transparent'),
    tertiary: themeGet('colors.background.subblock'),
    danger: themeGet('colors.danger900'),
    success: themeGet('colors.success900'),
  },
  disabledColor: {
    default: themeGet('colors.primary700'),
    secondary: themeGet('colors.text.muted'),
    tertiary: themeGet('colors.text.muted'),
    danger: themeGet('colors.danger600'),
    success: themeGet('colors.success600'),
  },
  disabledBorder: {
    default: themeGet('colors.primary900'),
    secondary: themeGet('colors.divider.default'),
    tertiary: themeGet('colors.background.subblock'),
    danger: themeGet('colors.danger900'),
    success: themeGet('colors.success900'),
  },
}

const getParam = createGetParam<Params, VariantProps>(params)

interface SizeProps {
  large?: boolean
  small?: boolean
  extraSmall?: boolean
}

const sizeParams = {
  height: {
    large: themeGet('controlHeights.4'),
    default: themeGet('controlHeights.3'),
    small: themeGet('controlHeights.2'),
    extraSmall: themeGet('controlHeights.1'),
  },
  padding: {
    large: 20,
    default: 16,
    small: 12,
    extraSmall: 10,
  },
  gap: {
    large: 12,
    default: 10,
    small: 8,
    extraSmall: 6,
  },
  fontSize: {
    large: themeGet('fontSizes.3'),
    default: themeGet('fontSizes.2'),
    small: themeGet('fontSizes.1'),
    extraSmall: themeGet('fontSizes.0'),
  },
}

const getSizeParam = createGetParam<typeof sizeParams, SizeProps>(sizeParams)

interface Props
  extends VariantProps,
    SizeProps,
    FlexboxProps,
    LayoutProps,
    SpaceProps {}

const Button = styled.button.attrs(props => ({
  type: props.type || 'button',
}))<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 6px;
  margin: 0;
  user-select: none;

  height: ${getSizeParam('height')}px;
  min-width: ${getSizeParam('height')}px;
  padding: 0 ${getSizeParam('padding')}px;
  gap: ${getSizeParam('gap')}px;
  font-size: ${getSizeParam('fontSize')}px;
  font-weight: ${themeGet('fontWeights.2')};
  white-space: nowrap;

  color: ${getParam('color')};
  background-color: ${getParam('background')};
  border: 1px solid ${getParam('border')};

  &:focus-visible {
    outline: 2px solid ${getParam('focus')};
    border-color: ${getParam('background')};
  }

  &:enabled:hover {
    background-color: ${getParam('hover')};
    border-color: ${getParam('hover')};
  }

  &:enabled:active {
    background-color: ${getParam('active')};
    border-color: ${getParam('active')};
  }

  &:disabled {
    cursor: default;
    color: ${getParam('disabledColor')};
    background-color: ${getParam('disabledBackground')};
    border-color: ${getParam('disabledBorder')};
  }

  & > svg {
    flex-shrink: 0;
  }

  ${flexbox}
  ${layout}
  ${space}
`

export default Button
