import { FunctionComponent, ReactNode } from 'react'

import styled, { css } from 'styled-components'
import {
  color,
  ColorProps,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  margin,
  MarginProps,
  padding,
  PaddingProps,
  width,
  zIndex,
  ZIndexProps,
} from 'styled-system'

import { themeGet } from 'Theme/v2'
import { gap, GapProps } from 'Theme/v2/system'

type ElementProps = LayoutProps &
  MarginProps &
  PaddingProps &
  ColorProps &
  ZIndexProps &
  Omit<FlexboxProps, 'flexDirection'> &
  GapProps & {
    alignEnd?: boolean
    alignStart?: boolean
    borderBottom?: boolean
    borderTop?: boolean
    center?: boolean
    children?: ReactNode
    className?: string
    fullHeight?: boolean
    fullScreen?: boolean
    fullWidth?: boolean
    justifyCenter?: boolean
    justifyEnd?: boolean
    noShrink?: boolean
    spaceBetween?: boolean
    squeeze?: boolean
    grow?: boolean
    wrap?: boolean
    relative?: boolean
    as?: string
  }

const alignEndCss = ({ alignEnd }: ElementProps) =>
  alignEnd &&
  css`
    align-items: flex-end;
  `

const alignStartCss = ({ alignStart }: ElementProps) =>
  alignStart &&
  css`
    align-items: flex-start;
  `

const borderBottomCss = ({ borderBottom }: ElementProps) =>
  borderBottom &&
  css`
    border-bottom: solid 1px ${themeGet('colors.divider.default')};
  `

const borderTopCss = ({ borderTop }: ElementProps) =>
  borderTop &&
  css`
    border-top: solid 1px ${themeGet('colors.divider.default')};
  `

const centerCss = ({ center }: ElementProps) =>
  center &&
  css`
    align-items: center;
  `

const fullHeightCss = ({ fullHeight }: ElementProps) =>
  fullHeight &&
  css`
    height: 100%;
  `

const fullScreenCss = ({ fullScreen }: ElementProps) =>
  fullScreen &&
  css`
    width: 100vw;
    height: 100vh;
  `

const fullWidthCss = ({ fullWidth }: ElementProps) =>
  fullWidth &&
  css`
    width: 100%;
  `

const justifyCenterCss = ({ justifyCenter }: ElementProps) =>
  justifyCenter &&
  css`
    justify-content: center;
  `

const justifyEndCss = ({ justifyEnd }: ElementProps) =>
  justifyEnd &&
  css`
    justify-content: flex-end;
  `

const noShrinkCss = ({ noShrink }: ElementProps) =>
  noShrink &&
  css`
    flex-shrink: 0;
  `

const spaceBetweenCss = ({ spaceBetween }: ElementProps) =>
  spaceBetween &&
  css`
    justify-content: space-between;
  `

const squeezeCss = ({ squeeze }: ElementProps) =>
  squeeze &&
  css`
    width: fit-content;
  `

const growCss = ({ grow }: ElementProps) =>
  grow &&
  css`
    flex-grow: 1;
  `

const wrapCss = ({ wrap }: ElementProps) =>
  wrap &&
  css`
    flex-wrap: wrap;
  `

const relativeCss = ({ relative }: ElementProps) =>
  relative &&
  css`
    position: relative;
  `

export const Element: FunctionComponent<ElementProps> = styled.div.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['wrap'].includes(prop) && defaultValidatorFn(prop),
})`
  ${layout}
  ${margin}
  ${padding}
  ${color}
  ${flexbox}
  ${gap}
  ${width}

  ${alignEndCss}
  ${alignStartCss}
  ${borderBottomCss}
  ${borderTopCss}
  ${centerCss}
  ${fullHeightCss}
  ${fullWidthCss}
  ${justifyCenterCss}
  ${justifyEndCss}
  ${noShrinkCss}
  ${spaceBetweenCss}
  ${squeezeCss}
  ${fullScreenCss}
  ${wrapCss}
  ${growCss}
  ${relativeCss}
  ${zIndex}
`

export const Column: FunctionComponent<ElementProps> = styled(Element)`
  display: flex;
  flex-direction: column;
`

export const Row: FunctionComponent<ElementProps> = styled(Element)`
  display: flex;
`
