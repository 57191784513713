import styled from 'styled-components'

import { themeGet } from 'Theme/v2'

const QR_CODE_CONTAINER_SIZE = 188
export const QR_CODE_SIZE = 157

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 420px;
`

export const QRCodeContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: ${QR_CODE_CONTAINER_SIZE}px;
  height: ${QR_CODE_CONTAINER_SIZE}px;
  border: 1px solid ${themeGet('colors.divider.default')};
  border-radius: 6px;
`
