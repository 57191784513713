import styled, { css } from 'styled-components'

import { themeGet } from 'Theme/v2'

type CheckIconHolderProps = {
  check?: boolean
}

const checkIconHolderCheckCss = ({ check }: CheckIconHolderProps) =>
  check
    ? css`
        color: ${themeGet('colors.success500')};
      `
    : css`
        color: ${themeGet('colors.divider.contrast')};
      `

export const CheckIconHolder = styled.div<CheckIconHolderProps>`
  display: flex;
  align-items: center;

  ${checkIconHolderCheckCss}
`
