// @ts-strict-ignore
import { useMemo } from 'react'

import eachDeep from 'deepdash/eachDeep'

import uniqBy from 'lodash/uniqBy'

import {
  HelpCenterArticle,
  HelpCenterSectionBySlug,
} from 'Constants/helpCenter'

function useHelpCenterArticles(
  sectionsBySlug: HelpCenterSectionBySlug,
): HelpCenterArticle[] {
  return useMemo(() => {
    let recentArticles: HelpCenterArticle[] = []
    eachDeep(
      sectionsBySlug,
      (node: { articles: HelpCenterArticle[] }) => {
        if (node?.articles)
          recentArticles = recentArticles.concat(node.articles)
      },
      [],
    )
    return uniqBy(recentArticles, article => article.id)
  }, [sectionsBySlug])
}

export default useHelpCenterArticles
