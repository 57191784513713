// @ts-strict-ignore
import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import createNumberMask from 'text-mask-addons/dist/createNumberMask'

import noop from 'lodash/noop'
import replace from 'lodash/replace'

import {
  Label,
  Wrapper,
  WrapperProps,
} from 'Components/UI/_v2/Forms/Input/styles'

import {
  Container,
  ContainerProps,
  DollarIcon,
  StyledMaskedInput,
} from './styles'

const currencyMask = createNumberMask({
  prefix: '',
  suffix: '',
  allowDecimal: true,
  decimalLimit: 2,
  integerLimit: 0,
  requireDecimal: false,
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowLeadingZeroes: true,
})

function parseMoney(input) {
  const result = parseFloat(replace(input, ',', '')) * 100
  return Number.isFinite(result) ? result : 0
}

function stringifyMoney(value) {
  if (!Number.isFinite(value)) {
    return ''
  }
  return String(value / 100)
}

type Props = ContainerProps &
  WrapperProps & {
    value?: string | number
    defaultValue?: string | number
    disabled?: boolean
    label?: string
    caption?: string
    placeholder?: string
    maxLength?: number
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void
    onChange?: (value: number) => void
  }

function MoneyInput({
  value,
  label,
  large,
  small,
  danger,
  disabled,
  success,
  onChange,
  placeholder,
  onKeyDown,
  ...rest
}: Props) {
  const [isActive, setIsActive] = useState(false)

  const [stringifiedValue, setStringifiedValue] = useState(
    stringifyMoney(value),
  )

  const handleChange = useCallback(
    event => {
      setStringifiedValue(event.target.value)
      const parsedValue = parseMoney(event.target.value)

      if (value !== parsedValue) {
        onChange?.(parsedValue)
      }
    },
    [value, onChange],
  )

  const handleBlur = useCallback<
    React.FocusEventHandler<HTMLInputElement>
  >(() => {
    setIsActive(false)
  }, [setIsActive])

  const handleFocus = useCallback<
    React.FocusEventHandler<HTMLInputElement>
  >(() => {
    setIsActive(true)
  }, [setIsActive])

  useEffect(() => {
    if (value && value !== parseMoney(stringifiedValue)) {
      setStringifiedValue(stringifyMoney(value))
    }
  }, [value, stringifiedValue])

  return (
    <Wrapper {...rest} large={large} small={small}>
      {label && <Label mb={2}>{label}</Label>}

      <Container
        active={isActive}
        danger={danger}
        disabled={disabled}
        large={large}
        small={small}
        success={success}
      >
        <DollarIcon mr={4} size={16} weight="bold" />

        <StyledMaskedInput
          mask={currencyMask}
          placeholder={placeholder}
          value={stringifiedValue}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          onKeyDown={onKeyDown}
        />
      </Container>
    </Wrapper>
  )
}

MoneyInput.defaultProps = {
  value: null,
  onChange: noop,
}

MoneyInput.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
}

export default MoneyInput
