import React from 'react'

import get from 'lodash/get'

import Container from './styles'

type Props = {
  glyph: any
  alt?: string
  height?: number | string
  size?: number | string
  width?: number | string
}

function Icon({ glyph, alt, ...rest }: Props) {
  return (
    <Container {...rest}>
      {alt && <title>{alt}</title>}
      <use xlinkHref={`#${get(glyph, 'id')}`} />
    </Container>
  )
}

Icon.displayName = 'Icon'

Icon.defaultProps = {
  alt: null,
  size: 20,
  width: null,
  height: null,
}

export default Icon
