import { DateTime } from 'luxon'

import compact from 'lodash/compact'
import get from 'lodash/get'
import map from 'lodash/map'

import { _ } from 'Services/I18n'

import palette from 'Theme/v2/palette'

export function getFullName(profile) {
  const firstName = get(profile, 'firstName', '')
  const lastName = get(profile, 'lastName', '')

  return compact([firstName, lastName]).join(' ')
}

function convertWorkersToOptions(data) {
  return map(data, item => ({
    label: getFullName(get(item, 'user.profile')),
    value: get(item, 'id'),
    photoUrl: get(item, 'user.profile.photoUrl'),
    role: get(item, 'role.name', ''),
  }))
}

function convertWorkersUserToOptions(data) {
  return map(data, item => {
    const roleType = get(item, 'roleType')
    const role = roleType === 'worker' ? get(item, 'role.name') : roleType

    return {
      label: getFullName(get(item, 'profile')),
      value: get(item, 'userId'),
      photoUrl: get(item, 'profile.photoUrl'),
      role,
    }
  })
}

function convertProjectUsersToOptions(data) {
  return map(data, item => ({
    label: getFullName(get(item, 'profile')),
    value: get(item, 'id'),
    photoUrl: get(item, 'profile.photoUrl'),
    role: get(item, 'role.name', ''),
    profile: { photoUrl: get(item, 'profile.photoUrl') },
  }))
}

const TRANSPARENT_GRAY = 'rgba(0, 0, 0, 1)'
const NOT_AVAILABLE_COLOR = palette.danger900

function isStatusWithPausedNotifications(user) {
  return (
    user?.notificationSchedule?.pauseNotificationsEnd === user?.status?.endDate
  )
}

function getUserStatusColor(user) {
  return isStatusWithPausedNotifications(user)
    ? NOT_AVAILABLE_COLOR
    : TRANSPARENT_GRAY
}

function getUserTime(profile) {
  const timeZone = profile?.timeZone

  if (!timeZone) {
    return DateTime.now().toFormat('T')
  }

  return DateTime.now().setZone(timeZone).toFormat('T')
}

function getUserLocation(profile) {
  return profile?.location || _('noLocation')
}

export default {
  getFullName,
  getUserTime,
  getUserLocation,
  convertWorkersToOptions,
  convertWorkersUserToOptions,
  convertProjectUsersToOptions,
  getUserStatusColor,
  isStatusWithPausedNotifications,
}
