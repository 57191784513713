import { themeGet as baseThemeGet } from '@styled-system/theme-get'

import invariant from 'tiny-invariant'

import forOwn from 'lodash/forOwn'
import isFunction from 'lodash/isFunction'

import type { ComponentVariants, Theme, Themes } from 'Theme/types'
import { DEFAULT_VARIANT } from 'Theme/types'

import ThemeProvider from './Provider'
import themes from './themes'

import GlobalStyle from '../GlobalStyle'

// TODO: Remove after redesign completed
function themeGet(path: string) {
  return baseThemeGet(`v3.${path}`)
}

function createGetParam<TParams, TProps>(params: TParams) {
  return (paramName: keyof TParams) => (props: TProps) => {
    const variants: TParams[keyof TParams] | undefined = params?.[paramName]

    invariant(
      variants,
      `Variant "${paramName as string}" should be defined in params`,
    )

    let prop = DEFAULT_VARIANT

    // TODO: some magic here, need to test/check
    forOwn(props, (value, key) => {
      // @ts-ignore
      if (variants?.[key] && value) prop = key
    })

    // @ts-ignore

    return isFunction(variants[prop]) ? variants[prop](props) : variants[prop]
  }
}

export { createGetParam, GlobalStyle, themeGet, ThemeProvider, themes }
export type { ComponentVariants, Theme, Themes }
