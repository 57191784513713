const moneyFormatter =
  ({ locale, currency }) =>
  value => {
    const numberFormat = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
    })

    return numberFormat.format(value)
  }

const parseCost = value => value.replace(/,/g, '').replace(/\$/g, '')

export default { parseCost, moneyFormatter }
