import { persistState as persistor } from '@storeon/localstorage'
import { createStoreon } from 'storeon'

import { COOKIE_KEYS, LOCAL_STORAGE } from 'Constants/ids'
import {
  AUTH_STATE,
  I18N_STATE,
  MODAL_STATE,
  PERSIST_STATE,
} from 'Constants/store'

import { CookieStorage } from 'Services/CookiePersist'
import shared from 'Services/Shared'

import authModule, { AUTH_TOKEN_DEFAULTS } from './Modules/auth'
import i18nModule, { I18N_DEFAULTS } from './Modules/i18n'
import modalModule from './Modules/modal'
import persistModule from './Modules/persist'
import uiModule, { KEY as UI_KEY } from './Modules/ui'

export const STORE_CLEAR = 'clear'

// TODO: Rewrite store to TS, see example ui.ts

export function createStore() {
  const modules = [
    uiModule,
    i18nModule,
    modalModule,
    authModule,
    persistModule,
    persistor([AUTH_STATE, I18N_STATE, PERSIST_STATE], {
      key: COOKIE_KEYS.PERSIST,
      storage: CookieStorage,
    }),
    persistor([UI_KEY], { key: LOCAL_STORAGE.STORE }),
  ]

  const store = createStoreon(modules)

  shared.setStore(store)

  store.on(STORE_CLEAR, state => ({
    ...state,
    [MODAL_STATE]: null,
    [AUTH_STATE]: AUTH_TOKEN_DEFAULTS,
    [I18N_STATE]: I18N_DEFAULTS,
  }))

  return store
}
