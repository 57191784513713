function safeGetStorage() {
  if (typeof localStorage === 'undefined') return null

  return localStorage
}

function safeGetItem(key) {
  if (typeof localStorage === 'undefined') return null

  return localStorage.getItem(key)
}

function safeSetItem(key, value) {
  if (typeof localStorage === 'undefined') return

  localStorage.setItem(key, value)
}

function safeRemoveItem(key) {
  if (typeof localStorage === 'undefined') return

  localStorage.removeItem(key)
}

export default { safeGetStorage, safeGetItem, safeSetItem, safeRemoveItem }
