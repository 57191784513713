import styled, { css } from 'styled-components'
import { space, SpaceProps, styleFn } from 'styled-system'

import { Row } from 'Components/UI/_v2/Flex'

import { createGetParam, themeGet } from 'Theme/v2'

export type SizeProps = {
  large?: boolean
  small?: boolean
}

export type ContainerProps = SpaceProps & {
  withSeparator?: boolean
  fullWidth?: boolean
}

const withSeparatorCss = ({ withSeparator }: ContainerProps) =>
  withSeparator &&
  css`
    display: flex;
    align-items: center;

    ::after {
      content: '';
      height: 1px;
      width: 100%;
      margin-left: 16px;
      background-color: ${themeGet('colors.divider.default')};
    }
  `

export const Container = styled(Row)<ContainerProps>`
  ${space}
  ${withSeparatorCss}
`

const wrapperFullWidthCss: styleFn = ({ fullWidth }: ContainerProps) =>
  fullWidth &&
  css`
    justify-content: center;
    > button {
      width: 100%;
    }
  `

export const Wrapper = styled(Row)<ContainerProps>`
  background-color: ${themeGet('colors.background.subblock')};
  border-radius: 8px;
  padding: 2px;

  ${wrapperFullWidthCss}
`

const sizeParams = {
  paddingY: {
    large: 8,
    default: 6,
    small: 4,
  },
  paddingX: {
    large: 17.7,
    default: 20.2,
    small: 23.2,
  },
  lineHeight: {
    large: 24,
    default: 20,
    small: 16,
  },
  fontSize: {
    large: themeGet('fontSizes.2'),
    default: themeGet('fontSizes.1'),
    small: themeGet('fontSizes.0'),
  },
}

const getSizeParam = createGetParam<typeof sizeParams, SizeProps>(sizeParams)

type SegmentProps = {
  active: boolean
}

const activeSegmentCss: styleFn = ({ active }: SegmentProps) =>
  active &&
  css`
    background: ${themeGet('colors.background.block')};
    box-shadow: 0 1px 16px -2px rgba(0, 0, 0, 0.05);
    color: ${themeGet('colors.text.heading')};
  `

export const Segment = styled.button<SegmentProps & SizeProps>`
  color: ${themeGet('colors.text.body')};
  border: none;
  font-size: ${getSizeParam('fontSize')}px;
  line-height: ${getSizeParam('lineHeight')}px;
  padding: ${getSizeParam('paddingY')}px ${getSizeParam('paddingX')}px;
  background-color: ${themeGet('colors.background.subblock')};
  border-radius: 6px;
  cursor: pointer;
  font-weight: ${themeGet('fontWeights.2')};
  white-space: nowrap;

  ${activeSegmentCss}
`
