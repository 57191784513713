// @ts-strict-ignore
import React, { useState } from 'react'

import { pick } from '@styled-system/props'

import Tippy, { TippyProps } from '@tippyjs/react/headless'

import isFunction from 'lodash/isFunction'

import {
  Arrow,
  ContentWrapper,
  PopoverBlock,
  PopoverBlockProps,
} from './styles'

type Props = TippyProps &
  PopoverBlockProps & {
    plugins?: any[]
    withArrow?: boolean
    withBackground?: boolean
    interactive?: boolean
  }

function Popover({
  content,
  delay,
  offset,
  plugins,
  render,
  withArrow,
  withBackground,
  ...rest
}: Props) {
  const [mounted, setMounted] = useState(false)
  const [arrow, setArrow] = useState(null)

  const lazyPlugin = {
    fn: () => ({
      onShow: () => setMounted(true),
      onHidden: () => setMounted(false),
    }),
  }

  const computedProps = { content, offset, plugins, render, ...rest }

  computedProps.plugins = [lazyPlugin].concat(plugins || [])

  computedProps.render = attrs =>
    mounted ? (
      <PopoverBlock
        {...pick(rest)}
        {...attrs}
        withoutBackground={!withBackground}
      >
        {withArrow && <Arrow ref={setArrow} />}
        <ContentWrapper>
          {isFunction(render) ? render(attrs) : content}
        </ContentWrapper>
      </PopoverBlock>
    ) : (
      ''
    )

  return (
    <Tippy
      delay={delay}
      popperOptions={{
        modifiers: [
          {
            name: 'arrow',
            options: {
              element: arrow,
            },
          },
          {
            name: 'flip',
            options: {
              padding: { top: 64 },
            },
          },
        ],
      }}
      {...computedProps}
    />
  )
}

Popover.defaultProps = {
  content: '',
  delay: [270, 0],
  offset: [0, 8],
  plugins: [],
  withArrow: false,
  withBackground: true,
} as Props

export default Popover
