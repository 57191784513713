import React, {
  forwardRef,
  ReactNode,
  Ref,
  useCallback,
  useMemo,
  useState,
} from 'react'

import { FiEye, FiEyeOff } from 'react-icons/fi'

import isArray from 'lodash/isArray'
import map from 'lodash/map'

import Optional from 'Components/UI/_v2/Optional'

import {
  Caption,
  Container,
  ContainerProps,
  DangerIcon,
  InfoIcon,
  PasswordIconWrapper,
  StyledInput,
  StyledInputProps,
  SuccessIcon,
  Wrapper,
  WrapperProps,
} from './styles'

import Label from '../Label'

type Props = ContainerProps &
  StyledInputProps &
  WrapperProps & {
    value?: string | number
    defaultValue?: string | number
    required?: boolean
    disabled?: boolean
    optional?: boolean
    label?: ReactNode
    caption?: string
    placeholder?: string
    maxLength?: number
    min?: number
    max?: number
    readOnly?: boolean
    renderBeforeElement?: () => React.ReactNode
    renderAfterElement?: (disabled: boolean) => React.ReactNode
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
    type?: React.HTMLProps<HTMLInputElement>['type']
    onBlur?: (event: React.FocusEvent<HTMLElement>) => void
    onFocus?: (event: React.FocusEvent<HTMLElement>) => void
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void
  }

function Input(
  {
    caption,
    required,
    label,
    optional,
    placeholder,
    renderAfterElement,
    renderBeforeElement,
    success,
    danger,
    disabled,
    isMulti,
    cols,
    rows,
    resize,
    type,
    small,
    min,
    max,
    large,
    defaultValue,
    value,
    mask,
    showMask,
    maxLength,
    readOnly,
    onChange,
    onBlur,
    onFocus,
    onKeyDown,
    ...rest
  }: Props,
  ref?: Ref<HTMLInputElement>,
) {
  const [isActive, setIsActive] = useState(false)
  const [shownPassword, setShownPassword] = useState(false)

  const handleBlur = useCallback<React.FocusEventHandler<HTMLInputElement>>(
    event => {
      setIsActive(false)
      onBlur?.(event)
    },
    [setIsActive, onBlur],
  )

  const handleFocus = useCallback<React.FocusEventHandler<HTMLInputElement>>(
    event => {
      setIsActive(true)
      onFocus?.(event)
    },
    [setIsActive, onFocus],
  )

  const renderCaption = useMemo(() => {
    if (!caption) return null

    if (success) {
      return (
        <Caption mt={2} success>
          <SuccessIcon mr="5px" mt={2} /> {caption}
        </Caption>
      )
    }

    if (danger) {
      return isArray(caption) ? (
        map(caption, text => (
          <Caption danger mt={2}>
            <DangerIcon mr="5px" mt={2} /> {text}
          </Caption>
        ))
      ) : (
        <Caption danger mt={2}>
          <DangerIcon mr="5px" mt={2} /> {caption}
        </Caption>
      )
    }

    return (
      <Caption mt={2}>
        <InfoIcon mr="5px" mt={2} /> {caption}
      </Caption>
    )
  }, [caption, success, danger])

  return (
    <Wrapper {...rest} large={large} small={small}>
      {label && (
        <Label mb={2}>
          {label} {optional && <Optional />}
        </Label>
      )}

      <Container
        active={isActive}
        danger={danger}
        disabled={disabled}
        large={large}
        small={small}
        success={success}
      >
        {renderBeforeElement && <>{renderBeforeElement()}</>}

        <StyledInput
          cols={cols}
          defaultValue={defaultValue}
          disabled={disabled}
          isMulti={isMulti}
          mask={mask}
          max={max}
          maxLength={maxLength}
          min={min}
          placeholder={placeholder}
          readOnly={readOnly}
          ref={ref}
          required={required}
          resize={resize}
          rows={rows}
          showMask={showMask}
          type={type === 'password' && shownPassword ? 'text' : type}
          value={value}
          onBlur={handleBlur}
          onChange={onChange}
          onFocus={handleFocus}
          onKeyDown={onKeyDown}
        />

        {renderAfterElement && <>{renderAfterElement(!!disabled)}</>}
        {type === 'password' && (
          <PasswordIconWrapper onClick={() => setShownPassword(!shownPassword)}>
            {shownPassword ? <FiEyeOff /> : <FiEye />}
          </PasswordIconWrapper>
        )}
      </Container>

      {renderCaption}
    </Wrapper>
  )
}

export default forwardRef(Input)
