// @ts-strict-ignore
import { useMemo } from 'react'

import findDeep from 'deepdash/findDeep'

import has from 'lodash/has'

import {
  HELP_CENTER_SECTION_SLUGS,
  HelpCenterSectionBySlug,
  TargetHelpCenterNode,
} from 'Constants/helpCenter'

function useHelpCenterNodeBySlug(
  sectionsBySlug: HelpCenterSectionBySlug,
  targetSlug?: String,
): TargetHelpCenterNode {
  return useMemo(() => {
    const targetNode: TargetHelpCenterNode = {}
    if (!sectionsBySlug || !targetSlug) return targetNode

    if (targetSlug === HELP_CENTER_SECTION_SLUGS.helpCenter) {
      return targetNode
    }

    const deepSlug = findDeep(
      sectionsBySlug,
      (value, key, parentValue) => parentValue.slug === targetSlug,
      { pathFormat: 'array' },
    )

    const enclosingNode = deepSlug?.parent

    if (!enclosingNode) return targetNode

    targetNode.adjacentNodes =
      enclosingNode.path === targetSlug
        ? enclosingNode.sections
        : deepSlug.context?.parent?.parent?.value

    const hasArticles = has(enclosingNode, 'articles')

    if (hasArticles) {
      targetNode.section = enclosingNode
    } else {
      targetNode.article = enclosingNode
    }

    return targetNode
  }, [sectionsBySlug, targetSlug])
}

export default useHelpCenterNodeBySlug
