import React from 'react'
import { Router } from 'react-router-dom'

import { ApolloProvider } from '@apollo/client'
import { LocationProvider } from 'Context/LocationContext'
import { createBrowserHistory } from 'history'
import { StoreContext } from 'storeon/react'

import { ErrorBoundary } from 'Components/Blocks'

import App from 'Containers/App'

import apiCall from 'Services/Api'
import createApolloClient from 'Services/Apollo'
import { initTranslations } from 'Services/I18n'
import shared from 'Services/Shared'
import { createStore } from 'Services/Store'

import 'react-toastify/dist/ReactToastify.min.css'

shared.setApi(apiCall())

const store = createStore()

initTranslations({ store })

const history = createBrowserHistory()

const client = createApolloClient({ history })

// Load all components needed before starting rendering
function Container() {
  return (
    <ErrorBoundary>
      <ApolloProvider client={client}>
        <StoreContext.Provider value={store}>
          <LocationProvider>
            <Router history={history}>
              <App />
            </Router>
          </LocationProvider>
        </StoreContext.Provider>
      </ApolloProvider>
    </ErrorBoundary>
  )
}

export default Container
