import React, { ReactNode, useMemo } from 'react'

import { Row, Text, Tooltip } from 'Components/UI/_v2'

import { LegendDot, LegendHorizontalBar, LegendVerticalBar } from './styles'

type Props = {
  horizontalBar?: boolean
  verticalBar?: boolean
  color?: string
  label?: ReactNode
  value?: ReactNode
  tooltip?: ReactNode
}

function LegendItem({
  horizontalBar,
  verticalBar,
  color,
  label,
  tooltip,
  value,
}: Props) {
  const mark = useMemo(() => {
    if (horizontalBar) {
      return <LegendHorizontalBar bg={color} />
    }
    if (verticalBar) {
      return <LegendVerticalBar bg={color} />
    }
    return <LegendDot bg={color} />
  }, [color, horizontalBar, verticalBar])

  return (
    <Row center gap={3}>
      {tooltip ? (
        <Tooltip content={tooltip} small>
          {mark}
        </Tooltip>
      ) : (
        mark
      )}
      <Text body caption3>
        <strong>
          {label}
          {value && ':'}
        </strong>{' '}
        {value}
      </Text>
    </Row>
  )
}

export default LegendItem
