import React from 'react'

import { ApplicationLogo, ApplicationLogoKind } from 'Components/Blocks/_v2'

import { ROOT } from 'Constants/paths'

import { useAppContext } from 'Hooks'

import { Container, StyledLink } from './styles'

function Header() {
  const { electron } = useAppContext()

  return (
    <Container>
      {electron ? (
        <ApplicationLogo kind={ApplicationLogoKind.WideColor} />
      ) : (
        <StyledLink href={ROOT} to={{ pathname: ROOT }}>
          <ApplicationLogo kind={ApplicationLogoKind.WideColor} />
        </StyledLink>
      )}
    </Container>
  )
}

export default Header
