// @ts-strict-ignore
import jwtDecode from 'jwt-decode'

import { Verification, VerificationKind } from 'Interfaces/Auth'

import shared from 'Services/Shared'

interface SuccessResult {
  ok: boolean
}

type TokenResult = {
  accessToken: string
  refreshToken?: string
}

type LoginParams = {
  email: string
  password: string
  withRefresh?: boolean
  verification?: VerificationKind
  code?: string
  deviceId?: string
}

type LoginResult = SuccessResult &
  TokenResult & {
    verification?: Verification
  }

export async function login({
  email,
  password,
  withRefresh = false,
  verification,
  code,
  deviceId,
}: LoginParams) {
  const result = await shared.apiCall({
    endpoint: '/auth/login',
    method: 'POST',
    query: { email, password, withRefresh, verification, code, deviceId },
  })

  const decodedToken = jwtDecode<any>(result.accessToken)

  return {
    ...result,
    verification: decodedToken.verification,
  } as LoginResult
}

export async function refresh({ refreshToken }) {
  return shared.apiCall({
    endpoint: '/auth/refresh',
    method: 'POST',
    query: { refreshToken },
  })
}

type RegisterByEmailParams = {
  email: string
}

export async function registerByEmail({
  email,
}: RegisterByEmailParams): Promise<SuccessResult> {
  return shared.apiCall({
    endpoint: '/auth/register-by-email',
    method: 'POST',
    query: { email },
  })
}

export async function resendVerifyCode({
  email,
}: RegisterByEmailParams): Promise<SuccessResult> {
  return shared.apiCall({
    endpoint: '/auth/resend-verify-code',
    method: 'POST',
    query: { email },
  })
}

export async function requestResetPassword({ email }) {
  return shared.apiCall({
    endpoint: '/auth/request-reset-password',
    method: 'POST',
    query: { email },
  })
}

export async function checkResetToken({ token }) {
  return shared.apiCall({
    endpoint: '/auth/check-reset-token',
    method: 'POST',
    query: { token },
  })
}

export async function resetPassword({ password, token }) {
  return shared.apiCall({
    endpoint: '/auth/reset-password',
    method: 'POST',
    query: { password, token },
  })
}

export async function changePassword({ password, currentPassword }) {
  return shared.apiCall({
    endpoint: '/auth/change-password',
    method: 'POST',
    query: { currentPassword, password },
  })
}

export async function verifyEmail({ password, token, withRefresh = false }) {
  return shared.apiCall({
    endpoint: '/auth/verify-email',
    method: 'POST',
    query: { password, token, withRefresh },
  })
}

interface VerifyEmailCodeParams {
  email: string
  code: string
  password: string
}

export async function verifyEmailCode({
  email,
  code,
  password,
}: VerifyEmailCodeParams): Promise<TokenResult> {
  return shared.apiCall({
    endpoint: '/auth/verify-email-code',
    method: 'POST',
    query: { email, code, password },
  })
}

type CheckVerifyCodeParams = {
  email: string
  code: string
}

export async function checkVerifyCode({
  email,
  code,
}: CheckVerifyCodeParams): Promise<SuccessResult> {
  return shared.apiCall({
    endpoint: '/auth/check-verify-code',
    method: 'GET',
    query: { email, code },
  })
}

export async function logout() {
  return shared.apiCall({
    endpoint: '/auth/logout',
  })
}
