import React, { useRef } from 'react'

import {
  arrow,
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingPortal,
  offset,
  Placement,
  shift,
  size,
  useDismiss,
  useFloating,
  useInteractions,
} from '@floating-ui/react-dom-interactions'

import { Arrow, ContentWrapper, PopoverBlock } from './styles'

const OFFSET = 8

type Props = {
  blockMinWidth?: number | string
  content?: React.ReactNode
  children?: React.ReactElement
  isOpen?: boolean
  placement?: Placement
  withArrow?: boolean
  onOpenChange?: (open: boolean) => void
}

function ScrollablePopover({
  blockMinWidth,
  children,
  content,
  isOpen,
  placement,
  withArrow,
  onOpenChange,
}: Props) {
  const arrowRef = useRef(null)

  const {
    x,
    y,
    reference,
    floating,
    strategy,
    context,
    placement: placementActual,
    middlewareData: { arrow: { x: arrowX, y: arrowY } = {} },
  } = useFloating<HTMLDivElement>({
    open: isOpen,
    onOpenChange,
    middleware: [
      offset(OFFSET),
      flip(),
      shift(),
      withArrow && arrow({ element: arrowRef }),
      size({
        apply({ availableWidth, availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            maxWidth: `${availableWidth - OFFSET}px`,
            maxHeight: `${availableHeight - OFFSET}px`,
          })
        },
      }),
    ],
    placement,
    whileElementsMounted: autoUpdate,
  })

  const dismiss = useDismiss(context)

  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss])

  const staticSide = withArrow && getStaticSide(placementActual)

  return (
    <>
      {children &&
        React.cloneElement(children, {
          ref: reference,
          ...getReferenceProps(),
        })}
      <FloatingPortal>
        {isOpen && (
          <FloatingFocusManager context={context} modal={false}>
            <PopoverBlock
              minWidth={blockMinWidth}
              ref={floating}
              style={{
                position: strategy,
                left: x ?? 0,
                top: y ?? 0,
              }}
              {...getFloatingProps()}
            >
              <ContentWrapper>{content}</ContentWrapper>
              {withArrow && (
                <Arrow
                  ref={arrowRef}
                  style={{
                    left: arrowX != null ? `${arrowX}px` : '',
                    top: arrowY != null ? `${arrowY}px` : '',
                    ...(staticSide && { [staticSide]: '-5px' }),
                  }}
                />
              )}
            </PopoverBlock>
          </FloatingFocusManager>
        )}
      </FloatingPortal>
    </>
  )
}

function getStaticSide(placement: Placement) {
  return {
    top: 'bottom',
    right: 'left',
    bottom: 'top',
    left: 'right',
  }[placement.split('-')[0]]
}

export default ScrollablePopover
