// @ts-strict-ignore
import { Observable } from '@apollo/client'
import { onError } from '@apollo/client/link/error'

import forEach from 'lodash/forEach'

import { refresh } from 'Services/Api/Queries/auth'
import Auth from 'Services/Auth'
import {
  getAccessToken,
  getRefreshToken,
  getRememberMe,
} from 'Services/Store/Modules/auth'

let isFetchingToken = false
let subscribers = []

const subscribeTokenRefresh = cb => {
  subscribers.push(cb)
}
const onTokenRefreshed = err => {
  subscribers.map(cb => cb(err))
}

/* eslint-disable no-console, consistent-return */
export default function createErrorLink() {
  return onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (networkError) console.error(`[Network error]: ${networkError}`)

    if (graphQLErrors) {
      forEach(graphQLErrors, ({ message, positions, path }) => {
        if (message === 'PersistedQueryNotFound') return
        // eslint-disable-next-line no-console
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
            positions,
          )}, Path: ${path}`,
        )
      })
    }

    const isUnauthorizedError =
      graphQLErrors?.[0]?.message === 'generic.notAuthorized' ||
      graphQLErrors?.[0]?.message === 'Anonymous access is denied.'

    if (isUnauthorizedError) {
      const refreshToken = getRefreshToken()

      if (refreshToken) {
        // @ts-ignore
        return new Observable(async observer => {
          try {
            const retryRequest = () => {
              operation.setContext(({ headers = {} }) => {
                const accessToken = getAccessToken()

                return {
                  headers: {
                    ...headers,
                    Authorization: accessToken ? `Bearer ${accessToken}` : null,
                  },
                }
              })

              const subscriber = {
                next: observer.next.bind(observer),
                error: observer.error.bind(observer),
                complete: observer.complete.bind(observer),
              }

              return forward(operation).subscribe(subscriber)
            }

            if (!isFetchingToken) {
              isFetchingToken = true

              try {
                const result = await refresh({ refreshToken })

                if (result?.ok) {
                  await Auth.signIn({
                    accessToken: result.accessToken,
                    refreshToken,
                    withRefresh: getRememberMe(),
                    refetch: false,
                  })
                } else {
                  throw new Error('Refresh token expired')
                }

                isFetchingToken = false
                onTokenRefreshed(null)
                subscribers = []

                return retryRequest()
              } catch (e) {
                onTokenRefreshed(new Error('Unable to refresh access token'))

                subscribers = []
                isFetchingToken = false

                await Auth.signOut()
              }
            }

            return new Promise(resolve => {
              subscribeTokenRefresh(errRefreshing => {
                if (!errRefreshing) return resolve(retryRequest())
              })
            })
          } catch (e) {
            observer.error(e)
          }
        })
      }

      Auth.signOut()

      return forward(operation)
    }
  })
}
