// @ts-strict-ignore
import numeral from 'numeral'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

import replace from 'lodash/replace'

import {
  CURRENCY,
  CURRENCY_FORMATS,
  CURRENCY_MULTIPLIERS,
  CURRENCY_SIGNS,
  DEFAULT_FORMAT,
  DEFAULT_MULTIPLIER,
} from 'Constants/currencies'

type Currency = string

function fromLocal(value: string, currency?: Currency) {
  // eslint-disable-next-line no-console
  if (!currency) console.warn(`Should pass currency in 'to'`)
  const multiplier = CURRENCY_MULTIPLIERS[currency] || DEFAULT_MULTIPLIER
  const number = parseFloat(value) || 0
  return Math.round(number * multiplier)
}

function toLocal(value: number, currency?: Currency) {
  // eslint-disable-next-line no-console
  if (!currency) console.warn(`Should pass currency in 'to'`)
  const multiplier = CURRENCY_MULTIPLIERS[currency] || DEFAULT_MULTIPLIER
  return value / multiplier
}

function format(value: number, currency?: Currency) {
  return numeral(value).format(CURRENCY_FORMATS[currency] || DEFAULT_FORMAT)
}

function toLocalFormat(value: number, currency?: Currency) {
  return format(toLocal(value, currency), currency)
}

function createMask(currency?: Currency) {
  const mask = {
    prefix: '',
    suffix: '',
    allowDecimal: true,
    // TODO: some currencies have fractions of 5
    decimalLimit: Math.log10(
      CURRENCY_MULTIPLIERS[currency] || DEFAULT_MULTIPLIER,
    ),
    // --
  }

  if (currency === CURRENCY.USD) {
    mask.prefix = CURRENCY_SIGNS[currency]
  }
  if (currency === CURRENCY.RUB) {
    mask.suffix = CURRENCY_SIGNS[currency]
  }

  return createNumberMask(mask)
}

const mask = createNumberMask({
  prefix: '',
  suffix: '',
  allowDecimal: true,
  decimalLimit: 2,
  requireDecimal: false,
  allowNegative: false,
})

/** @deprecated use fromLocal instead */
function parseFromInput(input) {
  const result = Math.round(parseFloat(replace(input, ',', '')) * 100)
  return Number.isFinite(result) ? result : null
}

/** @deprecated use toLocal instead */
function formatForInput(money) {
  if (!Number.isFinite(money)) {
    return ''
  }
  return String(money / 100)
}

export default {
  fromLocal,
  toLocal,
  format,
  toLocalFormat,
  createMask,
  mask,
  parseFromInput,
  formatForInput,
}
