import React, { ComponentProps } from 'react'
import { Field } from 'react-final-form'

import Toggle from './Toggle'

type Props = ComponentProps<typeof Toggle> & {
  name: string
}

function ToggleField({ name, ...rest }: Props) {
  return (
    <Field name={name} type="checkbox">
      {({ input }) => <Toggle {...rest} {...input} />}
    </Field>
  )
}

export default ToggleField
