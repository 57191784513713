import { rgba } from 'polished'

const palette = {
  white: '#ffffff',
  black: '#000000',

  antiFlashWhite: '#f0f2f5',
  batteryChargedBlue: '#1ab4d7',
  blueYonder: '#416da7',
  brightGray: '#e9eced',
  cultured: '#f3f5f7',
  ghostWhite: '#f9fafb',
  internationalOrange: '#ff4f00',
  lavender: '#e9eafb',
  mint: '#3cbf8e',
  snow: '#fbfaf9',
  platinum: '#e8e8e8',

  neutral100: '#1a1a1a',
  neutral200: '#262626',
  neutral300: '#333333',
  neutral400: '#4d4d4d',
  neutral500: '#7a7a7a',
  neutral600: '#b2b2b2',
  neutral700: '#e5e5e5',
  neutral800: '#f2f2f2',
  neutral900: '#f7f7f7',

  primary100: '#0b023b',
  primary200: '#2d1c82',
  primary300: '#391dc3',
  primary400: '#3a0ebe',
  primary500: '#7257fa',
  primary600: rgba('#7257fa', 0.6),
  primary700: rgba('#7257fa', 0.33),
  primary800: rgba('#7257fa', 0.2),
  primary900: rgba('#7257fa', 0.1),

  success100: '#072c14',
  success200: '#0e5827',
  success300: '#16833b',
  success400: '#1daf4e',
  success500: '#24db62',
  success600: rgba('#24db62', 0.6),
  success700: rgba('#24db62', 0.33),
  success800: rgba('#24db62', 0.2),
  success900: rgba('#24db62', 0.1),

  warning100: '#2f2304',
  warning200: '#5f4607',
  warning300: '#8e6a0b',
  warning400: '#be8d0e',
  warning500: '#edb012',
  warning600: rgba('#edb012', 0.6),
  warning700: rgba('#edb012', 0.33),
  warning800: rgba('#edb012', 0.2),
  warning900: rgba('#edb012', 0.1),

  danger100: '#2d0606',
  danger200: '#5b0b0b',
  danger300: '#881111',
  danger400: '#b61616',
  danger500: '#e31c1c',
  danger600: rgba('#e31c1c', 0.6),
  danger700: rgba('#e31c1c', 0.33),
  danger800: rgba('#e31c1c', 0.2),
  danger900: rgba('#e31c1c', 0.1),

  info100: '#092a28',
  info200: '#125551',
  info300: '#1c7f79',
  info400: '#25aaa2',
  info500: '#2ed4ca',
  info600: rgba('#2ed4ca', 0.6),
  info700: rgba('#2ed4ca', 0.33),
  info800: rgba('#2ed4ca', 0.2),
  info900: rgba('#2ed4ca', 0.1),
}

export default palette
