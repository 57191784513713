import React, { ReactNode } from 'react'

import { VerifyPhoneCoordinator, VerifyTOTPCoordinator } from 'Coordinators'

import { HelmetTitle } from 'Components/Blocks'
import { Header, IntroVideo } from 'Components/Blocks/_v2'

import { useAppContext } from 'Hooks'

import { useScopedI18n } from 'Services/I18n'

import { Container, Content, FormHolder } from './styles'

type Props = {
  children?: ReactNode
}

function RequestDemo({ children }: Props) {
  const s = useScopedI18n('auth')
  const { electron } = useAppContext()

  return (
    <Container electron={electron}>
      <HelmetTitle title={s('helmet.default')} />

      <Content>
        <Header />

        <FormHolder>{children}</FormHolder>
      </Content>

      <IntroVideo />

      <VerifyPhoneCoordinator />

      <VerifyTOTPCoordinator />
    </Container>
  )
}

export default RequestDemo
