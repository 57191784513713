import { ZIndices } from 'Theme/types'

const zIndices: ZIndices = {
  projectHeader: 200,
  chatPanel: 300,
  projectNavigation: 400,
  header: 500,
  inboxPanel: 600,
  navigationPanel: 700,
  blockerOverlay: 800,
  blocker: 900,
  modal: 1000,
  dropdown: 1500,
  modalFullscreen: 2000,
}

export default zIndices
