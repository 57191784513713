import { Location, PLAN_MONTHLY_PAYMENT, TRIAL_DAYS } from 'Constants/billing'

export const FREE_TRIAL_PERIOD_DAYS = TRIAL_DAYS

export enum CurrencyKey {
  AUD = 'AUD',
  USD = 'USD',
}

export const WORKLAIR_MONTHLY_COST_USER_MONTH = {
  [CurrencyKey.AUD]: PLAN_MONTHLY_PAYMENT[Location.Au].PRO_MONTHLY / 100,
  [CurrencyKey.USD]: PLAN_MONTHLY_PAYMENT[Location.Default].PRO_MONTHLY / 100,
}

export const WORKLAIR_ANNUALLY_COST_USER_MONTH = {
  [CurrencyKey.AUD]: PLAN_MONTHLY_PAYMENT[Location.Au].PRO_ANNUALLY / 100,
  [CurrencyKey.USD]: PLAN_MONTHLY_PAYMENT[Location.Default].PRO_ANNUALLY / 100,
}

export const INDUSTRY_STANDARD_MONTHLY_COST_USER_MONTH = {
  asana: {
    [CurrencyKey.AUD]: 16.73,
    [CurrencyKey.USD]: 10.9,
  },
  slack: {
    [CurrencyKey.AUD]: 13.47,
    [CurrencyKey.USD]: 8.775,
  },
  hubstaff: {
    [CurrencyKey.AUD]: 15.35,
    [CurrencyKey.USD]: 10,
  },
  intercom: {
    [CurrencyKey.AUD]: 59.86,
    [CurrencyKey.USD]: 39,
  },
  vacationTrackingApp: {
    [CurrencyKey.AUD]: 16.73,
    [CurrencyKey.USD]: 10.9,
  },
  spreadsheets: {
    [CurrencyKey.AUD]: 0,
    [CurrencyKey.USD]: 0,
  },
}

export function getIndustryStandartSummUserMonth(location: Location) {
  return Object.values(INDUSTRY_STANDARD_MONTHLY_COST_USER_MONTH).reduce(
    (acc, value) => {
      const price =
        location === Location.Au
          ? value[CurrencyKey.AUD]
          : value[CurrencyKey.USD]

      return acc + price
    },
    0,
  )
}

export const YOU_WILL_SAVE_USERS_NUMBER = 10

export function getYouWillSavePerYear(location: Location) {
  return (
    (getIndustryStandartSummUserMonth(location) -
      (location === Location.Au
        ? WORKLAIR_ANNUALLY_COST_USER_MONTH[CurrencyKey.AUD]
        : WORKLAIR_ANNUALLY_COST_USER_MONTH[CurrencyKey.USD])) *
    YOU_WILL_SAVE_USERS_NUMBER *
    12
  )
}

export const WORKLAIR_AI_ADDON_COSTS = {
  tokensAmount10000: {
    tokens: 10000,
    prompts: 100,
    price: {
      [CurrencyKey.AUD]: 31,
      [CurrencyKey.USD]: 20,
    },
  },
  tokensAmount50000: {
    tokens: 50000,
    prompts: 500,
    price: {
      [CurrencyKey.AUD]: 115,
      [CurrencyKey.USD]: 75,
    },
  },
  tokensAmount100000: {
    tokens: 100000,
    prompts: 1000,
    price: {
      [CurrencyKey.AUD]: 154,
      [CurrencyKey.USD]: 100,
    },
  },
}
