import React, { useCallback } from 'react'
import { useHistory } from 'react-router'

import { X } from 'phosphor-react'

import { ApplicationLogo, ApplicationLogoKind } from 'Components/Blocks/_v2'

import { ROOT } from 'Constants/paths'

import { Container, Content, StyledButton, StyledLink } from './styles'

function Header() {
  const history = useHistory()

  const handleClose = useCallback(() => {
    history.goBack()
  }, [history])

  return (
    <Container>
      <Content>
        <StyledLink href={ROOT} to={{ pathname: ROOT }}>
          <ApplicationLogo
            height={34}
            kind={ApplicationLogoKind.WideColor}
            width={135}
          />
        </StyledLink>

        <StyledButton onClick={handleClose}>
          <X size={24} />
        </StyledButton>
      </Content>
    </Container>
  )
}

export default Header
