import React, { ComponentProps } from 'react'

import { Container } from './styles'

type Props = ComponentProps<typeof Container> & {
  count?: number
}

function CountBadge({ count, ...rest }: Props) {
  return <Container {...rest}>{count}</Container>
}

export default CountBadge
