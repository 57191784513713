// @ts-strict-ignore
import React, { ReactElement, useMemo } from 'react'

import { useTheme } from 'styled-components'
import { SpaceProps } from 'styled-system'

import { Duration } from 'luxon'

import isNull from 'lodash/isNull'
import isObject from 'lodash/isObject'

import { Column } from 'Components/UI/_v2/Flex'
import Text from 'Components/UI/_v2/Text'

import { _ } from 'Services/I18n'

import { themeGet } from 'Theme/v2'

import { DelimiterBg, DelimiterFg } from './styles'

enum ValueKind {
  timeInline = 'timeInline',
  timeInlineWithSeconds = 'timeInlineWithSeconds',
  timeInlineRatio = 'timeInlineRatio',
  timeRatioInfo = 'timeRatioInfo',
  timeRatioInfoWithSeconds = 'timeRatioInfoWithSeconds',
}

type RatioValue = {
  current: number
  total?: number
}

type Props = SpaceProps & {
  large?: boolean
  currency?: string
  kind: ValueKind
  value: RatioValue | number
}

const TIME_FORMAT = 'hh:mm'
const TIME_FORMAT_WITH_SECONDS = 'hh:mm:ss'

function formatTime(seconds: number, format = TIME_FORMAT) {
  if (!Number.isFinite(seconds)) {
    return null
  }
  return Duration.fromObject({ seconds }).toFormat(
    format,
  ) as unknown as ReactElement
}

function TimeFormatter({
  kind,
  value = null,
  large,
  ...rest
}: Props): ReactElement {
  const theme = useTheme()

  const isLeft = useMemo(() => {
    if (!isObject(value)) return null

    return value.current <= value.total
  }, [value])

  const ratioColor = useMemo(() => {
    if (!isObject(value)) return null

    return isLeft
      ? themeGet('colors.success500')({ theme })
      : themeGet('colors.danger500')({ theme })
  }, [value, isLeft, theme])

  if (isNull(value)) return null

  const valueIsObject = isObject(value)

  switch (kind) {
    case ValueKind.timeInline:
      return formatTime(valueIsObject ? 0 : value)
    case ValueKind.timeInlineWithSeconds:
      return formatTime(valueIsObject ? 0 : value, TIME_FORMAT_WITH_SECONDS)
    case ValueKind.timeInlineRatio:
      return `${formatTime(valueIsObject ? value.current : 0)} / ${formatTime(
        valueIsObject ? value.total : 0,
      )}` as unknown as ReactElement
    default:
      break
  }

  return (
    <Column squeeze {...rest}>
      {kind === ValueKind.timeRatioInfo && valueIsObject && (
        <>
          <Text body caption2={!large} header6={large}>
            {formatTime(value.current)}
            {' / '}
            {formatTime(value.total)}
          </Text>

          <DelimiterBg large={large} my={large ? '6px' : '2px'}>
            <DelimiterFg
              bg={ratioColor}
              large={large}
              width={`${(value.current / value.total) * 100}%`}
            />
          </DelimiterBg>

          <Text caption2={large} caption3={!large} color={ratioColor} lowercase>
            <>
              {isLeft ? _('left') : _('over')}
              {': '}
              {formatTime(Math.abs(value.total - value.current))}
            </>
          </Text>
        </>
      )}

      {kind === ValueKind.timeRatioInfoWithSeconds && valueIsObject && (
        <>
          <Text body caption2={!large} header6={large}>
            {formatTime(value.current, TIME_FORMAT_WITH_SECONDS)}
          </Text>

          <DelimiterBg large={large} my={large ? '6px' : '2px'}>
            <DelimiterFg
              bg={ratioColor}
              large={large}
              width={`${(value.current / value.total) * 100}%`}
            />
          </DelimiterBg>

          <Text caption2={large} caption3={!large} color={ratioColor} lowercase>
            <>
              {isLeft ? _('left') : _('over')}
              {': '}
              {formatTime(
                Math.abs(value.total - value.current),
                TIME_FORMAT_WITH_SECONDS,
              )}
            </>
          </Text>
        </>
      )}
    </Column>
  )
}

export default Object.assign(TimeFormatter, { ValueKind })
