function safeGetStorage() {
  if (typeof sessionStorage === 'undefined') return null

  return sessionStorage
}

function safeGetItem(key) {
  if (typeof sessionStorage === 'undefined') return null

  return sessionStorage.getItem(key)
}

function safeSetItem(key, value) {
  if (typeof sessionStorage === 'undefined') return

  sessionStorage.setItem(key, value)
}

function safeRemoveItem(key) {
  if (typeof sessionStorage === 'undefined') return

  sessionStorage.removeItem(key)
}

export default { safeGetStorage, safeGetItem, safeSetItem, safeRemoveItem }
