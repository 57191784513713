// @ts-strict-ignore
import React, { useEffect } from 'react'
import { useLocation } from 'react-router'
import { animateScroll } from 'react-scroll'

import countries from 'i18n-iso-countries'
import english from 'i18n-iso-countries/langs/en.json'

import useOrganizationRedirect from 'Hooks/useOrganizationRedirect'

import Footer from './Footer'
import Header from './Header'
import { Container } from './styles'

countries.registerLocale(english)

function LandingLayout({ children }) {
  const location = useLocation()

  useOrganizationRedirect()

  useEffect(() => {
    animateScroll.scrollToTop()
  }, [location])

  return (
    <Container>
      <Header />
      {children}
      <Footer />
    </Container>
  )
}

export default LandingLayout
