export function getEmojiName(emoji: string) {
  switch (emoji) {
    case '👍':
      return ':thumb-up:'
    case '👎':
      return ':thumb-down:'
    case '😂':
      return ':tears-of-joy:'
    case '😍':
      return ':heart-eyes:'
    case '😮':
      return ':open-mouth:'
    case '🙌':
      return ':raised-hands:'

    default:
      return emoji
  }
}

export default {
  getEmojiName,
}
