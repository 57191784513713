import React, { useMemo } from 'react'

import { useTheme } from 'styled-components'

import { CaretRight } from 'phosphor-react'

import { androidGlyph, iosGlyph } from 'Assets/Svg/Apps'

import { Button, Column, Row, Text } from 'Components/UI/_v2'

import { GOOGLE_AUTHENTICATOR } from 'Constants/auth'

import { themeGet } from 'Theme/v2'

import { Content, Item, StoreIcon } from './styles'

type Props = {
  onFinish?: () => void
}

// TODO: localize
function VerifyTOTPCoordinatorModalInstallContent({ onFinish }: Props) {
  const theme = useTheme()
  const colors = useMemo(
    () => ({ icon: themeGet('colors.text.muted')({ theme }) }),
    [theme],
  )

  return (
    <Content>
      <Text body4 color="text.body">
        You&apos;ll need to install a Google Authenticator app on your phone to
        set up Two-Factor Authentication
      </Text>
      <Item href={GOOGLE_AUTHENTICATOR.APP_STORE_LINK} mt={6}>
        <StoreIcon glyph={iosGlyph} size={24} />
        <Row center fullWidth ml={4} spaceBetween>
          <Column>
            <Text color="text.heading" subheader2>
              Google Authenticator for iOS
            </Text>
            <Text caption3 color="text.muted" mt={1}>
              Get the app on the App Store
            </Text>
          </Column>
          <CaretRight color={colors.icon} size={16} weight="bold" />
        </Row>
      </Item>
      <Item href={GOOGLE_AUTHENTICATOR.GOOGLE_PLAY_LINK} mt={4}>
        <StoreIcon glyph={androidGlyph} size={24} />
        <Row center fullWidth ml={4} spaceBetween>
          <Column>
            <Text color="text.heading" subheader2>
              Google Authenticator for Android
            </Text>
            <Text caption3 color="text.muted" mt={1}>
              Get the app on the Play Store
            </Text>
          </Column>
          <CaretRight color={colors.icon} size={16} weight="bold" />
        </Row>
      </Item>
      <Row backgroundColor="divider.light" fullWidth height="1px" mt={6} />
      <Button mt={6} onClick={onFinish}>
        I opened the app and ready to scan
      </Button>
    </Content>
  )
}

export default VerifyTOTPCoordinatorModalInstallContent
