import styled, { css } from 'styled-components'
import { space, SpaceProps, styleFn } from 'styled-system'

import { CaretDown } from 'phosphor-react'

import { createGetParam, themeGet } from 'Theme/v2'

interface ChevronProps {
  open?: boolean
}
const sizes = {
  fontSize: {
    large: themeGet('fontSizes.2'),
    default: themeGet('fontSizes.1'),
    small: themeGet('fontSizes.0'),
  },
}

const getSizeParam = createGetParam<typeof sizes, SizeProps>(sizes)

const chevronOpenCss: styleFn = ({ open }: ChevronProps) =>
  open &&
  css`
    transform: rotate(-180deg);
  `

export const Chevron = styled(CaretDown).attrs({
  size: 16,
  weight: 'bold',
})<ChevronProps>`
  transition: transform ${themeGet('defaultTransitionTime')};

  ${chevronOpenCss}
`

interface SizeProps {
  large?: boolean
  small?: boolean
}

export const Content = styled.div<SizeProps>`
  color: ${themeGet('colors.text.body')};
  font-weight: ${themeGet('fontWeights.0')};
  font-size: ${getSizeParam('fontSize')}px;
`

export type ContainerProps = SpaceProps & SizeProps

export const Container = styled.div<ContainerProps>`
  ${space}
`
