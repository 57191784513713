import styled from 'styled-components'

import { themeGet } from 'Theme/v2'

export default styled.div`
  width: 100%;
  height: ${themeGet('sizes.electronHeaderHeight')}px;
  background: none;
  position: absolute;
  z-index: 999999;
  top: 0;
  left: 0;
  right: 0;
  -webkit-user-select: none;
  -webkit-app-region: drag;
`
