import styled from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  margin,
  MarginProps,
} from 'styled-system'

import { createGetParam, themeGet } from 'Theme/v2'

const delimiterSizes = {
  borderRadius: {
    large: 2,
    default: 1,
  },
  height: {
    large: 4,
    default: 2,
  },
}

interface DelimiterSizeProps {
  large?: boolean
}

const getDelimiterSizeParam = createGetParam<
  typeof delimiterSizes,
  DelimiterSizeProps
>(delimiterSizes)

export const DelimiterBg = styled.div<DelimiterSizeProps & MarginProps>`
  width: 100%;
  height: ${getDelimiterSizeParam('height')}px;
  border-radius: ${getDelimiterSizeParam('borderRadius')}px;
  background-color: ${themeGet('colors.neutral700')};

  ${margin}
`

export const DelimiterFg = styled.div<
  DelimiterSizeProps & ColorProps & LayoutProps
>`
  height: ${getDelimiterSizeParam('height')}px;
  border-radius: ${getDelimiterSizeParam('borderRadius')}px;
  max-width: 100%;

  ${layout}
  ${color}
`
