// @ts-strict-ignore
import React from 'react'
import { createRoot } from 'react-dom/client'

import * as Sentry from '@sentry/react'

import { IS_PRODUCTION, IS_STAGING, SENTRY } from 'Config/app'

import {
  RESIZE_OBSERVER_LOOP_ERROR_CHROME,
  RESIZE_OBSERVER_LOOP_ERROR_FIREFOX,
} from 'Constants/sentry'

import Container from './Container'

if (IS_PRODUCTION || IS_STAGING)
  Sentry.init({
    dsn: SENTRY.DSN,
    integrations: [],
    ignoreErrors: [
      RESIZE_OBSERVER_LOOP_ERROR_CHROME,
      RESIZE_OBSERVER_LOOP_ERROR_FIREFOX,
    ],
    tracesSampleRate: 0.0,
  })

const container = document.getElementById('root')
const root = createRoot(container)

root.render(<Container />)
