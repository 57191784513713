import styled from 'styled-components'
import { layout, LayoutProps } from 'styled-system'

import bgImage from 'Assets/Images/Auth/bg.jpg'
// TODO: convert to `webp` image
import containerImage from 'Assets/Images/Auth/welcome.png'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: url(${bgImage}) no-repeat center center;
  background-size: cover;
  min-height: 400px;
`

export const Content = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background: url(${containerImage}) no-repeat center center;
  background-size: contain;

  > button {
    box-shadow: 0 1px 44px -2px #0000007a;
  }
`

export const TextHolder = styled.div`
  position: absolute;
  bottom: 5%;
`

export const VideoHolder = styled.div<LayoutProps>`
  border-radius: 10px;
  overflow: hidden;

  ${layout}
`
