import React from 'react'

import styled from 'styled-components'
import { space, SpaceProps, width, WidthProps } from 'styled-system'

import { createGetParam, themeGet } from 'Theme/v2'

export interface SizeProps {
  large?: boolean
  small?: boolean
}

const sizes = {
  fontSize: {
    large: themeGet('fontSizes.2'),
    default: themeGet('fontSizes.1'),
    small: themeGet('fontSizes.0'),
  },
  lineHeight: {
    large: 24,
    default: 20,
    small: 16,
  },
  toggleHolderHeight: {
    large: 32,
    default: 28,
    small: 24,
  },
  toggleHolderWidth: {
    large: 56,
    default: 48,
    small: 40,
  },
  circleSize: {
    large: 28,
    default: 24,
    small: 20,
  },
  circleLeft: {
    large: 26,
    default: 22,
    small: 18,
  },
  labelTextFontSize: {
    large: themeGet('fontSizes.2'),
    default: themeGet('fontSizes.1'),
    small: themeGet('fontSizes.0'),
  },
  captionTextFontSize: {
    large: themeGet('fontSizes.1'),
    default: themeGet('fontSizes.0'),
    small: themeGet('fontSizes.0'),
  },
  captionTextLineHeight: {
    large: 20,
    default: 16,
    small: 16,
  },
}

const getSizeParam = createGetParam<typeof sizes, SizeProps>(sizes)

export const Circle = styled.div<SizeProps>`
  position: absolute;
  top: 2px;
  background: white;
  border-radius: 999px;
  transition: all ${themeGet('defaultTransitionTime')};
  height: ${getSizeParam('circleSize')}px;
  width: ${getSizeParam('circleSize')}px;
  left: ${getSizeParam('circleLeft')}px;
`

export const ToggleHolder = styled.div<SizeProps>`
  position: relative;
  border-radius: 999px;
  height: ${getSizeParam('toggleHolderHeight')}px;
  width: ${getSizeParam('toggleHolderWidth')}px;
`

export const LabelText = styled.div`
  color: ${themeGet('colors.text.heading')};
`

export const CaptionText = styled.div`
  color: ${themeGet('colors.text.muted')};
  font-weight: ${themeGet('fontWeights.1')};
`

export const InvisibleCheckbox = styled.input.attrs({ type: 'checkbox' })<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >
>`
  position: absolute;
  background: none;
  border: none;
  opacity: 0;
  pointer-events: none;
`

export type ContainerProps = SpaceProps & SizeProps & WidthProps

export const Container = styled.label<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  user-select: none;
  flex-shrink: 0;
  font-weight: ${themeGet('fontWeights.2')};
  font-size: 16px;
  gap: 12px;
  font-weight: ${getSizeParam('fontSize')}px;
  font-weight: ${themeGet('fontWeights.2')};
  line-height: ${getSizeParam('lineHeight')}px;

  & > input {
    :enabled ~ ${ToggleHolder} {
      cursor: pointer;
    }

    :not(:checked) ~ ${ToggleHolder} > ${Circle} {
      left: 2px;
    }

    :not(:checked) ~ ${ToggleHolder} {
      background-color: ${themeGet('colors.divider.default')};
    }

    :enabled:checked ~ ${ToggleHolder} {
      background-color: ${themeGet('colors.primary500')};
    }

    :disabled:checked ~ ${ToggleHolder} {
      background-color: ${themeGet('colors.primary700')};
    }

    :disabled:not(:checked) ~ ${ToggleHolder} > ${Circle} {
      background-color: ${themeGet('colors.divider.contrast')};
    }

    :focus-visible ~ ${ToggleHolder} {
      outline: 2px solid ${themeGet('colors.primary700')};
    }
  }

  ${CaptionText} {
    font-size: ${getSizeParam('captionTextFontSize')}px;
    line-height: ${getSizeParam('captionTextLineHeight')}px;
  }

  ${LabelText} {
    font-size: ${getSizeParam('labelTextFontSize')}px;
  }

  ${space}
  ${width};
`
