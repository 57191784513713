// @ts-strict-ignore
import React, { ComponentProps, ReactElement } from 'react'

import CurrencyFormatter from './CurrencyFormatter'
import DateFormatter from './DateFormatter'
import InlineCurrencyFormatter from './InlineCurrencyFormatter'
import PercentFormatter from './PercentFormatter'
import RatioFormatter from './RatioFormatter'
import TimeFormatter from './TimeFormatter'

type Props =
  | ComponentProps<typeof DateFormatter>
  | ComponentProps<typeof TimeFormatter>
  | ComponentProps<typeof InlineCurrencyFormatter>
  | ComponentProps<typeof CurrencyFormatter>
  | ComponentProps<typeof RatioFormatter>
  | ComponentProps<typeof PercentFormatter>

function ValueFormatterFacade(props: Props): ReactElement {
  switch (props.kind) {
    case DateFormatter.ValueKind.date:
    case DateFormatter.ValueKind.dateDaysLeft:
    case DateFormatter.ValueKind.dateRange:
    case DateFormatter.ValueKind.dateRangeDaysLeft:
    case DateFormatter.ValueKind.dateInline:
    case DateFormatter.ValueKind.dateInlineRange:
      return <DateFormatter {...props} />
    case TimeFormatter.ValueKind.timeInline:
    case TimeFormatter.ValueKind.timeInlineWithSeconds:
    case TimeFormatter.ValueKind.timeInlineRatio:
    case TimeFormatter.ValueKind.timeRatioInfo:
    case TimeFormatter.ValueKind.timeRatioInfoWithSeconds:
      return <TimeFormatter {...props} />
    case InlineCurrencyFormatter.ValueKind.currencyInline:
    case InlineCurrencyFormatter.ValueKind.currencyInlineFloat:
    case InlineCurrencyFormatter.ValueKind.currencyInlinePercent:
    case InlineCurrencyFormatter.ValueKind.currencyInlinePercentFloat:
      return <InlineCurrencyFormatter {...props} />
    case CurrencyFormatter.ValueKind.currencyRatioInfo:
    case CurrencyFormatter.ValueKind.currencyRatioInfoPercent:
    case CurrencyFormatter.ValueKind.currencyRatioInfoFloat:
    case CurrencyFormatter.ValueKind.currencyRatioInfoPercentFloat:
      return <CurrencyFormatter {...props} />
    case RatioFormatter.ValueKind.ratioInfo:
      return <RatioFormatter {...props} />
    case PercentFormatter.ValueKind.percentInline:
      return <PercentFormatter {...props} />
    default:
      return null
  }
}

export default Object.assign(ValueFormatterFacade, {
  ValueKind: {
    ...DateFormatter.ValueKind,
    ...InlineCurrencyFormatter.ValueKind,
    ...CurrencyFormatter.ValueKind,
    ...TimeFormatter.ValueKind,
    ...RatioFormatter.ValueKind,
    ...PercentFormatter.ValueKind,
  },
})
