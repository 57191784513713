// @ts-strict-ignore
import React, { ReactElement } from 'react'

import { SpaceProps } from 'styled-system'

import numeral from 'numeral'

import isNil from 'lodash/isNil'
import isNumber from 'lodash/isNumber'
import isObject from 'lodash/isObject'

import { HIDE_NUMBERS_STRING } from 'Constants/ids'

enum ValueKind {
  percentInline = 'percentInline',
}

type RatioValue = {
  current: number
  total?: number
}

type Props = SpaceProps & {
  alwaysHidden?: boolean
  alwaysVisible?: boolean
  kind: ValueKind
  value: RatioValue | number
  alwaysWithSign?: boolean
}

const PERCENT_FORMAT = '0%'
const PERCENT_FORMAT_WITHSIGN = '+0%'

function formatPercent(value: number, alwaysWithSign = false) {
  if (alwaysWithSign) {
    return numeral(value).format(PERCENT_FORMAT_WITHSIGN)
  }
  return numeral(value).format(PERCENT_FORMAT)
}

function PercentFormatter({
  alwaysHidden,
  alwaysVisible,
  alwaysWithSign,
  kind,
  value,
}: Props): ReactElement {
  const hideNumbers = !alwaysVisible || alwaysHidden

  if (isNil(value)) return null

  if (hideNumbers) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{HIDE_NUMBERS_STRING}</>
  }

  let percentValue = 0
  if (isObject(value) && value.total) {
    percentValue = value.current / value.total || 0
  } else if (isNumber(value)) {
    percentValue = value || 0
  }

  switch (kind) {
    case ValueKind.percentInline:
      return <>{formatPercent(percentValue, alwaysWithSign)}</>

    default:
      return null
  }
}

export default Object.assign(PercentFormatter, { ValueKind })
