import merge from 'lodash/merge'

import DefaultTheme from '../Default'
import palette from '../palette'

const colors = {
  bg: '#000000',
  primary: '#ffffff',

  background: {
    screen: palette.neutral100,
    block: palette.neutral200,
    subblock: palette.neutral300,
  },
  divider: {
    default: palette.neutral300,
    darker: palette.neutral300,
    light: palette.neutral400,
    lighter: palette.neutral200,
    contrast: palette.neutral500,
  },
  text: {
    body: palette.neutral600,
    heading: palette.neutral900,
    important: palette.neutral700,
    muted: palette.neutral500,
  },
  gradient: {
    animated: {
      default: `linear-gradient(to right, ${palette.neutral400} 8%, ${palette.neutral300} 18%, ${palette.neutral400} 33%)`,
      invert: `linear-gradient(to right, ${palette.neutral300} 8%, ${palette.neutral400} 18%, ${palette.neutral300} 33%)`,
    },
  },
  slantedLinesBg: {
    bg: palette.neutral300,
    line: palette.neutral400,
  },
  scrollbar: {
    track: palette.neutral300,
    thumb: palette.neutral400,
  },
}

export default merge({}, DefaultTheme, {
  colors, // NOTE: support for built-in styled-system styling functions
  v2: {
    name: 'dark',
    colors,
    shadow: {
      large: '0 1px 64px -2px rgba(0, 0, 0, 0.4);',
      medium: '0 1px 32px -2px rgba(0, 0, 0, 0.4);',
      small: '0 1px 16px -2px rgba(0, 0, 0, 0.4)',
      down: {
        large: '0 16px 64px -3px rgba(0, 0, 0, 0.4)',
        medium: '0 12px 32px -2px rgba(0, 0, 0, 0.4)',
        small: '0 8px 14px -4px rgba(0, 0, 0, 0.4)',
      },
      up: {
        large: ' 0 -8px 64px -3px rgba(0, 0, 0, 0.4)',
        medium: '0 -8px 32px -1px rgba(0, 0, 0, 0.4)',
        small: '0 -4px 16px -2px rgba(0, 0, 0, 0.4)',
      },
    },
  },
})
