import { ApolloClient } from '@apollo/client'
import { StoreonStore } from 'storeon'

export enum SharedService {
  API = 'api',
  ApolloClient = 'apolloClient',
  Store = 'store',
}

type Client = ApolloClient<any>
type Services = {
  [SharedService.API]?: any
  [SharedService.ApolloClient]?: Client
  [SharedService.Store]?: StoreonStore
}

const services = {} as Services

function checkHasValue<T = any>(serviceName: SharedService) {
  if (!services[serviceName])
    throw new Error(`${serviceName} not found in Shared`)

  return services[serviceName] as T
}

// Setters
function setApi(api: any) {
  services[SharedService.API] = api
}

function setApolloClient(client: Client) {
  services[SharedService.ApolloClient] = client
}

function setStore(store: StoreonStore) {
  services[SharedService.Store] = store
}

// Getters
function apiCall(options: any) {
  return checkHasValue(SharedService.API)?.(options)
}

function getApolloClient() {
  return checkHasValue<Client>(SharedService.ApolloClient)
}

function getStore() {
  return checkHasValue<StoreonStore>(SharedService.Store)
}

export default {
  setApi,
  setApolloClient,
  setStore,
  apiCall,
  getApolloClient,
  getStore,
}
