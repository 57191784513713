import styled, { css } from 'styled-components'
import { space, SpaceProps, styleFn } from 'styled-system'

import { CaretDown } from 'phosphor-react'

import { ComponentVariants, createGetParam, themeGet } from 'Theme/v2'

export type ContainerProps = SizeProps & SpaceProps
type ContainerVariants = ComponentVariants<ContainerVariantProps>
export type ContainerVariantProps = {
  large?: boolean
  small?: boolean
}
type ContainerParams = {
  fontSize: ContainerVariants
}

type ChevronProps = {
  open?: boolean
}

const containerParams: ContainerParams = {
  fontSize: {
    large: themeGet('fontSizes.2'),
    default: themeGet('fontSizes.1'),
    small: themeGet('fontSizes.0'),
  },
}
const getContainerParam = createGetParam<
  ContainerParams,
  ContainerVariantProps
>(containerParams)

const chevronOpenCss: styleFn = ({ open }: ChevronProps) =>
  open &&
  css`
    transform: rotate(-180deg);
  `

export const Chevron = styled(CaretDown).attrs({
  weight: 'bold',
})<ChevronProps>`
  transition: transform ${themeGet('defaultTransitionTime')};

  ${chevronOpenCss}
`

export const Content = styled.div<SpaceProps>`
  padding-top: ${themeGet('space.2')}px;
  color: ${themeGet('colors.text.body')};
  ${space}
`

export interface SizeProps {
  large?: boolean
  small?: boolean
}

export const Container = styled.div<ContainerProps>`
  font-size: ${getContainerParam('fontSize')}px;
  font-weight: 400;

  ${space}
`
