// @ts-strict-ignore
import React, { useCallback, useMemo, useState } from 'react'

import {
  downloadAppStoreGlyph,
  downloadGooglePlayGlyph,
  facebookNegativeGlyph,
  instagramNegativeGlyph,
  linkedInNegativeGlyph,
  twitterNegativeGlyph,
} from 'Assets/Svg/Landing'

import {
  ApplicationLogo,
  ApplicationLogoKind,
  ContactSalesForm,
} from 'Components/Blocks/_v2'
import { Column, Icon, Modal, NavLink, Row, Text } from 'Components/UI/_v2'

import {
  ADDRESS_MAIN_OFFICE,
  APP_LINKS,
  APP_NAME,
  COPYRIGHTS_YEAR,
  EMAILS,
  SOCIAL_LINKS,
} from 'Constants/ids'
import {
  AFFILIATE,
  API,
  CLOUD_TERMS_OF_SERVICE,
  HELP_CENTER,
  HOME,
  IMPRESSUM,
  LOGIN,
  PRIVACY_POLICY,
  REQUEST_DEMO,
  ROOT,
  SUBMIT_REFERRAL,
} from 'Constants/paths'

import { _, useScopedI18n } from 'Services/I18n'

import {
  Container,
  ContentBottom,
  ContentTop,
  ContentWrapper,
  CustomLink,
  Delimiter,
  DownloadLinkHolder,
  DownloadLinksContainer,
  NavMenu,
  StyledNavButton,
  StyledNavLink,
  ViewMobile,
  ViewTablet,
} from './styles'

function Footer() {
  const s = useScopedI18n('home.footer')

  const [isContactSalesOpen, setIsContactSalesOpen] = useState(false)

  const handleContactSales = useCallback(() => {
    setIsContactSalesOpen(!isContactSalesOpen)
  }, [isContactSalesOpen])

  const navItems = useMemo(
    () => [
      {
        name: 'product',
        topics: [
          {
            name: 'api',
            to: `${API}`,
          },
          {
            name: 'features',
            to: `${HOME}`,
          },
          {
            name: 'helpCenter',
            to: HELP_CENTER,
          },
          {
            name: 'requestDemo',
            to: REQUEST_DEMO,
            blank: true,
          },
          {
            name: 'signIn',
            to: LOGIN,
            blank: true,
          },
        ],
      },
      {
        name: 'company',
        topics: [
          { name: 'contact', handleClick: handleContactSales },
          { name: 'becomePartner', to: AFFILIATE },
          { name: 'submitReferral', to: SUBMIT_REFERRAL },
        ],
      },
      {
        name: 'support',
        topics: [
          { name: 'termsOfServices', to: CLOUD_TERMS_OF_SERVICE, blank: true },
          { name: 'privacyPolicy', to: PRIVACY_POLICY, blank: true },
          { name: 'impressum', to: IMPRESSUM, blank: true },
        ],
      },
    ],
    [handleContactSales],
  )

  return (
    <Container>
      <ContentWrapper>
        <ContentTop>
          <Column gap={6} mb={[11, 13, 13, 0]}>
            <Text header2 heading maxWidth={428}>
              {s('title')}
            </Text>
            <Row center gap={7}>
              <CustomLink href={EMAILS.INFO.LINK}>
                <Text action1 primary>
                  {EMAILS.INFO.CAPTION}
                </Text>
              </CustomLink>

              <Delimiter />

              <CustomLink href={SOCIAL_LINKS.facebook} target="_blank">
                <Icon
                  alt="Facebook logo"
                  glyph={facebookNegativeGlyph}
                  size={18}
                />
              </CustomLink>
              <CustomLink href={SOCIAL_LINKS.linkedin} target="_blank">
                <Icon
                  alt="LinkedIn logo"
                  glyph={linkedInNegativeGlyph}
                  size={18}
                />
              </CustomLink>
              <CustomLink href={SOCIAL_LINKS.twitter} target="_blank">
                <Icon
                  alt="Twitter logo"
                  glyph={twitterNegativeGlyph}
                  size={18}
                />
              </CustomLink>
              <CustomLink href={SOCIAL_LINKS.instagram} target="_blank">
                <Icon
                  alt="Instagram logo"
                  glyph={instagramNegativeGlyph}
                  size={18}
                />
              </CustomLink>
            </Row>
          </Column>

          <Column gap={[3, 3, 3, 5]} mb={[11, 13, 13, 0]}>
            <Text heading subheader1>
              {s('ourOffice')}
            </Text>
            <Text body body4 width={[1, 1, 1, 178]}>
              {ADDRESS_MAIN_OFFICE}
            </Text>
          </Column>

          <DownloadLinksContainer>
            <DownloadLinkHolder href={APP_LINKS.appstore} target="_blank">
              <Icon alt="Download in AppStore" glyph={downloadAppStoreGlyph} />
            </DownloadLinkHolder>

            <DownloadLinkHolder href={APP_LINKS.googleplay} target="_blank">
              <Icon
                alt="Download in GooglePlay"
                glyph={downloadGooglePlayGlyph}
              />
            </DownloadLinkHolder>
          </DownloadLinksContainer>
        </ContentTop>
      </ContentWrapper>

      <ContentWrapper>
        <ContentBottom>
          <Column spaceBetween>
            <NavLink href={ROOT} to={ROOT}>
              <Row center>
                <ApplicationLogo
                  height={34}
                  kind={ApplicationLogoKind.WideColor}
                  width={135}
                />
              </Row>
            </NavLink>

            <ViewTablet>
              <Text caption1 muted nowrap>
                © {COPYRIGHTS_YEAR} {APP_NAME}
              </Text>
            </ViewTablet>
          </Column>

          <NavMenu>
            {navItems.map(item => (
              <Column key={item.name} mb={[11, 11, 0]} mr={[0, 0, 56, 88]}>
                <Text heading mb={4} nowrap subheader1>
                  {s(item.name)}
                </Text>

                <Column gap={4}>
                  {item.topics.map(topic =>
                    !topic.to ? (
                      <StyledNavButton
                        key={topic.name}
                        onClick={topic.handleClick}
                      >
                        {s(topic.name)}
                      </StyledNavButton>
                    ) : (
                      <StyledNavLink
                        key={topic.name}
                        target={topic.blank ? '_blank' : undefined}
                        to={topic.to}
                      >
                        {s(topic.name)}
                      </StyledNavLink>
                    ),
                  )}
                </Column>
              </Column>
            ))}
          </NavMenu>

          <ViewMobile>
            <Text caption1 muted nowrap>
              © {COPYRIGHTS_YEAR} {APP_NAME}
            </Text>
          </ViewMobile>
        </ContentBottom>
      </ContentWrapper>

      <Modal
        isOpen={isContactSalesOpen}
        title={_('blocks.modals.contactSales.title')}
        onClose={handleContactSales}
      >
        <ContactSalesForm onCancel={handleContactSales} />
      </Modal>
    </Container>
  )
}

export default Footer
