import styled, { css } from 'styled-components'
import { margin, MarginProps, styleFn } from 'styled-system'

import { themeGet } from 'Theme/v2'

type SizeProps = {
  small?: boolean
  large?: boolean
}

export type ContainerProps = SizeProps & MarginProps

type PageButtonProps = SizeProps & {
  active?: boolean
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${themeGet('space.5')}px;
  color: ${themeGet('colors.primary500')};

  ${margin}
`

const buttonCss = css`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  outline: none;
  padding: 0;
  cursor: pointer;
  user-select: none;

  &:disabled {
    cursor: default;
    color: ${themeGet('colors.text.muted')};
  }
`

export const NavButton = styled.button`
  gap: ${themeGet('space.3')}px;
  color: ${themeGet('colors.primary500')};

  ${buttonCss}
`

const pageButtonLargeCss: styleFn = ({ large }: SizeProps) =>
  large &&
  css`
    width: 40px;
    height: 40px;
  `

const pageButtonSmallCss: styleFn = ({ small }: SizeProps) =>
  small &&
  css`
    width: 32px;
    height: 32px;
  `

const pageButtonActiveCss: styleFn = ({ active }: PageButtonProps) =>
  active &&
  css`
    color: ${themeGet('colors.text.inverse')};
    background-color: ${themeGet('colors.primary500')};
    cursor: default;

    &:hover {
      color: ${themeGet('colors.text.inverse')};
    }
  `

export const PageButton = styled.div<PageButtonProps>`
  width: 36px;
  height: 36px;
  border-radius: 6px;
  color: ${themeGet('colors.text.heading')};

  &:hover {
    color: ${themeGet('colors.primary500')};
  }

  ${buttonCss}

  ${pageButtonLargeCss}
  ${pageButtonSmallCss}
  ${pageButtonActiveCss}
`
