import {
  Location,
  Plan,
  PLAN_MONTHLY_PAYMENT,
  PlansPrice,
} from 'Constants/billing'
import { OrganizationSubscriptionPeriod } from 'Constants/mainGraphQL'

type GetBillingPriceByLocationParams = {
  location: Location
}

function getBillingPriceByLocation({
  location,
}: GetBillingPriceByLocationParams): PlansPrice {
  return {
    [Plan.Basic]: {
      [OrganizationSubscriptionPeriod.Annually]:
        PLAN_MONTHLY_PAYMENT[location].BASIC_ANNUALLY,
      [OrganizationSubscriptionPeriod.Monthly]:
        PLAN_MONTHLY_PAYMENT[location].BASIC_MONTHLY,
    },
    [Plan.Pro]: {
      [OrganizationSubscriptionPeriod.Annually]:
        PLAN_MONTHLY_PAYMENT[location].PRO_ANNUALLY,
      [OrganizationSubscriptionPeriod.Monthly]:
        PLAN_MONTHLY_PAYMENT[location].PRO_MONTHLY,
    },
    [Plan.Enterprise]: null,
  }
}

export default {
  getBillingPriceByLocation,
}
