// @ts-strict-ignore
import { DateTime } from 'luxon'

export const BREAKPOINTS = {
  MOBILE: 'mobile',
  TABLET: 'tablet',
  DESKTOP: 'desktop',
  LARGE: 'large',
}

export const APP_LINKS = {
  googleplay: 'https://play.google.com/store/apps/details?id=io.worklair',
  appstore: 'https://apps.apple.com/ru/app/synchronos/id1661528672',
  emajinGoogleplay: 'https://play.google.com/store/apps/details?id=golf.emajin',
  emajinAppstore: 'https://apps.apple.com/au/app/emajin-golf/id6596770826',
}

export const LOCAL_STORAGE = {
  REDIRECT_URL: 'Worklair.redirectUrl',
  REFERRAL_DATA: 'Worklair.linkMinkReferralData',
  REPORTS_SUM_RANGES: 'Worklair.reportsSumRanges',
  REPORTS_PROJECTS: 'Worklair.reportsProjects',
  REPORTS_FILTERS: 'Worklair.reportsFilters',
  REPORTS_DATES: 'Worklair.reportsDates',
  REPORTS_GROUP_BY: 'Worklair.reportsGroupBy',
  DOWNLOADED_APP_VERSION: 'Worklair.desktop',
  STORE: 'Worklair.store',
  RECENT_EMOJI: 'RecentEmoji',
  CHAT_HIDDEN_CHANNELS: 'Worklair.chatHiddenChannels',
  CHAT_VIEW_SETTINGS: 'Worklair.chatViewSettings',
}

export const COOKIE_KEYS = {
  PERSIST: 'persist',
  UI: 'ui',
  COOKIE_ACCEPTED: 'cookie-accepted',
  I18N: 'i18n',
}

export const LANGUAGE = {
  EN: 'en',
  UK: 'uk',
}
export const SOCIAL_LINKS = {
  facebook: 'https://www.facebook.com/worklair',
  linkedin: 'https://www.linkedin.com/company/worklair',
  twitter: 'https://twitter.com/worklair',
  instagram: 'https://www.instagram.com/worklair.io/',
}

export const ADDRESS_MAIN_OFFICE =
  'Suite 302, 13/15 Wentworth Ave, Sydney NSW 2000'

export const EMAILS = {
  HELLO: { LINK: 'mailto:hello@worklair.io', CAPTION: 'hello@worklair.io' },
  INFO: { LINK: 'mailto:info@worklair.io', CAPTION: 'info@worklair.io' },
  HELP: { LINK: 'mailto:hello@worklair.io', CAPTION: 'hello@worklair.io' },
}

export const PHONES = {
  CALLBACK: {
    LINK: 'tel:+61412428803',
    CAPTION: '+61 412 428 803',
    COUNTRY: 'AU',
  },
}

export const EXTERNAL_LINKS = {
  CALENDLY: {
    WIDGET: 'https://assets.calendly.com/assets/external/widget.js',
    URL: 'https://calendly.com/worklair/25min/?hide_landing_page_details=1&hide_gdpr_banner=1',
  },
  LEARN_ABOUT_2FA: 'https://en.wikipedia.org/wiki/Multi-factor_authentication',
  INTRO_VIDEO_URL: 'https://www.youtube.com/watch?v=Yj7ncFmV8Uc&',
  G2: 'https://www.g2.com/products/worklair/reviews',
  CAPTERRA: 'https://www.capterra.com.au/software/1041854/worklair',
  HOW_WORKLAIR_WORKS_VIDEO: 'https://www.youtube.com/watch?v=Yj7ncFmV8Uc&t=2s',
}

export const HIDE_NUMBERS_STRING = '***'

export const APP_NAME = 'Worklair'

export const DATOCMS_ENDPOINT = {
  PREVIEW: `https://graphql.datocms.com/preview`,
  MAIN: `https://graphql.datocms.com/`,
}

export const KEYBOARD_KEYS = {
  ENTER: 'Enter',
  NUMPAD_ENTER: 'NumpadEnter',
  ESCAPE: 'Escape',
  BACKSPACE: 'Backspace',
}

export enum Breakpoints {
  ExtraSmall = 480,
  Small = 640,
  Medium = 768,
  Large = 1200,
  ExtraLarge = 1320,
}

export const COPYRIGHTS_YEAR = DateTime.now().toFormat('yyyy')
