// @ts-strict-ignore
function format(value) {
  return `${Math.round(value * 100)}%`
}

function formatWithSign(value) {
  return `${value > 0 ? '+' : ''}${format(value)}`
}

function calculate(totalValue, partialValue) {
  return (100 * partialValue) / totalValue
}

export default { format, formatWithSign, calculate }
