import { useEffect } from 'react'

import { REWARDFUL } from 'Config/app'

import { useScript } from 'Hooks'

const script = {
  type: 'text/javascript',
  innerHTML:
    "(function(w,r){w._rwq=r;w[r]=w[r]||function(){(w[r].q=w[r].q||[]).push(arguments)}})(window,'rewardful')",
}

export function useRewardful() {
  // @ts-ignore
  return { rewardful: window?.Rewardful, Rewardful: window?.Rewardful }
}

export function useInitializeRewardful() {
  const [loaded] = useScript('https://r.wdfl.co/rw.js', {
    'data-rewardful': REWARDFUL.API_KEY,
  })

  useEffect(() => {
    // @ts-ignore
    if (!loaded || !window.rewardful) return

    // @ts-ignore
    window.rewardful('ready', () => {
      // eslint-disable-next-line no-console
      // console.log('Rewardful ready')
    })
  }, [loaded])

  return { script: loaded ? script : null, loaded }
}
