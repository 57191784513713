// @ts-strict-ignore
import React from 'react'
import { renderRoutes } from 'react-router-config'

import Header from './Header'
import { Container, Content } from './styles'

function LegalLayout({ route }) {
  return (
    <Container>
      <Header />
      <Content>{renderRoutes(route.routes)}</Content>
    </Container>
  )
}

export default LegalLayout
