import { NavLink } from 'react-router-dom'

import styled from 'styled-components'
import { padding } from 'styled-system'

import { themeGet } from 'Theme/v2'

export const Container = styled.div.attrs({
  px: [4, 6, 7, 9],
})`
  display: flex;
  min-height: 64px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  align-self: flex-start;

  ${padding}
`

export const StyledLink = styled(NavLink)`
  color: ${themeGet('colors.text.heading')};
  font-weight: 600;
  text-decoration: none;
  white-space: nowrap;
`
