// @ts-strict-ignore
import { useCallback, useEffect, useRef } from 'react'

export default function useAbortController(options) {
  const abortControllerRef = useRef<AbortController>()

  const getAbortController = useCallback(() => {
    if (!abortControllerRef.current) {
      abortControllerRef.current = new AbortController()
    }
    return abortControllerRef.current
  }, [])

  useEffect(() => {
    return () => getAbortController().abort()
  }, [getAbortController, options])

  return useCallback(() => getAbortController().signal, [getAbortController])
}
