import styled from 'styled-components'
import { layout, LayoutProps } from 'styled-system'

import { themeGet } from 'Theme/v2'

export const StyledForm = styled.form.attrs({
  width: [340, 380, 460],
})<LayoutProps>`
  display: flex;
  flex-direction: column;

  ${layout}
`

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${themeGet('colors.divider.default')};
`
