export const emailConstraint = {
  email: {
    presence: true,
    email: {
      message: 'should be correct',
    },
  },
}

export const passwordConstraint = {
  password: {
    presence: true,
    length: {
      minimum: 9,
      maximum: 100,
      message: 'must be at least 9 characters',
    },
  },
}
