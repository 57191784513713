import React, { useEffect } from 'react'
import { useLocation } from 'react-router'

import styled from 'styled-components'

import Loader from 'Components/UI/_v2/SpinLoader'

import { FRONTEND_URL } from 'Config/app'

import { LOGIN } from 'Constants/paths'

import { useAppContext } from 'Hooks'

import { themeGet } from 'Theme/v2'

export const Wrapper = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeGet('space.4')}px;
  background-color: ${themeGet('colors.background.block')};
  border-radius: 6px;
  box-shadow: ${themeGet('shadow.medium')};
  padding: ${themeGet('space.4')}px;
  width: 280px;
  min-height: 160px;
  justify-content: center;
  align-items: center;
`

export default function AppRedirect() {
  const location = useLocation()

  const { me } = useAppContext()

  useEffect(() => {
    const redirect = `${FRONTEND_URL}${location.pathname?.replace('/app', '')}${
      me ? location.search : encodeURIComponent(location.search)
    }`
    window.location.href = me ? redirect : `${LOGIN}?backTo=${redirect}`
  }, [location, me])

  return (
    <Wrapper>
      <Content>
        <Loader />
        Redirect...
      </Content>
    </Wrapper>
  )
}
