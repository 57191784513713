import { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

export default css`
  .toast-container_v2 {
    min-width: 420px;

    .Toastify__toast {
      padding: 0;
      background: transparent;
      border-radius: 10px;
      min-height: unset;
      font-family: ${themeGet('v2.font')};
      box-shadow: ${themeGet('v2.shadow.down.large')};

      .Toastify__toast-icon {
        display: none;
      }

      .Toastify__toast-body {
        padding: 0;
      }

      .Toastify__progress-bar {
        height: 4px;
        background: ${themeGet('v2.colors.primary500')};
      }

      &.Toastify__toast--error {
        border-color: unset;
        background-color: transparent;

        .Toastify__progress-bar {
          background: ${themeGet('v2.colors.danger500')};
        }
      }

      &.Toastify__toast--success {
        border-color: unset;
        background-color: transparent;

        .Toastify__progress-bar {
          background: ${themeGet('v2.colors.success400')};
        }
      }

      &.Toastify__toast--warning {
        border-color: unset;
        background-color: transparent;

        .Toastify__progress-bar {
          background: ${themeGet('v2.colors.warning500')};
        }
      }

      &.Toastify__toast--info {
        border-color: unset;
        background-color: transparent;

        .Toastify__progress-bar {
          background: ${themeGet('v2.colors.info500')};
        }
      }

      &.Toastify__toast--default {
        border-color: unset;
        background-color: transparent;

        .Toastify__progress-bar {
          background: ${themeGet('v2.colors.primary500')};
        }
      }
    }
  }
`
