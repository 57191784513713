import flatMapDeep from 'lodash/flatMapDeep'
import get from 'lodash/get'
import values from 'lodash/values'

const getGraphQLErrors = error => {
  if (error.message) return [error.message.replace('GraphQL error:', '').trim()]
  return flatMapDeep(get(error, 'graphQLErrors'), ({ state }) => values(state))
}

export default { getGraphQLErrors }
