import {
  logoAsanaGlyph,
  logoChantyGlyph,
  logoClickUpGlyph,
  logoHarvestGlyph,
  logoHubstaffGlyph,
  logoJiraGlyph,
  logoMicrosoftTeamsGlyph,
  logoMondayGlyph,
  logoRocketChatGlyph,
  logoSlackGlyph,
  logoTimeDoctorGlyph,
  logoTogglGlyph,
  logoTrelloGlyph,
} from 'Assets/Svg/Billing'

import { OrganizationSubscriptionPeriod } from './mainGraphQL'

export enum Plan {
  Basic = 'basic',
  Pro = 'professional',
  Enterprise = 'enterprise',
}

export enum Location {
  Default = 'default',
  Au = 'AU',
}

// 10USD/15AUD vs 6.58USD/10AUD

export const PLAN_MONTHLY_PAYMENT = {
  [Location.Default]: {
    BASIC_ANNUALLY: 520,
    BASIC_MONTHLY: 781,
    PRO_ANNUALLY: 658,
    PRO_MONTHLY: 1000,
  },
  [Location.Au]: {
    BASIC_ANNUALLY: 800,
    BASIC_MONTHLY: 1200,
    PRO_ANNUALLY: 1000,
    PRO_MONTHLY: 1500,
  },
}

export type PlanPrice = {
  [OrganizationSubscriptionPeriod.Annually]: number
  [OrganizationSubscriptionPeriod.Monthly]: number
}

export type PlansPrice = {
  [Plan.Basic]: PlanPrice
  [Plan.Pro]: PlanPrice
  [Plan.Enterprise]: PlanPrice | null
}

export type PlanData = {
  id: Plan
  name: string
  description: string
  price?: {
    [OrganizationSubscriptionPeriod.Annually]: number
    [OrganizationSubscriptionPeriod.Monthly]: number
  }
  keyFeatures?: string[]
  contactSales?: boolean
}

type PlansData = {
  // [Plan.Basic]: PlanData
  [Plan.Pro]: PlanData
  [Plan.Enterprise]: PlanData
}

export type PlansGeneralFeature = {
  name: string
  row: Array<string | boolean>
}

export type PlanAdditionFeaturesGroup = {
  groupTitle: string
  groupData: PlansGeneralFeature[]
}

export const PLANS_DATA: PlansData = {
  // [Plan.Basic]: {
  //   id: Plan.Basic,
  //   price: {
  //     [OrganizationSubscriptionPeriod.Annually]:
  //       PLAN_MONTHLY_PAYMENT[Location.Default].BASIC_ANNUALLY,
  //     [OrganizationSubscriptionPeriod.Monthly]:
  //       PLAN_MONTHLY_PAYMENT[Location.Default].BASIC_MONTHLY,
  //   },
  //   name: 'Basic',
  //   description: 'Best choice for mid-size teams',
  // },
  [Plan.Pro]: {
    id: Plan.Pro,
    price: {
      [OrganizationSubscriptionPeriod.Annually]:
        PLAN_MONTHLY_PAYMENT[Location.Default].PRO_ANNUALLY,
      [OrganizationSubscriptionPeriod.Monthly]:
        PLAN_MONTHLY_PAYMENT[Location.Default].PRO_MONTHLY,
    },
    name: 'Pro',
    description: 'Best for multiple teams',
    keyFeatures: [
      'Unlimited Team Members',
      'Unlimited Chat history',
      'Unlimited Projects',
      'Unlimited Task Boards',
      'Unlimited Cloud Space',
    ],
  },
  [Plan.Enterprise]: {
    id: Plan.Enterprise,
    name: 'Enterprise',
    description:
      'White Labeling,\nLive Onboarding Training,\nDedicated Success Manager, Access to Managed Services\nand much' +
      ' more',
    contactSales: true,
  },
}

const UNLIMITED = 'Unlimited'

export const PLANS_GENERAL_FEATURES: PlansGeneralFeature[] = [
  {
    name: 'Personal Team Members',
    row: [UNLIMITED, UNLIMITED, UNLIMITED],
  },
  {
    name: 'Client Team Members',
    row: [
      `${UNLIMITED} (Read only)`,
      `${UNLIMITED} (Editor)`,
      `${UNLIMITED} (Editor)`,
    ],
  },
  {
    name: 'Cloud Space',
    row: ['5 GB', UNLIMITED, UNLIMITED],
  },
  {
    name: 'Chat history messages',
    row: [UNLIMITED, UNLIMITED, UNLIMITED],
  },
  {
    name: 'Attachment max size',
    row: ['50 MB', UNLIMITED, UNLIMITED],
  },
  {
    name: 'Projects',
    row: [UNLIMITED, UNLIMITED, UNLIMITED],
  },
  {
    name: 'Task Boards',
    row: [UNLIMITED, UNLIMITED, UNLIMITED],
  },
  {
    name: 'Activity Log',
    row: ['3 months', UNLIMITED, UNLIMITED],
  },
  {
    name: 'GitHub integrations',
    row: [false, true, true],
  },
  {
    name: 'Roadmap',
    row: [true, true, true],
  },
  {
    name: 'Custom Dashboards',
    row: [false, true, true],
  },
]

export const PLANS_ADDITIONAL_FEATURES: PlanAdditionFeaturesGroup[] = [
  {
    groupTitle: 'Planning',
    groupData: [
      {
        name: 'Gannt Chart',
        row: [true, true, true],
      },
      {
        name: 'Burndown Chart',
        row: [true, true, true],
      },
      {
        name: 'Tasks Progress',
        row: [true, true, true],
      },
      {
        name: 'Performance Dashboard',
        row: [false, true, true],
      },
    ],
  },
  {
    groupTitle: 'Budgeting & Finances',
    groupData: [
      {
        name: 'Estimations',
        row: [false, true, true],
      },
      {
        name: 'Time tracking',
        row: [false, true, true],
      },
      {
        name: 'Invoices',
        row: [false, true, true],
      },
      {
        name: 'Expenses',
        row: [false, true, true],
      },
      {
        name: 'Reports',
        row: [false, true, true],
      },
      {
        name: 'Workload',
        row: [false, true, true],
      },
      {
        name: 'Timeline',
        row: [false, true, true],
      },
    ],
  },
  {
    groupTitle: 'App',
    groupData: [
      {
        name: 'Desktop app',
        row: [true, true, true],
      },
      {
        name: 'Desktop Tracker',
        row: [false, true, true],
      },
    ],
  },
  {
    groupTitle: 'Teams',
    groupData: [
      {
        name: 'Allocations',
        row: [true, true, true],
      },
      {
        name: 'Rates',
        row: [false, true, true],
      },
      {
        name: 'Reports',
        row: [false, true, true],
      },
      {
        name: 'Custom permissions',
        row: [false, true, true],
      },
    ],
  },
]

export const VERSUS_PRODUCTS = [
  {
    id: 'PROJECT_MANAGEMENT',
    data: [
      { name: 'ClickUp', price: 1900, logo: logoClickUpGlyph },
      { name: 'Asana', price: 2499, logo: logoAsanaGlyph },
      { name: 'Trello', price: 1000, logo: logoTrelloGlyph },
      { name: 'JIRA', price: 1200, logo: logoJiraGlyph },
      { name: 'Monday', price: 1600, logo: logoMondayGlyph },
    ],
  },
  {
    id: 'TIME_TRACKING',
    data: [
      { name: 'Hubstaff', price: 833, logo: logoHubstaffGlyph },
      { name: 'TimeDoctor', price: 833, logo: logoTimeDoctorGlyph },
      { name: 'Toggl', price: 1800, logo: logoTogglGlyph },
      { name: 'Harvest', price: 1080, logo: logoHarvestGlyph },
    ],
  },
  {
    id: 'CHAT',
    data: [
      { name: 'Slack', price: 667, logo: logoSlackGlyph },
      { name: 'Microsoft Teams', price: 500, logo: logoMicrosoftTeamsGlyph },
      { name: 'RocketChat', price: 300, logo: logoRocketChatGlyph },
      { name: 'Chanty', price: 300, logo: logoChantyGlyph },
    ],
  },
]

export const USERS_COUNT_RANGE = { min: 1, max: 50 }

export const DEFAULT_SEATS = 5
export const MIN_SEATS = 1
export const TRIAL_DAYS = 14
