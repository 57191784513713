import styled, { css } from 'styled-components'
import { space, SpaceProps, styleFn } from 'styled-system'

import { createGetParam, themeGet } from 'Theme/v2'

const BORDER_SIZE = 3

const sizes = {
  size: {
    megaLarge: 80,
    extraLarge: 48,
    superLarge: 208,
    extremelyLarge: 230,
    large: 40,
    default: 32,
    small: 24,
  },
  fontSize: {
    megaLarge: 26,
    extraLarge: themeGet('fontSizes.2'),
    large: themeGet('fontSizes.1'),
    default: themeGet('fontSizes.0'),
    small: 10,
  },
}

export interface SizeProps {
  extremelyLarge?: boolean
  superLarge?: boolean
  extraLarge?: boolean
  megaLarge?: boolean
  large?: boolean
  small?: boolean
}

export interface VariantProps {
  bordered?: boolean
  squared?: boolean
}

const variants = {
  borderColor: {
    default: 'transparent',
    bordered: themeGet('colors.background.block'),
  },
}
export type ContainerProps = SizeProps & SpaceProps & VariantProps

const getSizeParam = createGetParam<typeof sizes, SizeProps>(sizes)
const getParam = createGetParam<typeof variants, VariantProps>(variants)

const baseBorderedCss: styleFn = ({ bordered }: VariantProps) =>
  bordered &&
  css`
    width: calc(100% + ${BORDER_SIZE}px);
    height: calc(100% + ${BORDER_SIZE}px);
    border: ${BORDER_SIZE}px solid ${getParam('borderColor')};
  `

const baseSquaredCss: styleFn = ({ squared }: VariantProps) =>
  squared &&
  css`
    border-radius: 0;
  `

export const Image = styled.img<VariantProps>`
  object-fit: cover;
  user-select: none;
  height: 100%;
  width: 100%;
  border-radius: 999px;

  ${baseBorderedCss};
  ${baseSquaredCss}
`

export const Acronym = styled.div<VariantProps>`
  display: flex;
  flex-shrink: 0;
  color: ${themeGet('colors.text.heading')};
  border-radius: 999px;
  justify-content: center;
  align-items: center;

  ${baseBorderedCss};
  ${baseSquaredCss}
`

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
  border-radius: 999px;
  background: ${themeGet('colors.divider.default')};
  height: ${getSizeParam('size')}px;
  width: ${getSizeParam('size')}px;
  font-size: ${getSizeParam('fontSize')}px;
  font-weight: ${themeGet('fontWeights.2')};
  color: ${themeGet('colors.text.body')};

  ${baseSquaredCss};

  ${space};
`
