import styled from 'styled-components'
import {
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
} from 'styled-system'

import { createGetParam, themeGet } from 'Theme/v2'

interface SizeProps {
  extraLarge?: boolean
  large?: boolean
  small?: boolean
  extraSmall?: boolean
}

interface VariantProps {
  secondary?: boolean
  tertiary?: boolean
}

const sizes = {
  circleSize: {
    extraLarge: 40,
    large: 28,
    default: 24,
    small: 20,
    extraSmall: 16,
  },
  fontSize: {
    extraLarge: themeGet('fontSizes.4'),
    large: 18,
    default: themeGet('fontSizes.2'),
    small: themeGet('fontSizes.1'),
    extraSmall: themeGet('fontSizes.0'),
  },
}

const variants = {
  background: {
    default: themeGet('colors.primary500'),
    secondary: themeGet('colors.background.subblock'),
    tertiary: themeGet('colors.text.heading'),
  },
  color: {
    default: themeGet('colors.white'),
    secondary: themeGet('colors.text.body'),
    tertiary: themeGet('colors.background.block'),
  },
  hover: {
    default: themeGet('colors.primary400'),
    secondary: themeGet('colors.divider.default'),
    tertiary: themeGet('colors.text.body'),
  },
  active: {
    default: themeGet('colors.primary300'),
    secondary: themeGet('colors.divider.contrast'),
    tertiary: themeGet('colors.text.body'),
  },
  focus: {
    default: themeGet('colors.primary700'),
    secondary: themeGet('colors.primary700'),
    tertiary: themeGet('colors.neutral600'),
  },
  disabledBackground: {
    default: themeGet('colors.primary900'),
    secondary: themeGet('colors.background.subblock'),
    tertiary: themeGet('colors.background.subblock'),
  },
  disabledColor: {
    default: themeGet('colors.primary700'),
    secondary: themeGet('colors.divider.contrast'),
    tertiary: themeGet('colors.divider.contrast'),
  },
}

interface Props
  extends SizeProps,
    VariantProps,
    FlexboxProps,
    LayoutProps,
    SpaceProps {}

const getSizeParam = createGetParam<typeof sizes, SizeProps>(sizes)
const getVariantsParam = createGetParam<typeof variants, VariantProps>(variants)

const CircleButton = styled.button.attrs(props => ({
  type: props.type || 'button',
}))<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  border-radius: 999px;
  margin: 0;
  padding: 0;
  user-select: none;
  height: ${getSizeParam('circleSize')}px;
  width: ${getSizeParam('circleSize')}px;
  font-size: ${getSizeParam('fontSize')}px;

  color: ${getVariantsParam('color')};
  background: ${getVariantsParam('background')};

  &:focus-visible {
    outline: 2px solid ${getVariantsParam('focus')};
  }

  &:enabled:hover {
    background: ${getVariantsParam('hover')};
  }

  &:enabled:active {
    background: ${getVariantsParam('active')};
  }

  &:disabled {
    cursor: default;
    color: ${getVariantsParam('disabledColor')};
    background: ${getVariantsParam('disabledBackground')};
  }

  ${flexbox}
  ${layout}
  ${space}
`

export default CircleButton
