import styled from 'styled-components'
import { layout, LayoutProps } from 'styled-system'

import { themeGet } from 'Theme/v2'

export const Content = styled.div.attrs({
  width: [370, 400, 600, 1000],
})<LayoutProps>`
  height: 720px;
  border-top: 1px solid ${themeGet('colors.divider.default')};

  ${layout}
`
