import styled from 'styled-components'
import { layout, LayoutProps, space, SpaceProps } from 'styled-system'

const STROKE_WIDTH = 5
const RADIUS = 35
const DASHARRAY = 2 * Math.PI * RADIUS

export const Container = styled.svg.attrs({
  viewBox: `0 0 ${RADIUS * 2 + STROKE_WIDTH} ${RADIUS * 2 + STROKE_WIDTH}`,
})<SpaceProps & LayoutProps>`
  ${space}
  ${layout}
`

interface ProgressRingProps {
  progress?: number
}

export const ProgressRing = styled.circle.attrs(
  ({ progress }: ProgressRingProps) => ({
    strokeDashoffset: DASHARRAY * (1 - (progress ?? 0)),
  }),
)<ProgressRingProps>`
  fill: none;
  stroke-width: ${STROKE_WIDTH}px;
  stroke-dasharray: ${DASHARRAY}px;
  r: ${RADIUS};
  cx: ${RADIUS + STROKE_WIDTH / 2};
  cy: ${RADIUS + STROKE_WIDTH / 2};
  stroke: currentColor;
  stroke-linecap: round;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
`
