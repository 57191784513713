export const CURRENCY = {
  USD: 'USD',
  RUB: 'RUB',
} as const

export const DEFAULT_MULTIPLIER = 100

export const CURRENCY_MULTIPLIERS = {}

export const CURRENCY_SIGNS = {
  [CURRENCY.USD]: '$',
  // TODO: doesn't work with numeral. probably need to register custom format
  //  http://numeraljs.com/#custom-formats
  [CURRENCY.RUB]: '$',
  // --
}

export const DEFAULT_FORMAT = '0,.00'

export const CURRENCY_FORMATS = {
  [CURRENCY.USD]: `${CURRENCY_SIGNS[CURRENCY.USD]}0,.00`,
  [CURRENCY.RUB]: `0,.00${CURRENCY_SIGNS[CURRENCY.RUB]}`,
}
