// @ts-strict-ignore
import { NavLink } from 'react-router-dom'

import styled, { css } from 'styled-components'
import {
  display,
  layout,
  margin,
  MarginProps,
  padding,
  space,
} from 'styled-system'

import {
  LANDING_MAX_CONTENT_WIDTH,
  LANDING_PAGE_PADDING_X,
} from 'Constants/landing'

import { themeGet } from 'Theme/v2'

export const Container = styled.div.attrs({
  px: LANDING_PAGE_PADDING_X,
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: fixed;
  background-color: ${themeGet('colors.background.block')}f0;
  backdrop-filter: blur(2.5px);
  z-index: 1999;

  > div {
    width: 100%;
  }

  ${padding}
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  padding-block: ${themeGet('space.5')}px;
  max-width: ${LANDING_MAX_CONTENT_WIDTH}px;
`

const navLinkActiveCss = ({ active }) =>
  active &&
  css`
    color: ${themeGet('colors.text.heading')};
    border-color: ${themeGet('colors.primary600')};
  `

export const StyledNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  color: ${themeGet('colors.text.muted')};
  font-size: ${themeGet('fontSizes.2')}px;
  font-weight: ${themeGet('fontWeights.1')};
  line-height: 150%;
  text-decoration: none;
  padding-block: ${themeGet('space.2')}px;
  cursor: pointer;
  user-select: none;
  border-bottom: 2px solid transparent;

  &:hover {
    color: ${themeGet('colors.text.heading')};
  }

  ${margin};
  ${space};

  ${navLinkActiveCss}
`

export const NavMenu = styled.div.attrs({
  display: ['none', 'none', 'none', 'flex'],
})`
  align-items: center;
  gap: ${themeGet('space.11')}px;

  @media screen and (min-width: ${themeGet('breakpoints.3')}em) {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    gap: 56px;
  }

  ${display};
`

export const ContactButton = styled.button.attrs({
  display: ['none', 'none', 'none', 'flex'],
})<MarginProps>`
  display: flex;
  align-items: center;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
  color: ${themeGet('colors.text.muted')};
  font-weight: ${themeGet('fontWeights.1')};
  font-size: ${themeGet('fontSizes.2')}px;

  &:hover {
    color: ${themeGet('colors.primary500')};
  }

  ${margin}
  ${display}
`

type HamburgerButtonProps = {
  primary: boolean
}

const hamburgerButtonPrimaryCss = ({ primary }) =>
  primary &&
  css`
    color: ${themeGet('colors.primary500')};
  `

export const HamburgerButton = styled.div.attrs({
  display: ['flex', 'flex', 'flex', 'none'],
})<MarginProps & HamburgerButtonProps>`
  cursor: pointer;
  flex-shrink: 0;

  &:hover {
    color: ${themeGet('colors.primary500')};
  }

  ${margin}
  ${display};

  ${hamburgerButtonPrimaryCss}
`

export const ViewMobile = styled.div.attrs({
  display: ['flex', 'none'],
})`
  ${layout}
`

export const ViewTablet = styled.div.attrs({
  display: ['none', 'flex'],
})`
  ${layout}
`
