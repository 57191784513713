import { NavLink as NavLinkBase } from 'react-router-dom'

import styled, { css } from 'styled-components'
import { space, SpaceProps } from 'styled-system'

import { createGetParam, themeGet } from 'Theme/v2'

const sizes = {
  gap: {
    large: 12,
    default: 10,
    small: 8,
    extraSmall: 6,
  },
  fontSize: {
    large: 18,
    default: themeGet('fontSizes.2'),
    small: themeGet('fontSizes.1'),
    extraSmall: themeGet('fontSizes.0'),
  },
  lineHeight: {
    large: 28,
    default: 24,
    small: 20,
    extraSmall: 16,
  },
}

const variants = {
  color: {
    default: themeGet('colors.primary500'),
    muted: themeGet('colors.text.muted'),
    heading: themeGet('colors.text.heading'),
    danger: themeGet('colors.danger500'),
    success: themeGet('colors.success500'),
  },
  focusedOutline: {
    default: themeGet('colors.primary700'),
    muted: themeGet('colors.primary700'),
    heading: themeGet('colors.text.body'),
    danger: themeGet('colors.danger700'),
    success: themeGet('colors.success700'),
  },
  hoverColor: {
    default: themeGet('colors.primary400'),
    muted: themeGet('colors.primary500'),
    heading: themeGet('colors.text.heading'),
    danger: themeGet('colors.danger400'),
    success: themeGet('colors.success400'),
  },
  activeColor: {
    default: themeGet('colors.primary300'),
    muted: themeGet('colors.primary300'),
    heading: themeGet('colors.text.heading'),
    danger: themeGet('colors.danger300'),
    success: themeGet('colors.success300'),
  },
}

const getSizeParam = createGetParam<typeof sizes, SizeProps>(sizes)
const getVariantsParam = createGetParam<typeof variants, VariantProps>(variants)

interface VariantProps {
  muted?: boolean
  heading?: boolean
  danger?: boolean
  success?: boolean
}

interface SizeProps {
  large?: boolean
  small?: boolean
  extraSmall?: boolean
}

interface Props extends VariantProps, SizeProps, SpaceProps {
  inline?: boolean
}

const navLinkInlineCss = ({ inline }: Props) =>
  inline &&
  css`
    display: inline;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
  `

const NavLink = styled(NavLinkBase)<Props>`
  display: flex;
  align-items: center;
  gap: ${getSizeParam('gap')}px;
  font-size: ${getSizeParam('fontSize')}px;
  font-weight: ${themeGet('fontWeights.2')};
  line-height: ${getSizeParam('lineHeight')}px;
  color: ${getVariantsParam('color')};
  text-decoration: none;

  &:hover {
    color: ${getVariantsParam('hoverColor')};
  }

  &:active {
    color: ${getVariantsParam('activeColor')};
  }

  &:focus-visible {
    outline: 2px solid ${getVariantsParam('focusedOutline')};
    border-radius: 6px;
  }

  ${navLinkInlineCss}
  ${space}
`

export default NavLink
