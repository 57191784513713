export enum AiPackage {
  Large = 'large',
  Medium = 'medium',
  Small = 'small',
}

export enum AiUsageOperation {
  Completion = 'completion',
  Transcription = 'transcription',
}

export enum AiUsageSource {
  Deepgram = 'deepgram',
  OpenAi = 'open_ai',
}

export enum AiUsageUnit {
  Credit = 'credit',
  Token = 'token',
}

export enum AchAccountType {
  BusinessChecking = 'businessChecking',
  BusinessSavings = 'businessSavings',
  PersonalChecking = 'personalChecking',
  PersonalSavings = 'personalSavings',
}

export enum AvailabilityKind {
  Availability = 'availability',
  Checkin = 'checkin',
  Holiday = 'holiday',
  Unavailability = 'unavailability',
}

export enum BankingTransactionKind {
  Plaid = 'plaid',
  Xero = 'xero',
}

export enum BankingTransactionRuleConditionComparator {
  Contains = 'contains',
  Equal = 'equal',
  Gt = 'gt',
  Gte = 'gte',
  Lt = 'lt',
  Lte = 'lte',
  NotContains = 'notContains',
  NotEqual = 'notEqual',
}

export enum BankingTransactionRuleConditionOperator {
  And = 'and',
  Or = 'or',
}

export enum BankingTransactionRuleInvoiceKind {
  ClientInvoice = 'clientInvoice',
  ContractorInvoice = 'contractorInvoice',
}

export enum BankingTransactionRuleInvoiceLogicAction {
  SetPaidStatus = 'setPaidStatus',
}

export enum BankingTransactionRuleInvoiceLogicConditionFilterInvoiceAttribute {
  DueDate = 'dueDate',
  Identifier = 'identifier',
  InvoiceDate = 'invoiceDate',
  TotalAmount = 'totalAmount',
}

export enum BankingTransactionRuleInvoiceLogicConditionFilterTransactionAttribute {
  Amount = 'amount',
  MerchantName = 'merchantName',
  Name = 'name',
  PostedAt = 'postedAt',
  Status = 'status',
}

export enum BankingTransactionRuleKind {
  ClientInvoice = 'client_invoice',
  ContractorInvoice = 'contractor_invoice',
  Default = 'default',
}

export enum BankingTransactionRuleLogicActionKind {
  Category = 'category',
  MerchantName = 'merchantName',
  Status = 'status',
}

export enum BankingTransactionRuleLogicConditionFilterKind {
  Amount = 'amount',
  MerchantName = 'merchantName',
  Name = 'name',
  PostedAt = 'postedAt',
  Status = 'status',
}

export enum BankingTransactionStatus {
  Created = 'created',
  Excluded = 'excluded',
  Reviewed = 'reviewed',
}

export enum BankingTransactionSummaryGroupByDate {
  Day = 'day',
  Month = 'month',
  Week = 'week',
}

export enum BillingTransferKind {
  Ach = 'ach',
  DomesticWire = 'domesticWire',
  InternationalWire = 'internationalWire',
}

export enum ClientCollaboratorStatus {
  Accepted = 'accepted',
  Declined = 'declined',
  Pending = 'pending',
}

export enum ClientInvoiceStatus {
  Declined = 'declined',
  Draft = 'draft',
  Paid = 'paid',
  Pending = 'pending',
}

export enum ContractorInvoiceStatus {
  Declined = 'declined',
  Draft = 'draft',
  Paid = 'paid',
  Pending = 'pending',
}

export enum Currency {
  Aud = 'aud',
  Cad = 'cad',
  Eur = 'eur',
  Usd = 'usd',
}

export enum DashboardScope {
  Organization = 'organization',
  Personal = 'personal',
  Project = 'project',
}

export enum GithubPullRequestEventAction {
  Closed = 'closed',
  Dismissed = 'dismissed',
  Merged = 'merged',
  Opened = 'opened',
  ReadyForReview = 'ready_for_review',
  Reopened = 'reopened',
  ReviewRequestRemoved = 'review_request_removed',
  ReviewRequested = 'review_requested',
  Submitted = 'submitted',
  Unlocked = 'unlocked',
}

export enum GlobalSearchEntityKind {
  Channel = 'channel',
  Message = 'message',
  Project = 'project',
  ProjectTask = 'projectTask',
}

export enum InvoiceKind {
  ClientInvoice = 'clientInvoice',
  ContractorInvoice = 'contractorInvoice',
}

export enum InvoiceStatus {
  Declined = 'declined',
  Draft = 'draft',
  Paid = 'paid',
  Pending = 'pending',
}

export enum MilestonePaddingKindEnumType {
  Dynamic = 'dynamic',
  Static = 'static',
}

export enum NotificationScheduleType {
  Availability = 'availability',
  Everyday = 'everyday',
  WorkingDays = 'workingDays',
}

export enum OrganizationCustomExpenseKind {
  Operating = 'operating',
  Overhead = 'overhead',
}

export enum OrganizationRole {
  Admin = 'admin',
  Client = 'client',
  Owner = 'owner',
  Pm = 'pm',
  Worker = 'worker',
}

export enum OrganizationSubscriptionPeriod {
  Annually = 'annually',
  Monthly = 'monthly',
}

export enum OrganizationSubscriptionPlan {
  Legacy = 'legacy',
  Professional = 'professional',
}

export enum OrganizationSubscriptionStatus {
  Active = 'active',
  Canceled = 'canceled',
  Incomplete = 'incomplete',
  IncompleteExpired = 'incomplete_expired',
  PastDue = 'past_due',
  Trialing = 'trialing',
  Unpaid = 'unpaid',
}

export enum OrganizationTwoFactorAuth {
  Disabled = 'disabled',
  Enabled = 'enabled',
  EnabledForce = 'enabled_force',
}

export enum PlaidTransactionStatus {
  Created = 'created',
  Excluded = 'excluded',
  Reviewed = 'reviewed',
}

export enum ProjectAiGameplanPromptStatus {
  Completion = 'completion',
  CompletionError = 'completion_error',
  CompletionLimitsExceeded = 'completion_limits_exceeded',
  Initial = 'initial',
  Ready = 'ready',
  Transcription = 'transcription',
  TranscriptionError = 'transcription_error',
  TranscriptionLimitsExceeded = 'transcription_limits_exceeded',
}

export enum ProjectMilestoneKindEnumType {
  FixedPrice = 'fixed_price',
  TimeAndMaterial = 'time_and_material',
}

export enum ProjectMilestoneStatusEnumType {
  Active = 'active',
  Cancelled = 'cancelled',
  Completed = 'completed',
  Draft = 'draft',
}

export enum ProjectOverviewGroup {
  Day = 'day',
  Month = 'month',
  Quarter = 'quarter',
  Week = 'week',
  Year = 'year',
}

export enum ProjectRoleEnumType {
  Admin = 'admin',
  Owner = 'owner',
  Pm = 'pm',
  Worker = 'worker',
}

export enum ProjectSupportWidgetAssignmentKind {
  Manual = 'manual',
  RoundRobin = 'round_robin',
}

export enum ProjectWorkerUserRoleEnumType {
  Pm = 'pm',
  Worker = 'worker',
}

export enum ProjectsFilterEnumType {
  Archived = 'archived',
  Commercial = 'commercial',
  NotCommercial = 'notCommercial',
}

export enum SortInputOrder {
  Asc = 'asc',
  Desc = 'desc',
}

export enum StripeInvoiceStatus {
  Draft = 'draft',
  Open = 'open',
  Paid = 'paid',
  Uncollectible = 'uncollectible',
  Void = 'void',
}

export enum StripePaymentMethodKind {
  AcssDebit = 'acss_debit',
  Affirm = 'affirm',
  AfterpayClearpay = 'afterpay_clearpay',
  Alipay = 'alipay',
  AuBecsDebit = 'au_becs_debit',
  BacsDebit = 'bacs_debit',
  Bancontact = 'bancontact',
  Blik = 'blik',
  Boleto = 'boleto',
  Card = 'card',
  CardPresent = 'card_present',
  CustomerBalance = 'customer_balance',
  Eps = 'eps',
  Fpx = 'fpx',
  Giropay = 'giropay',
  Grabpay = 'grabpay',
  Ideal = 'ideal',
  InteracPresent = 'interac_present',
  Klarna = 'klarna',
  Konbini = 'konbini',
  Link = 'link',
  Oxxo = 'oxxo',
  P24 = 'p24',
  Paynow = 'paynow',
  Pix = 'pix',
  Promptpay = 'promptpay',
  SepaDebit = 'sepa_debit',
  Sofort = 'sofort',
  UsBankAccount = 'us_bank_account',
  WechatPay = 'wechat_pay',
}

export enum StripePaymentMethodStatus {
  Attached = 'attached',
  Detached = 'detached',
}

/** TaskBoard filter conjunction */
export enum TaskBoardFilterConjunction {
  And = 'and',
  Or = 'or',
}

/** TaskBoard filter kind */
export enum TaskBoardFilterKind {
  Gt = 'gt',
  Gte = 'gte',
  Is = 'is',
  IsILike = 'isILike',
  IsNot = 'isNot',
  IsNotILike = 'isNotILike',
  IsNotSet = 'isNotSet',
  IsSet = 'isSet',
  Lt = 'lt',
  Lte = 'lte',
}

/** TaskBoard filter link kind */
export enum TaskBoardFilterLinkKind {
  Is = 'is',
  IsNot = 'isNot',
  IsNotSet = 'isNotSet',
  IsSet = 'isSet',
}

/** TaskBoard filter milestone status value */
export enum TaskBoardFilterMilestoneStatusValue {
  Active = 'active',
  Cancelled = 'cancelled',
  Completed = 'completed',
  Draft = 'draft',
}

/** TaskBoard filter status kind */
export enum TaskBoardFilterStatusKind {
  Is = 'is',
  IsNot = 'isNot',
}

/** TaskBoard filter status value */
export enum TaskBoardFilterStatusValue {
  Completed = 'completed',
  InProgress = 'inProgress',
}

export enum TaskBoardFilterTextSearchKind {
  IsILike = 'isILike',
  IsNotILike = 'isNotILike',
}

export enum TaskBoardSearchField {
  Description = 'description',
  Name = 'name',
  TaskNumber = 'taskNumber',
}

/** Task board sort: createdAt, weight, name, estimation, estimationSummary, startDate, deadline, overtime, overdue */
export enum TaskBoardSortColumn {
  CreatedAt = 'createdAt',
  Deadline = 'deadline',
  Estimation = 'estimation',
  EstimationSummary = 'estimationSummary',
  Name = 'name',
  Overdue = 'overdue',
  Overtime = 'overtime',
  StartDate = 'startDate',
  Weight = 'weight',
}

/** Task board sort order: asc, ascNullsFirst, desc, descNullsLast */
export enum TaskBoardSortOrder {
  Asc = 'asc',
  AscNullsFirst = 'ascNullsFirst',
  Desc = 'desc',
  DescNullsLast = 'descNullsLast',
}

export enum TaskGroupByEnumType {
  Assignee = 'assignee',
  Deadline = 'deadline',
  Stage = 'stage',
  Tag = 'tag',
}

export enum TaskLinkType {
  Blocking = 'blocking',
  Copied = 'copied',
  Linked = 'linked',
  Synced = 'synced',
}

export enum TaskStatusEnumType {
  Completed = 'completed',
  InProgress = 'in_progress',
  NotCompleted = 'not_completed',
  NotStarted = 'not_started',
}

export enum TaskboardFilterTemplateKindType {
  Personal = 'personal',
  Project = 'project',
}

export enum TeamMemberShareKind {
  Move = 'move',
  Share = 'share',
}

export enum TeamMemberShareState {
  Accepted = 'accepted',
  Obsoleted = 'obsoleted',
  Pending = 'pending',
  Rejected = 'rejected',
}

export enum TeamStatusDuration {
  DontClear = 'dont_clear',
  Hour_1 = 'hour_1',
  Hour_4 = 'hour_4',
  Minutes_30 = 'minutes_30',
  ThisWeek = 'this_week',
  Today = 'today',
}

export enum TeamWorkloadGroupByEnumType {
  Stage = 'stage',
  Tag = 'tag',
}

export enum TimeoffKind {
  Holiday = 'holiday',
  Sick = 'sick',
  Vacation = 'vacation',
}

export enum TimeoffStatus {
  Approved = 'approved',
  Declined = 'declined',
  Pending = 'pending',
}

export enum TrackerStateEnumType {
  Started = 'started',
  Stopped = 'stopped',
}

export enum UserDesktopAppPlatformType {
  Linux = 'linux',
  Macos = 'macos',
  Windows = 'windows',
}

export enum UserDevicePlatformType {
  Android = 'android',
  Browsers = 'browsers',
  Ios = 'ios',
  Macos = 'macos',
  Safari = 'safari',
  Windows = 'windows',
}

export enum UserRoleEnumType {
  Admin = 'admin',
  Client = 'client',
  Owner = 'owner',
  Pm = 'pm',
  Worker = 'worker',
}

export enum WireAccountType {
  Business = 'business',
  Individual = 'individual',
}

export enum XeroTransactionStatus {
  Created = 'created',
  Excluded = 'excluded',
  Reviewed = 'reviewed',
}
