import styled from 'styled-components'

import { themeGet } from 'Theme/v2'

export const EmailWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${themeGet('space.2')}px;
  color: ${themeGet('colors.text.body')};
  font-size: ${themeGet('fontSizes.0')}px;
  font-weight: ${themeGet('fontWeights.2')};
  cursor: pointer;
  position: relative;
  min-width: 0;

  svg {
    flex-shrink: 0;
  }
`

export const Email = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const Copied = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  background: ${themeGet('colors.success500')};
  color: white;
  border-radius: 4px;
  padding: 2px 0;
  font-size: 10px;
  line-height: 10px;
  text-align: center;
`
