import { HtmlHTMLAttributes } from 'react'

import styled from 'styled-components'
import { space, SpaceProps } from 'styled-system'

import { X } from 'phosphor-react'

import { createGetParam, themeGet } from 'Theme/v2'

export interface SizeProps {
  large?: boolean
  small?: boolean
}

export type ContainerProps = SpaceProps &
  SizeProps &
  HtmlHTMLAttributes<HTMLDivElement> & {
    disabled?: boolean
  }

export interface VariantProps {
  disabled?: boolean
}

const sizes = {
  labelFontSize: {
    large: themeGet('fontSizes.2'),
    default: themeGet('fontSizes.1'),
    small: themeGet('fontSizes.0'),
  },
  labelLineHeight: {
    large: themeGet('fontSizes.4'),
    default: themeGet('fontSizes.3'),
    small: themeGet('fontSizes.2'),
  },
  avatarHeight: {
    large: 40,
    default: 32,
    small: 24,
  },
  avatarWidth: {
    large: 40,
    default: 32,
    small: 24,
  },
  captionFontSize: {
    large: themeGet('fontSizes.0'),
    default: 10,
    small: 10,
  },
  captionLineHeight: {
    large: themeGet('fontSizes.2'),
    default: themeGet('fontSizes.0'),
    small: themeGet('fontSizes.0'),
  },
  iconHeight: {
    large: 16,
    default: 14,
    small: 12,
  },
  iconWidth: {
    large: 16,
    default: 14,
    small: 12,
  },
  iconFontSize: {
    large: themeGet('fontSizes.2'),
    default: themeGet('fontSizes.1'),
    small: themeGet('fontSizes.0'),
  },
  height: {
    large: 48,
    default: 40,
    small: 32,
  },
}

const params = {
  avatarOpacity: {
    disabled: 0.5,
    default: 1,
  },
  labelColor: {
    disabled: themeGet('colors.text.muted'),
    default: themeGet('colors.text.heading'),
  },
  captionColor: {
    disabled: themeGet('colors.text.muted'),
    default: themeGet('colors.text.body'),
  },
  iconColor: {
    disabled: themeGet('colors.text.muted'),
    default: themeGet('colors.text.heading'),
  },
  hoveredBorderColor: {
    disabled: themeGet('colors.divider.default'),
    default: themeGet('colors.divider.contrast'),
  },
  focusedOutlineStyle: {
    disabled: 'none',
    default: 'solid',
  },
  focusedDeleteIconColor: {
    disabled: themeGet('colors.text.muted'),
    default: themeGet('colors.primary500'),
  },
  deleteIconColor: {
    disabled: themeGet('colors.text.muted'),
    default: themeGet('colors.text.heading'),
  },
  deleteIconOpacity: {
    disabled: 0.2,
    default: 1,
  },
}

const getSizeParam = createGetParam<typeof sizes, SizeProps>(sizes)
const getParam = createGetParam<
  typeof params,
  Partial<VariantProps & SizeProps>
>(params)

export const Avatar = styled.img<VariantProps & SizeProps>`
  border-radius: 100%;
  height: ${getSizeParam('avatarHeight')}px;
  width: ${getSizeParam('avatarWidth')}px;
  opacity: ${getParam('avatarOpacity')};
  ${space}
`

export const Label = styled.span<VariantProps & SizeProps>`
  height: ${getSizeParam('labelLineHeight')}px;
  font-size: ${getSizeParam('labelFontSize')}px;
  color: ${getParam('labelColor')};
`

export const Caption = styled.span<VariantProps & SizeProps>`
  font-size: ${getSizeParam('captionFontSize')}px;
  line-height: ${getSizeParam('captionLineHeight')}px;
  color: ${getParam('captionColor')};
`

export const IconContainer = styled.div<VariantProps & SizeProps>`
  & svg {
    height: ${getSizeParam('iconHeight')}px;
    width: ${getSizeParam('iconWidth')}px;
    font-size: ${getSizeParam('iconFontSize')}px;
    color: ${getParam('iconColor')};
    fill: ${getParam('iconColor')};
  }
`

export const DeleteIcon = styled(X).attrs({
  weight: 'bold',
})<VariantProps>`
  color: ${getParam('deleteIconColor')};
  opacity: 0.2;
  cursor: pointer;
  ${space}
`

export const Container = styled.div<VariantProps & SizeProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 11px;
  padding: 4px 16px 4px 4px;
  border: 1px solid ${themeGet('colors.divider.default')};
  background-color: ${themeGet('colors.background.subblock')};
  border-radius: 100px;
  font-weight: ${themeGet('fontWeights.1')};
  height: ${getSizeParam('height')}px;

  &:hover {
    border-color: ${getParam('hoveredBorderColor')};
    & ${DeleteIcon} {
      color: ${getParam('focusedDeleteIconColor')};
      opacity: ${getParam('deleteIconOpacity')};
    }
  }

  &:focus {
    outline-width: 2px;
    outline-color: ${themeGet('colors.primary700')};
    outline-style: ${getParam('focusedOutlineStyle')};
    border-color: transparent;
    & ${DeleteIcon} {
      color: ${getParam('focusedDeleteIconColor')};
      opacity: ${getParam('deleteIconOpacity')};
    }
  }

  ${space};
`
