import { space } from 'Theme/defaultTheme'
import { ThemeV2 } from 'Theme/types'

import colors from './colors'
import sizes from './sizes'
import zIndices from './zIndices'

const theme: ThemeV2 = {
  name: 'default',
  font: `Inter, BlinkMacSystemFont, sans-serif`,
  webfonts: ['Inter:400,500,600,700,900'],
  defaultTransitionTime: '0.3s',
  sizes,
  space,
  colors,
  zIndices,
  //            0   1   2   3
  breakpoints: [32, 48, 64, 80],
  //          0   1   2   3   4   5   6   7   8   9
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 72, 96],
  //             0    1    2    3    4
  fontWeights: [400, 500, 600, 700, 900],
  //               0   1   2   3   4
  controlHeights: [24, 32, 40, 48, 56],

  shadow: {
    large: '0 1px 64px -2px rgba(0, 0, 0, 0.08)',
    medium: '0 1px 32px -2px rgba(0, 0, 0, 0.08)',
    small: '0 1px 16px -2px rgba(0, 0, 0, 0.05)',
    down: {
      large: '0 16px 64px -3px rgba(0, 0, 0, 0.08)',
      medium: '0 12px 32px -2px rgba(0, 0, 0, 0.08)',
      small: '0 8px 14px -4px rgba(0, 0, 0, 0.08)',
    },
    up: {
      large: '0 -8px 64px -3px rgba(0, 0, 0, 0.08)',
      medium: '0 -8px 32px -1px rgba(0, 0, 0, 0.08)',
      small: '0 -4px 16px -2px rgba(0, 0, 0, 0.08)',
    },
  },
}

export default theme
