function iterativeAverage(value, count, add) {
  return (value * count + add) / (count + 1)
}

function isNumber(value) {
  return !Number.isNaN(parseFloat(value)) && Number.isFinite(value)
}

function isEqual(a, b, delta = 0.0001) {
  return Math.abs(a - b) < delta
}

export default {
  iterativeAverage,
  isNumber,
  isEqual,
}
