import React, { useEffect } from 'react'
import ReactGA from 'react-ga'
import { useLocation } from 'react-router'

import get from 'lodash/get'
import noop from 'lodash/noop'

import { GA, IS_PRODUCTION } from 'Config/app'

import useAppContext from 'Hooks/useAppContext'

const analytics = IS_PRODUCTION
  ? ReactGA
  : {
      initialize: noop,
      set: noop,
      pageview: noop,
    }

analytics.initialize({ trackingId: GA.ID })

function LocationTracker() {
  const { me } = useAppContext()
  const { pathname } = useLocation()

  useEffect(() => {
    analytics.set({ page: pathname })
    analytics.pageview(pathname)
  }, [pathname])

  useEffect(() => {
    const userId = get(me, 'id')

    analytics.set({ userId })
  }, [me])

  return <div />
}

export default LocationTracker
