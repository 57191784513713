import styled, { css } from 'styled-components'
import { space, SpaceProps, styleFn } from 'styled-system'

import { createGetParam, themeGet } from 'Theme/v2'

interface SizeProps {
  large?: boolean
  small?: boolean
}

interface Props extends SpaceProps, SizeProps {
  success?: boolean
  danger?: boolean
  /**
   * @param progress - accept value between 0 and 1
   */
  progress?: number
  progressColor?: string
  trackColor?: string
}

const sizes = {
  height: {
    large: 8,
    default: 6,
    small: 4,
  },
}

const getSizeParams = createGetParam<typeof sizes, SizeProps>(sizes)

const progressColorCss: styleFn = ({
  success,
  danger,
  progressColor: backgroundColor,
}: Props) => {
  if (success) {
    return css`
      background-color: ${themeGet('colors.success500')};
    `
  }
  if (danger) {
    return css`
      background-color: ${themeGet('colors.danger500')};
    `
  }
  return css`
    background-color: ${backgroundColor || themeGet(`colors.primary500`)};
  `
}

const trackColorCss: styleFn = ({ trackColor }: Props) => css`
  background-color: ${trackColor || themeGet(`colors.divider.default`)};
`

const progressCss: styleFn = ({ progress }: Props) => (progress ?? 0) * 100

const ProgressBar = styled.div<Props>`
  position: relative;
  border-radius: 100px;
  overflow: hidden;
  height: ${getSizeParams('height')}px;
  &:before {
    content: '';
    top: 0;
    left: 0;
    bottom: 0;
    position: absolute;
    width: ${progressCss}%;
    border-radius: 100px;
    transition: width ${themeGet('defaultTransitionTime')};
    ${progressColorCss}
  }

  ${space}
  ${trackColorCss}
`
export default ProgressBar
