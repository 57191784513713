import { DateTime } from 'luxon'
import validatejs from 'validate.js'

import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'

validatejs.extend(validatejs.validators.datetime, {
  parse(value) {
    return DateTime.fromJSDate(value).toMillis()
  },
  format(value) {
    return DateTime.fromMillis(value).toJSDate()
  },
})

function validateArray(arrayItems, itemConstraints) {
  if (!arrayItems) return null

  const arrayItemErrors = arrayItems.reduce((acc, item, index) => {
    const error = validatejs(item, itemConstraints)

    if (error) {
      acc[index] = error
    } else {
      acc[index] = {}
    }

    return acc
  }, [])

  return isEmpty(arrayItemErrors) ? null : arrayItemErrors
}

validatejs.validators.array = validateArray

const pattern = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/

const password = (value, options) => {
  if (!pattern.test(value)) return options.message || 'is invalid'
  return undefined
}

const notEquality = (value, options) => {
  const items = filter(options.items, item => isEqual(item, value))
  if (items.length > 1) {
    return options.message || 'is duplicated'
  }

  return undefined
}

export default [
  { key: 'password', value: password },
  { key: 'notEquality', value: notEquality },
]
