// @ts-strict-ignore
import React, {
  ComponentProps,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { useMutation } from '@apollo/client'

import { Modal } from 'Components/UI/_v2'

import createTOTPFactorMutation from 'Graphql/Mutations/User/createTOTPFactor.graphql'
import enableTOTPVerificationMutation from 'Graphql/Mutations/User/enableTOTPVerification.graphql'

import toast from 'Services/Toast'
import Utils from 'Services/Utils'

import { InstallContent, SetupContent, SuccessContent } from './Contents'

enum Step {
  Install = 1,
  Setup,
  Success,
}

type Props = Pick<ComponentProps<typeof Modal>, 'isOpen' | 'onClose'> & {
  successSubtitle?: string
  successTitle?: string
  onFinish?: () => void
}

const INITIAL_STEP = Step.Install

// TODO: localize
function VerifyTOTPCoordinatorModal({
  isOpen,
  successSubtitle,
  successTitle,
  onClose,
  onFinish,
}: Props) {
  const [loading, setLoading] = useState(false)
  const [step, setStep] = useState(INITIAL_STEP)
  const [factor, setFactor] = useState<MainSchema.UserTotpFactor>()

  const [createTOTPFactor] = useMutation<
    MainMutationData<'createTOTPFactor'>,
    void
  >(createTOTPFactorMutation)
  const [enableTOTPVerification] = useMutation<
    MainMutationData<'enableTOTPVerification'>,
    MainSchema.MutationEnableTotpVerificationArgs
  >(enableTOTPVerificationMutation)

  const title = useMemo(
    () => ({
      [Step.Install]: 'Install an authentication app on your phone',
      [Step.Setup]: 'Scan the QR code using the app',
    }),
    [],
  )

  useEffect(() => {
    if (!isOpen) {
      setFactor(undefined)
      return
    }

    setStep(INITIAL_STEP)
    setLoading(true)

    createTOTPFactor().then(result => {
      setFactor(result.data?.createTOTPFactor)
      setLoading(false)
    })
  }, [isOpen, createTOTPFactor])

  const handleStepBack = useCallback(() => {
    setStep(prev => Math.max(Step.Install, prev - 1))
  }, [])

  const handleInstallContentFinish = useCallback(() => {
    setStep(Step.Setup)
  }, [])

  const handleSetupContentFinish = useCallback(
    async (code: string) => {
      if (!factor) return
      try {
        setLoading(true)
        await enableTOTPVerification({
          variables: { factorId: factor.id, code },
        })
        setStep(Step.Success)
      } catch (error: any) {
        const [graphQLError] = Utils.Errors.getGraphQLErrors(error)
        toast.error({ text: graphQLError })
        setLoading(false)
      }
    },
    [factor, enableTOTPVerification],
  )

  return (
    <Modal
      closeButtonDisabled={loading}
      isOpen={isOpen}
      title={title[step]}
      onClose={onClose}
    >
      {step === Step.Install && (
        <InstallContent onFinish={handleInstallContentFinish} />
      )}
      {step === Step.Setup && (
        <SetupContent
          factor={factor}
          loading={loading}
          onCancel={handleStepBack}
          onFinish={handleSetupContentFinish}
        />
      )}
      {step === Step.Success && (
        <SuccessContent
          subtitle={successSubtitle}
          title={successTitle}
          onConfirm={onFinish}
        />
      )}
    </Modal>
  )
}

export default VerifyTOTPCoordinatorModal
