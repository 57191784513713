import * as paths from 'Constants/paths'

import { Auth } from 'Containers/Layout'
import AuthPages from 'Containers/Pages/Auth'

export default [
  {
    component: Auth,
    path: paths.AUTH,
    routes: [
      {
        path: paths.SIGN_UP,
        exact: true,
        component: AuthPages.SignUp,
      },
      {
        path: paths.LOGIN,
        exact: true,
        component: AuthPages.Login,
      },
      {
        path: paths.FORGOT_PASSWORD,
        exact: true,
        component: AuthPages.ForgotPassword,
      },
      {
        path: paths.RESET_PASSWORD,
        exact: true,
        component: AuthPages.ResetPassword,
      },
      {
        path: paths.VERIFY,
        exact: true,
        component: AuthPages.VerifyAccount,
      },
      {
        path: paths.PHONE_VERIFICATION,
        exact: true,
        component: AuthPages.PhoneVerification,
      },
      {
        path: paths.TOTP_VERIFICATION,
        exact: true,
        component: AuthPages.TOTPVerification,
      },
      {
        path: paths.VERIFY_EMAIL,
        exact: true,
        component: AuthPages.VerifyEmail,
      },
      {
        path: paths.CREATE_PASSWORD,
        exact: true,
        component: AuthPages.CreatePassword,
      },
    ],
  },
]
