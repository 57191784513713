import styled from 'styled-components'
import { margin, MarginProps } from 'styled-system'

import { themeGet } from 'Theme/v2'

import { getSizeParam, SizeProps } from './Select/styles'

const Label = styled.div<MarginProps & SizeProps>`
  user-select: none;
  cursor: text;
  font-weight: ${themeGet('fontWeights.2')};
  color: ${themeGet('colors.text.heading')};
  font-size: ${getSizeParam('labelFontSize')}px;
  ${margin};
`

export default Label
