export const LANDING_PAGE_PADDING_X = [20, 20, 40]

export const LANDING_MAX_CONTENT_WIDTH = 1200

export const PRICING_FAQ_SECTION_ID = 'faq'

export const AFFILIATE_PAYOUT_PERCENT = { REFERRAL: 15, PARTNER: 30 }

export const COMPANY_SIZE_OPTIONS = [
  '1-4 people',
  '5-19 people',
  '20-49 people',
  '50-99 people',
  '100-499 people',
  '500+ people',
]

export const INDUSTRY_OPTIONS = [
  'Accounting Services',
  'Advertising & Marketing',
  'Architecture & Engineering',
  'Business Consulting & Advisory',
  'Consulting',
  'Financial Services & Insurance',
  'IT Services',
  'Legal',
  'Media',
  'Professional Services',
  'Public Sector & Government',
  'Real Estate & Construction',
  'Software/SaaS/Web Development',
  'Technology',
  'Telecommunications',
  'Training & Consultation',
  'Other',
]
