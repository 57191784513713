import { createGlobalStyle } from 'styled-components'
import styledNormalize from 'styled-normalize'
import { themeGet } from '@styled-system/theme-get'

import { rangeSlider, swiper, toasts } from './styles'

export default createGlobalStyle`
  ${styledNormalize}

  html {
    width: 100%;
   }

  body {
    color: ${themeGet('v2.colors.text.body')};
    font-size: 14px;
    font-family: ${themeGet('v2.font')};
    line-height: 20px;
    min-width: 100vw;
    min-height: 100vh;
    height: 100%;
    padding: 0;
    overflow: auto;
    margin: 0;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-overflow-scrolling: touch;

    #gs {
      .gs0 {
        bottom: 4px !important;
        left: 4px !important;
      }

      .gs1t {
        left: 4px !important;
        bottom: 64px !important;
      }

      .gs1z {
        margin-bottom: -48px !important;
      }
    }
  }

  html, body, #root, #root > div {
    margin: 0;
    box-sizing: border-box;
    background-repeat: repeat;
  }


  ::selection {
    background: ${themeGet('v2.colors.primary500')};
    color: white;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  pre {
    border: 1px solid ${themeGet('v2.colors.divider.default')};
    background: ${themeGet('v2.colors.background.block')};
    border-radius: 6px;
    padding: ${themeGet('v2.space.5')}px;
    margin: ${themeGet('v2.space.6')}px auto;
    text-align: left;
    font-size: ${themeGet('v2.fontSizes.1')}px;
    font-weight: ${themeGet('v2.fontWeights.0')};
    line-height: 24px;
  }

  input, textarea {
    display: block;
    margin: 10px auto;
  }

  .Toastify {
    &__toast-container {
      z-index: 10000 !important;
    }
  }

  .ReactModal__Body--open {
    overflow: hidden;
  }

  ${rangeSlider}
  ${toasts}
  ${swiper}
`
