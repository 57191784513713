import React, { ReactNode, useCallback } from 'react'

import { Column } from 'Components/UI/_v2/Flex'

import {
  Avatar,
  Caption,
  Container,
  ContainerProps,
  DeleteIcon,
  IconContainer,
  Label,
} from './styles'

type Props = ContainerProps & {
  caption?: string
  icon?: ReactNode
  img?: string
  label?: string
  onDelete?: () => void
}

function Chip({
  caption,
  disabled,
  icon,
  img,
  label,
  onDelete,
  ...rest
}: Props) {
  const handleDeleteIconClick = useCallback(() => {
    if (disabled) return
    onDelete?.()
  }, [disabled, onDelete])

  return (
    <Container {...rest} disabled={disabled} tabIndex={0}>
      <Avatar alt={label} src={img} />
      {icon && <IconContainer>{icon}</IconContainer>}

      <Column>
        <Label disabled={disabled} large={rest.large} small={rest.small}>
          {label}
        </Label>
        {caption && (
          <Caption disabled={disabled} large={rest.large} small={rest.small}>
            {caption}
          </Caption>
        )}
      </Column>
      <DeleteIcon onClick={handleDeleteIconClick} />
    </Container>
  )
}

export default Chip
