import styled, { css } from 'styled-components'
import {
  height,
  HeightProps,
  size,
  SizeProps,
  space,
  SpaceProps,
  width,
  WidthProps,
} from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

type StrokeProps = {
  stroke?: string
  fill?: string
}

const strokeCss = (props: StrokeProps) =>
  props.stroke &&
  css`
    stroke: ${themeGet(`colors.${props.stroke}`)};
    fill: ${themeGet(`colors.${props.fill}`)};
  `
type Props = StrokeProps & HeightProps & SizeProps & SpaceProps & WidthProps

export default styled.div.attrs({
  as: 'svg',
})<Props>`
  vertical-align: middle;
  flex-shrink: 0;

  ${strokeCss};

  ${size};
  ${width};
  ${height};
  ${space};
`
