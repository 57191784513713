// import { darken, lighten } from 'polished'

export const palette = {
  athensGray: '#e9e9ec',

  alabaster: '#f9f9f9',
  alto: '#ddd',
  aluminium: '#a7abb1',
  amaranth: '#e22e50',
  aquamarineBlue: '#6fe7be',
  azure: '#3666b3',
  bittersweet: '#fc766a',
  black: '#000000',
  blackOpacityDark: 'rgba(0, 0, 0, 0.8)',
  blackOpacity: 'rgba(0, 0, 0, 0.5)',
  blackOpacityMiddle: 'rgba(0, 0, 0, 0.1)',
  blackOpacityLight: 'rgba(0, 0, 0, 0.15)',
  brinkPink: '#fa6891',
  chestnut: '#bc554c',
  chestnutRose: '#c8456a',
  cornflower: '#92b5e9',
  dustyGray: '#979797',
  mischka: '#e3e3e6',
  porcelain: '#f4f5f6',
  ebony: '#141127',
  electricViolet: '#c700ff',
  emperor: '#555353',
  gallery: '#f0f0f0',
  dodgerBlue: '#449aff',
  catskillWhite: '#f5f7fA',
  cornflowerBlue: '#6998e4',
  emerald: '#39de57',
  frenchGray: '#c3c0c4',
  gigas: '#4a34a3',
  grey: '#343434',
  gunPowder: '#403f54',
  heliotrope: '#a289ff',
  hintOfRed: '#f7f4f4',
  malachite: '#23f566',
  mercury: '#e9e9e9',
  mineShaft: '#333',
  monaLisa: '#ff978d',
  oceanGreen: '#42b983',
  oxfordBlue: '#364152',
  pastelGreen: '#6ae693',
  perano: '#a1beee',
  selago: '#e9e5fb',
  pinkSalmon: '#ff96b4',
  pictonBlue: '#2f97e8',
  pomegranate: '#f52723',
  portage: '#967ef4',
  red: '#ff0000',
  roseRed: '#f05f52',
  redDamask: '#d75a4a',
  royalBlue: '#6644ea',
  santasGray: '#a4a4ad',
  sapphire: '#482790',
  scarpaFlow: '#535364',
  silverChalice: '#aaa',
  silverSand: '#c2c6cb',
  bombay: '#b2b5ba',
  spindle: '#b2bae9',
  tundora: '#4a4a4a',
  trout: '#4e595f',
  white: '#fff',
  whiteLilac: '#fafdfb',
  whiteOpacity: 'rgba(255, 255, 255, 0.8)',
  whiteOpacityLight: 'rgba(255, 255, 255, 0.4)',
  wildSand: '#f6f6f6',
  seashell: '#f1f1f1',
  confetti: '#e6dd6a',
  ghostWhite: '#f2f2f5',
  danger: '#d0021b',
  radicalRed: '#ff356d',
  rosyPink: '#fb6992',
  seafoamBlue: '#5cd498',
  softBlue: '#6a9ae6',
  deepYellowPink: '#f15d5d',
  fireSiennaCraiola: '#ed8e59',
  greenLime: '#bfdd02',
  greenCraiola: '#13ae61',
  moderateSlateBlue: '#6644ea',
  brightGray: '#e9ebf1',
  snowGray: '#FAFAFA',
  vodka: '#B7C0EB',
  lavender: '#DDEAFF',
  brightestGray: '#EBEBED',
}

const colors = {
  ...palette,
  clear: 'rgba(255, 255, 255, 0)',
  clearFromBlack: 'rgba(0, 0, 0, 0)',

  bg: palette.white,
  gradient: 'linear-gradient(to right, #eee 8%, #ddd 18%, #eee 33%)',
  gradientInvert: 'linear-gradient(to right, #ddd 8%, #eee 18%, #ddd 33%)',
  gradientStatus: 'linear-gradient(41deg, #fb6992, #6644ea)',

  gradientBlackOpacity: `linear-gradient(to top, ${
    palette.blackOpacity
  }, ${'rgba(255, 255, 255, 0)'})`,

  // Colors
  primary: palette.royalBlue,
  secondary: palette.santasGray,
  error: palette.bittersweet,
  disabled: palette.santasGray,
  textColor: palette.gunPowder,
  positive: palette.seafoamBlue,
  negative: palette.pinkSalmon,
  link: palette.cornflowerBlue,
  muted: palette.santasGray,

  // Fonts
  font: {
    primary: palette.gunPowder,
    secondary: palette.santasGray,
    link: palette.cornflowerBlue,
    placeholder: palette.santasGray,
  },
  whiteHover: '#f2f2f2',
}

// colors.lightGrey = lighten(0.6, colors.grey)
//
// colors.whiteActive = darken(0.1, colors.white)
//
// colors.primaryHover = darken(0.05, colors.primary)
// colors.primaryActive = darken(0.1, colors.primary)

export default colors
