import styled from 'styled-components'
import { space, SpaceProps } from 'styled-system'

import { createGetParam, themeGet } from 'Theme/v2'

export type SizeProps = {
  small?: boolean
  medium?: boolean
  large?: boolean
}

const sizes = {
  height: {
    large: 48,
    default: 40,
    small: 32,
  },
  labelFontSize: {
    large: themeGet('fontSizes.2'),
    default: themeGet('fontSizes.1'),
    small: themeGet('fontSizes.0'),
  },
  inputFontSize: {
    large: themeGet('fontSizes.2'),
    default: themeGet('fontSizes.1'),
    small: themeGet('fontSizes.0'),
  },
  errorFontSize: {
    large: themeGet('fontSizes.1'),
    default: themeGet('fontSizes.0'),
    small: '10px',
  },
}

const getSizeParam = createGetParam<typeof sizes, SizeProps>(sizes)

export type StyledInputProps = SizeProps & SpaceProps

export const StyledInput = styled.input<StyledInputProps>`
  width: 52px;
  height: ${getSizeParam('height')}px;
  margin: 0;
  border: 1px solid ${themeGet('colors.divider.default')};
  border-radius: 6px;
  outline: none;
  appearance: none;
  background: transparent;
  color: ${themeGet('colors.text.heading')};
  font-weight: ${themeGet('fontWeights.1')};
  font-size: ${getSizeParam('inputFontSize')}px;
  text-align: center;

  ::placeholder {
    color: ${themeGet('colors.text.muted')};
  }

  &:enabled:hover {
    border-color: ${themeGet('colors.divider.contrast')};
  }

  &:enabled:focus {
    border-color: ${themeGet('colors.primary500')};
  }

  &:disabled {
    color: ${themeGet('colors.text.muted')};
  }

  ${space}
`

export const Label = styled.div<SizeProps>`
  user-select: none;
  cursor: text;
  font-weight: ${themeGet('fontWeights.2')};
  color: ${themeGet('colors.text.heading')};
  font-size: ${getSizeParam('labelFontSize')}px;
`

export const ErrorText = styled.div<SizeProps>`
  display: flex;
  align-items: center;
  gap: ${themeGet('space.3')}px;
  color: ${themeGet('colors.danger500')};
  font-size: ${getSizeParam('errorFontSize')}px;
  font-weight: ${themeGet('fontWeights.1')};
`
