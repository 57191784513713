import { useEffect } from 'react'

export default function useUnload(actionHandler) {
  return useEffect(() => {
    window.addEventListener('beforeunload', actionHandler)

    return () => {
      window.removeEventListener('beforeunload', actionHandler)
    }
  }, [actionHandler])
}
