import isEqual from 'lodash/isEqual'
import pick from 'lodash/pick'

import { AUTH_ACTIONS, AUTH_STATE } from 'Constants/store'

import shared from 'Services/Shared'

export const AUTH_TOKEN_DEFAULTS = {
  accessToken: null,
  refreshToken: null,
  rememberMe: true,
}

export function getAuth() {
  return shared.getStore().get()?.[AUTH_STATE]
}

export function getAccessToken() {
  return getAuth()?.accessToken
}

export function getRefreshToken() {
  return getAuth()?.refreshToken
}

export function getRememberMe() {
  return getAuth()?.rememberMe
}

export function setAuth(variables) {
  return shared.getStore().dispatch(AUTH_ACTIONS.SET, variables)
}

export default function authState(store) {
  store.on('@init', () => ({
    [AUTH_STATE]: AUTH_TOKEN_DEFAULTS,
  }))

  store.on(AUTH_ACTIONS.SET, (state, variables) => {
    const values = pick(variables, Object.keys(AUTH_TOKEN_DEFAULTS))

    const nextAuth = { ...state[AUTH_STATE], ...values }

    if (isEqual(nextAuth, state[AUTH_STATE])) return null

    return { [AUTH_STATE]: nextAuth }
  })
}
