import styled from 'styled-components'
import {
  color,
  ColorProps,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
} from 'styled-system'

import { createGetParam, themeGet } from 'Theme/v2'

const sizes = {
  gap: {
    large: 12,
    default: 10,
    small: 8,
    extraSmall: 6,
  },
  fontSize: {
    large: 18,
    default: themeGet('fontSizes.2'),
    small: themeGet('fontSizes.1'),
    extraSmall: themeGet('fontSizes.0'),
  },
  lineHeight: {
    large: 28,
    default: 24,
    small: 20,
    extraSmall: 16,
  },
}

const variants = {
  color: {
    default: themeGet('colors.primary500'),
    secondary: themeGet('colors.text.heading'),
    tertiary: themeGet('colors.text.body'),
    danger: themeGet('colors.danger500'),
    success: themeGet('colors.success500'),
  },
  focusedOutline: {
    default: themeGet('colors.primary700'),
    secondary: themeGet('colors.primary700'),
    tertiary: themeGet('colors.text.body'),
    danger: themeGet('colors.danger700'),
    success: themeGet('colors.success700'),
  },
  hoverColor: {
    default: themeGet('colors.primary400'),
    secondary: themeGet('colors.primary500'),
    tertiary: themeGet('colors.primary500'),
    danger: themeGet('colors.danger400'),
    success: themeGet('colors.success400'),
  },
  activeColor: {
    default: themeGet('colors.primary300'),
    secondary: themeGet('colors.primary300'),
    tertiary: themeGet('colors.primary300'),
    danger: themeGet('colors.danger300'),
    success: themeGet('colors.success300'),
  },
  disabledColor: {
    default: themeGet('colors.primary700'),
    secondary: themeGet('colors.text.muted'),
    tertiary: themeGet('colors.text.muted'),
    danger: themeGet('colors.danger700'),
    success: themeGet('colors.success700'),
  },
}

const getSizeParam = createGetParam<typeof sizes, SizeProps>(sizes)
const getVariantsParam = createGetParam<typeof variants, VariantProps>(variants)

interface VariantProps {
  primary?: boolean
  secondary?: boolean
  tertiary?: boolean
  danger?: boolean
  success?: boolean
}

interface SizeProps {
  large?: boolean
  small?: boolean
  extraSmall?: boolean
}

interface Props
  extends VariantProps,
    SizeProps,
    FlexboxProps,
    LayoutProps,
    SpaceProps,
    ColorProps {}

const LinkButton = styled.button.attrs(props => ({
  type: props.type || 'button',
}))<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  gap: ${getSizeParam('gap')}px;
  font-size: ${getSizeParam('fontSize')}px;
  font-weight: ${themeGet('fontWeights.2')};
  line-height: ${getSizeParam('lineHeight')}px;
  color: ${getVariantsParam('color')};

  &:focus-visible {
    outline: 2px solid ${getVariantsParam('focusedOutline')};
    border-radius: 6px;
  }

  &:enabled:hover {
    color: ${getVariantsParam('hoverColor')};
  }

  &:enabled:active {
    color: ${getVariantsParam('activeColor')};
  }

  &:disabled {
    cursor: default;
  }

  &:disabled {
    color: ${getVariantsParam('disabledColor')};
  }

  ${flexbox}
  ${layout}
  ${space}
  ${color}
`

export default LinkButton
