// @ts-strict-ignore
import { useMemo } from 'react'

import eachDeep from 'deepdash/eachDeep'

import uniqBy from 'lodash/uniqBy'

import {
  HelpCenterSection,
  HelpCenterSectionBySlug,
} from 'Constants/helpCenter'

// TODO: add proper typing
function useHelpCenterSections(sectionsBySlug: HelpCenterSectionBySlug): any {
  return useMemo(() => {
    let recentSections: any = []
    if (sectionsBySlug) {
      eachDeep(
        sectionsBySlug,
        (node: { sections: [HelpCenterSection] }) => {
          if (node?.sections)
            recentSections = recentSections.concat(node.sections)
        },
        [],
      )
      recentSections = uniqBy(recentSections, (v: HelpCenterSection) => v.id)
    }
    return recentSections
  }, [sectionsBySlug])
}

export default useHelpCenterSections
