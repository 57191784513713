import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'

function HelmetTitle({ title, children }) {
  return (
    <Helmet>
      {title && <title>{title}</title>}
      {children}
    </Helmet>
  )
}

HelmetTitle.defaultProps = {
  children: null,
  title: 'Worklair',
}

HelmetTitle.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  title: PropTypes.string,
}

export default HelmetTitle
