// @ts-strict-ignore
import React, { ComponentProps, ReactNode, useMemo } from 'react'
import { Props as ReactSelectProps } from 'react-select'

import { useTheme } from 'styled-components'
import { WidthProps } from 'styled-system'
import { pick } from '@styled-system/props'

import { Theme } from 'Theme/v2'

import * as Components from './Components'
import {
  Caption,
  SizeProps,
  StyledSelect,
  Wrapper,
  WrapperProps,
} from './styles'
import { CustomSelectProps, OptionType } from './types'

import Label from '../Label'

type OptionsType = OptionType[]
type ValueType = OptionType | OptionsType

export type Props = WidthProps &
  WrapperProps &
  SizeProps &
  CustomSelectProps &
  ComponentProps<typeof StyledSelect> & {
    options?: OptionType[]
    disabled?: boolean
    withPortal?: boolean
    async?: boolean
    cacheOptions?: boolean
    defaultOptions?: boolean
    components?: any
    creatable?: boolean
    danger?: boolean
    formatOptionLabel?: ReactSelectProps['formatOptionLabel']
    formatCreateLabel?: (input: string) => void
    createOptionPosition?: 'first' | 'last'
    allowCreateWhileLoading?: boolean
    caption?: string
    isMulti?: boolean
    isSearchable?: boolean
    isLoading?: boolean
    menuIsOpen?: boolean
    placeholder?: string
    scrollToOption?: OptionType
    label?: ReactNode
    loadOptions?: (
      inputValue: string,
      callback: (options: OptionType[]) => void,
    ) => Promise<OptionType[]> | void
    isValidNewOption?: (inputValue: string) => boolean
    value?: ValueType
    onInputChange?: (newValue: string) => void
  }

const THEME = (theme: Theme) => ({
  ...theme,
  borderRadius: 5,
  spacing: {
    baseUnit: 2,
    menuGutter: 4,
  },
})

function Select({
  async,
  components,
  creatable,
  withPortal,
  disabled,
  formatOptionLabel,
  formatCreateLabel,
  createOptionPosition,
  allowCreateWhileLoading,
  isValidNewOption,
  width,
  caption,
  danger,
  options,
  isMulti,
  isSearchable,
  isLoading,
  menuIsOpen,
  placeholder,
  scrollToOption,
  styles,
  value,
  label,
  ...rest
}: Props) {
  const theme = useTheme()
  const selectTheme = useMemo(() => THEME(theme), [theme]) as any

  const customStyles = useMemo(() => {
    return withPortal
      ? {
          ...(styles ?? {}),
          menuPortal: (base: any) => ({
            ...base,
            zIndex: 9999,
          }),
        }
      : styles
  }, [styles, withPortal])

  return (
    <Wrapper {...pick(rest)} width={width}>
      {label && <Label mb={2}>{label}</Label>}

      <StyledSelect
        {...rest}
        allowCreateWhileLoading={allowCreateWhileLoading}
        async={async}
        components={{ ...Components, ...components }}
        creatable={creatable}
        createOptionPosition={createOptionPosition}
        danger={danger}
        formatCreateLabel={formatCreateLabel}
        formatOptionLabel={formatOptionLabel}
        isDisabled={disabled}
        isLoading={isLoading}
        isMulti={isMulti}
        isSearchable={isSearchable}
        isValidNewOption={isValidNewOption}
        menuIsOpen={menuIsOpen}
        menuPortalTarget={withPortal ? document.body : undefined}
        options={options}
        placeholder={placeholder}
        scrollToOption={scrollToOption}
        styles={customStyles}
        theme={selectTheme}
        value={value}
        width={width}
      />
      {caption && danger && <Caption mt={2}>{caption}</Caption>}
    </Wrapper>
  )
}

Select.defaultProps = {
  async: false,
  components: null,
  creatable: false,
  withPortal: false,
  disabled: false,
  width: 1,
  options: [],
} as Props

export default Select
