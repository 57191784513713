import React, { ComponentProps } from 'react'
import { Field } from 'react-final-form'

import Utils from 'Services/Utils'

import PinInput from './PinInput'

type Props = ComponentProps<typeof PinInput> & {
  name: string
}

function PinInputField({ name, ...rest }: Props) {
  return (
    <Field name={name}>
      {({ input: { value, onChange }, meta }) => {
        const { error } = Utils.Form.hasError(meta)
        return (
          <PinInput {...rest} error={error} value={value} onChange={onChange} />
        )
      }}
    </Field>
  )
}

export default PinInputField
