import { useCallback } from 'react'

import i18n from 'i18n-js'
import { useStoreon } from 'storeon/react'

import get from 'lodash/get'

import { LANGUAGE } from 'Constants/ids'
import { I18N_STATE } from 'Constants/store'

import mainEn from './translations/en.json'
import mainUk from './translations/uk.json'

const validLanguages = [LANGUAGE.EN, LANGUAGE.UK]

const translationsSet = { en: mainEn, uk: mainUk }

function initTranslations({ store }) {
  const i18nState = store.get()[I18N_STATE]
  let lang

  const storedLand = get(i18nState, 'locale')

  if (storedLand) {
    lang = storedLand
  } else {
    lang = window.navigator.language || window.navigator.userLanguage
    lang = lang ? lang.substring(0, 2) : ''
  }

  if (!validLanguages.includes(lang)) {
    lang = LANGUAGE.EN
  }

  i18n.locale = lang

  i18n.translations = translationsSet
  i18n.missingTranslation = key => `![${key}]`

  return i18n.locale
}

function _(key, options) {
  return i18n.t(key, options)
}

function I18n({ t, options }) {
  const { i18n: state } = useStoreon(I18N_STATE)
  return _(t, { ...options, locale: state.locale })
}

export function useScopedI18n(key) {
  return useCallback((subKey, opts) => _(`${key}.${subKey}`, opts), [key])
}

export { _, initTranslations, validLanguages }

export default I18n
