import styled from 'styled-components'
import { layout, padding, position } from 'styled-system'

import { themeGet } from 'Theme/v2'

export const Container = styled.div.attrs({
  height: ['unset', 'unset', '100vh'],
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  background-color: ${themeGet('colors.background.block')};
  position: relative;

  ${layout}
`

export const Content = styled.div.attrs({
  pt: [92, 92, 128, 112],
  px: [20, 20, 40, 64],
})`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;

  ${position}
  ${padding}
`
