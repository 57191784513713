import React from 'react'
import { renderRoutes } from 'react-router-config'
import PropTypes from 'prop-types'

import { Container } from './styles'

function Root({ route: currentRoute, user }) {
  return <Container>{renderRoutes(currentRoute.routes, { user })}</Container>
}

Root.defaultProps = {
  user: null,
}

Root.propTypes = {
  route: PropTypes.object.isRequired,
  user: PropTypes.object,
}

export default Root
