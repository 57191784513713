import loadable from '@loadable/component'

import * as paths from 'Constants/paths'

import { LegalLayout } from 'Containers/Layout'

const Legal = loadable(() => import('Containers/Pages/Legal'))
const LegalRoot = loadable(() => import('Containers/Pages/LegalRoot'))

export default [
  {
    component: LegalLayout,
    path: paths.LEGAL,
    routes: [
      {
        path: paths.LEGAL,
        exact: true,
        component: LegalRoot,
      },
      {
        path: paths.CLOUD_TERMS_OF_SERVICE,
        exact: true,
        component: Legal,
      },
      {
        path: paths.PRIVACY_POLICY,
        exact: true,
        component: Legal,
      },
      {
        path: paths.USER_NOTICE,
        exact: true,
        component: Legal,
      },
      {
        path: paths.ACCEPTABLE_USE_POLICY,
        exact: true,
        component: Legal,
      },
      {
        path: paths.COPYRIGHT_AND_TRADEMARK_VIOLATION,
        exact: true,
        component: Legal,
      },
      {
        path: paths.DATA_TRANSFER_IMPACT_ASSESSMENT,
        exact: true,
        component: Legal,
      },
      {
        path: paths.OTHER_LEGAL,
        exact: true,
        component: Legal,
      },
      {
        path: paths.RESELLER_AGREEMENT,
        exact: true,
        component: Legal,
      },
      {
        path: paths.IMPRESSUM,
        exact: true,
        component: Legal,
      },
      {
        path: paths.PRODUCT_FAMILY,
        exact: true,
        component: Legal,
      },
      {
        path: paths.SUBPROCESSORS,
        exact: true,
        component: Legal,
      },
      {
        path: paths.DATA_PROCESSING_APPENDUM,
        exact: true,
        component: Legal,
      },
      {
        path: paths.LEGAL_GDPR,
        exact: true,
        component: Legal,
      },
      {
        path: paths.SOFTWARE_LICENSE_AGREEMENT,
        exact: true,
        component: Legal,
      },
      {
        path: paths.THIRD_PARTY_CODE,
        exact: true,
        component: Legal,
      },
      {
        path: paths.GOVERNMENT_AMENDMENT,
        exact: true,
        component: Legal,
      },
      {
        path: paths.COOKIES_NOTICE,
        exact: true,
        component: Legal,
      },
      {
        path: paths.SECURITY_STATEMENT,
        exact: true,
        component: Legal,
      },
      {
        path: paths.ARTIFICIAL_INTELLIGENCE,
        exact: true,
        component: Legal,
      },
    ],
  },
]
