import styled, { css } from 'styled-components'
import { layout, LayoutProps, space, SpaceProps } from 'styled-system'

import { themeGet } from 'Theme/v2'

interface ShapeProps {
  square?: boolean
}

const containerShapeCss = ({ square }: ShapeProps) => {
  if (square) {
    return css`
      border-radius: 4px;
    `
  }

  return css`
    border-radius: 999px;
  `
}

export const Container = styled.div<ShapeProps & SpaceProps & LayoutProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${themeGet('colors.divider.default')};
  background: ${themeGet('colors.divider.light')};
  color: ${themeGet('colors.text.muted')};

  ${containerShapeCss}
  ${space}
  ${layout}
`
