// @ts-strict-ignore
import validate from 'validate.js'

import isEmpty from 'lodash/isEmpty'

function acronym(str, length?: number) {
  const matches = str.match(/\b(\w)/g)
  return matches ? matches.join('').substring(0, length) : ''
}

function isValidEmail(email) {
  return (
    email &&
    isEmpty(
      validate(
        { email },
        {
          email: { email: true },
        },
      ),
    )
  )
}

function isEmptyOrSpaces(str?: string): boolean {
  return isEmpty(str) || str.match(/^\s*$/) !== null
}

function wrapSymbolWithSpan(value: string, symbol: string) {
  return value?.replace(new RegExp(`(${symbol})`, 'gi'), `<span>$1</span>`)
}

export default {
  acronym,
  isValidEmail,
  isEmptyOrSpaces,
  wrapSymbolWithSpan,
}
