import styled from 'styled-components'
import { MarginProps, padding, PaddingProps } from 'styled-system'

import Tippy, { TippyProps } from '@tippyjs/react'

import { createGetParam, themeGet } from 'Theme/v2'

import 'tippy.js/dist/tippy.css'

const DEFAULT_DELAY: [number, number] = [500, 0]

interface SizeProps {
  large?: boolean
  small?: boolean
}

interface Props extends TippyProps, SizeProps, MarginProps, PaddingProps {}

const sizes = {
  fontSizes: {
    large: themeGet('fontSizes.2'),
    default: themeGet('fontSizes.1'),
    small: themeGet('fontSizes.0'),
  },
  lineHeight: {
    large: 28,
    default: 24,
    small: 20,
  },
  paddingY: {
    large: 8,
    default: 7,
    small: 6,
  },
  paddingX: {
    large: 16,
    default: 14,
    small: 12,
  },
}

const getSizeParam = createGetParam<typeof sizes, SizeProps>(sizes)

const Tooltip = styled(Tippy).attrs({
  duration: 0,
  arrow: true,
} as Props)<Props>`
  background-color: ${themeGet('colors.background.block')};
  border-radius: 6px;
  box-shadow: ${themeGet('shadow.medium')};

  & .tippy-content {
    color: ${themeGet('colors.text.heading')};
    font-size: ${getSizeParam('fontSizes')}px;
    line-height: ${getSizeParam('lineHeight')}px;
    padding: ${getSizeParam('paddingY')}px ${getSizeParam('paddingX')}px;

    ${padding};
  }

  & .tippy-arrow {
    color: ${themeGet('colors.background.block')};
    z-index: 1;
  }
`

export default Object.assign(Tooltip, { Delay: DEFAULT_DELAY })
