import React from 'react'
import { ClearIndicatorProps } from 'react-select'

import { FiX } from 'react-icons/fi'

import { Clear } from './styles'

const ClearIndicator = React.forwardRef<HTMLDivElement, ClearIndicatorProps>(
  ({ innerProps }, ref) => (
    <Clear {...innerProps} ref={ref}>
      <FiX />
    </Clear>
  ),
)

export default ClearIndicator
