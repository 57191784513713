// @ts-strict-ignore
import React, { useCallback, useMemo } from 'react'
import { Form } from 'react-final-form'

import { useMutation } from '@apollo/client'
import validate from 'validate.js'

import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

import { Button, InputField, Row, SelectField } from 'Components/UI/_v2'

import { HelpCenterArticle, HelpCenterSection } from 'Constants/helpCenter'

import contactSupportMutation from 'Graphql/Mutations/Communication/contactSupport.graphql'

import { useAppContext } from 'Hooks'

import { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'
import Utils from 'Services/Utils'

import { Divider, StyledForm } from './styles'

enum Field {
  Name = 'name',
  Email = 'email',
  Topic = 'topic',
  Subject = 'subject',
  Description = 'description',
}

type Props = {
  onClose: () => void
  supportTicketSubjects?: [HelpCenterSection | HelpCenterArticle]
}

function ContactSupportForm({ supportTicketSubjects, onClose }: Props) {
  const { me } = useAppContext()
  const s = useScopedI18n('blocks.contactSupportForm')

  const selectTopicOptions = useMemo(
    () =>
      map(supportTicketSubjects, subject => ({
        value: subject.title,
        label: subject.title,
      })),
    [supportTicketSubjects],
  )

  const initialValues = useMemo(
    () => ({
      [Field.Email]: me?.email,
    }),
    [me],
  )

  const formValidation = useCallback(
    values =>
      validate(values, {
        [Field.Name]: {
          presence: {
            presence: true,
          },
        },
        [Field.Email]: {
          presence: true,
          email: true,
        },
        [Field.Subject]: {
          presence: true,
        },
      }),
    [],
  )

  const [contactSupport] = useMutation<
    MainMutationData<'contactSupport'>,
    MainSchema.MutationContactSupportArgs
  >(contactSupportMutation)

  const submit = useCallback(
    async values => {
      try {
        await contactSupport({
          variables: {
            name: values[Field.Name],
            email: values[Field.Email],
            topic: values[Field.Topic]?.label ?? '',
            subject: values[Field.Subject],
            description: values[Field.Description] ?? '',
          },
        })

        onClose()
        toast.success({ text: s('success') })
      } catch (error) {
        const [graphQLError] = Utils.Errors.getGraphQLErrors(error)
        toast.error({ text: graphQLError })
      }
    },
    [contactSupport, onClose, s],
  )

  const renderForm = useCallback(
    ({ handleSubmit }) => (
      <StyledForm onSubmit={handleSubmit}>
        <InputField
          label={s('fields.name')}
          mb={6}
          name={Field.Name}
          placeholder={s('fields.placeholders.name')}
          width={1}
        />

        <InputField
          label={s('fields.email')}
          mb={6}
          name={Field.Email}
          placeholder={s('fields.placeholders.email')}
          width={1}
        />

        {!isEmpty(selectTopicOptions) && (
          <SelectField
            label={s('fields.topic')}
            mb={6}
            name={Field.Topic}
            options={selectTopicOptions}
            placeholder={s('fields.placeholders.topic')}
            withPortal
          />
        )}

        <InputField
          label={s('fields.subject')}
          mb={6}
          name={Field.Subject}
          placeholder={s('fields.placeholders.subject')}
          width={1}
        />

        <InputField
          label={s('fields.description')}
          mb={5}
          name={Field.Description}
          placeholder={s('fields.placeholders.description')}
          width={1}
        />

        <Divider />

        <Row gap={4} mt={5}>
          <Button secondary width={1} onClick={onClose}>
            {s('cancel')}
          </Button>
          <Button type="submit" width={1} onClick={handleSubmit}>
            {s('send')}
          </Button>
        </Row>
      </StyledForm>
    ),
    [onClose, s, selectTopicOptions],
  )

  return (
    <Form
      initialValues={initialValues}
      render={renderForm}
      validate={formValidation}
      onSubmit={submit}
    />
  )
}

export default ContactSupportForm
