import React, { useCallback, useEffect, useState } from 'react'

import { useSingleInstance } from 'Hooks'

import EventBus from 'Services/EventBus'

import Modal from './Modal'

enum Action {
  Start = 'VerifyPhoneCoordinator/Start',
}

type FinishResult = {
  success: boolean
  userCancelled?: boolean
}

type StartOptions = {
  successSubtitle?: string
  successTitle?: string
  onCancel?: () => void
  onFinish?: (result: FinishResult) => void
}

// TODO: add support for callbacks
function VerifyPhoneCoordinator() {
  useSingleInstance('VerifyPhoneCoordinator')

  const [options, setOptions] = useState<StartOptions>()
  const [modalOpen, setModalOpen] = useState(false)

  const finish = useCallback(() => {
    setModalOpen(false)
    setOptions(undefined)
  }, [])

  useEffect(() => {
    const startToken = EventBus.on(
      Action.Start,
      (options: StartOptions | undefined) => {
        setOptions(options)
        setModalOpen(true)
      },
    )

    return () => {
      startToken.clear()
    }
  }, [])

  // TODO: disable backdrop cancelling while queries are running
  return (
    <Modal
      isOpen={modalOpen}
      successSubtitle={options?.successSubtitle}
      successTitle={options?.successTitle}
      onClose={finish}
      onFinish={finish}
    />
  )
}

VerifyPhoneCoordinator.start = (options?: StartOptions) =>
  EventBus.trigger(Action.Start, options)

export default VerifyPhoneCoordinator
