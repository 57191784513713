export default {
  projectHeader: 496,
  chatPanel: 497,
  projectNavigation: 497,
  header: 498,
  inboxPanel: 498,
  navigationPanel: 499,
  blockerOverlay: 899,
  blocker: 900,
  modal: 1000,
  dropdown: 1500,
  modalFullscreen: 2000,
}
