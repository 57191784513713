// @ts-strict-ignore
import React, { useCallback, useState } from 'react'
import { Form } from 'react-final-form'

import { useMutation } from '@apollo/client'
import validate from 'validate.js'

import CalendlyWidgetModal from 'Components/Blocks/CalendlyWidget'
import {
  Button,
  CheckboxField,
  InputField,
  PhoneInput,
  Row,
  Text,
} from 'Components/UI/_v2'

import { emailConstraint } from 'Constants/constraints'
import { CLOUD_TERMS_OF_SERVICE, PRIVACY_POLICY } from 'Constants/paths'

import requestDemoMutation from 'Graphql/Mutations/Communication/requestDemo.graphql'

import { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import { StyledForm, StyledLink, Tab } from './styles'

enum Field {
  FirstName = 'firstName',
  LastName = 'lastName',
  WorkEmail = 'email',
  CompanyName = 'companyName',
  CompanySize = 'companySize',
  Message = 'message',
  Agree = 'agree',
}

type Props = {
  onClose: () => void
}

function RequestDemoForm({ onClose }: Props) {
  const s = useScopedI18n('blocks.requestDemoForm')

  const [tabIndex, setTabIndex] = useState(0)
  const [phoneNumber, setPhoneNumber] = useState(null)
  const [isRequestModalOpen, setIsRequestModalOpen] = useState(false)

  const [requestDemo] = useMutation<
    MainMutationData<'requestDemo'>,
    MainSchema.MutationRequestDemoArgs
  >(requestDemoMutation)

  const submit = useCallback(
    async values => {
      try {
        await requestDemo({
          variables: {
            firstName: values[Field.FirstName],
            lastName: values[Field.LastName],
            phoneNumber: phoneNumber ?? '',
            workEmail: values[Field.WorkEmail],
            companyName: values[Field.CompanyName],
            companySize: Number(values[Field.CompanySize]) ?? 0,
            message: values[Field.Message] ?? '',
          },
        })

        setIsRequestModalOpen(true)
        toast.success({ text: s('success') })
      } catch (e) {
        toast.error({
          text: e.response?.body?.error?.message || s('errors.default'),
        })
      }
    },
    [phoneNumber, requestDemo, s],
  )

  const formValidationFirstStep = useCallback(
    values =>
      validate(values, {
        [Field.FirstName]: {
          presence: {
            presence: true,
          },
        },
        [Field.LastName]: {
          presence: {
            presence: true,
          },
        },
        ...emailConstraint,
      }),
    [],
  )

  const formValidationSecondStep = useCallback(
    values =>
      validate(values, {
        [Field.CompanyName]: {
          presence: {
            presence: true,
          },
        },
        [Field.CompanySize]: {
          presence: true,
          numericality: {
            onlyInteger: true,
            greaterThan: 0,
          },
        },
      }),
    [],
  )

  const renderFirstStepForm = useCallback(
    ({ handleSubmit }) => (
      <StyledForm onSubmit={handleSubmit}>
        <Row fullWidth gap={5} mb={9}>
          <InputField
            label={s('fields.firstName')}
            name={Field.FirstName}
            placeholder={s('fields.placeholders.firstName')}
            width={1}
          />
          <InputField
            label={s('fields.lastName')}
            name={Field.LastName}
            placeholder={s('fields.placeholders.lastName')}
            width={1}
          />
        </Row>

        <PhoneInput
          label={s('fields.phoneNumber')}
          mb={9}
          placeholder={s('fields.placeholders.phoneNumber')}
          value={phoneNumber}
          onChange={value => setPhoneNumber(value)}
        />

        <InputField
          label={s('fields.workEmail')}
          mb={9}
          name={Field.WorkEmail}
          placeholder={s('fields.placeholders.workEmail')}
        />

        <Button width={1} onClick={handleSubmit}>
          {s('continue')}
        </Button>
      </StyledForm>
    ),
    [phoneNumber, s],
  )

  const handleCalendlyWidgetClose = useCallback(() => {
    setIsRequestModalOpen(false)
    onClose?.()
  }, [onClose])

  const renderSecondStepForm = useCallback(
    ({ handleSubmit, values }) => (
      <StyledForm onSubmit={handleSubmit}>
        <Row fullWidth gap={5} mb={9}>
          <InputField
            label={s('fields.companyName')}
            name={Field.CompanyName}
            placeholder={s('fields.placeholders.companyName')}
            width={1}
          />

          <Row noShrink width={128}>
            <InputField
              label={s('fields.companySize')}
              min={1}
              name={Field.CompanySize}
              placeholder={s('fields.placeholders.companySize')}
              type="number"
            />
          </Row>
        </Row>

        <InputField
          isMulti
          label={s('fields.additionalInfo')}
          mb={9}
          name={Field.Message}
          placeholder={s('fields.placeholders.additionalInfo')}
          resize="none"
          rows={4}
        />

        <CheckboxField
          label={
            <Text as="div" heading subheader2>
              {s('fields.agree')[0]}
              &nbsp;
              <StyledLink target="_blank" to={CLOUD_TERMS_OF_SERVICE}>
                {s('fields.agree')[1]}
              </StyledLink>
              &nbsp;
              {s('fields.agree')[2]}
              &nbsp;
              <StyledLink target="_blank" to={PRIVACY_POLICY}>
                {s('fields.agree')[3]}
              </StyledLink>
            </Text>
          }
          mb={9}
          name={Field.Agree}
        />

        <Button
          disabled={!values[Field.Agree]}
          type="submit"
          width={1}
          onClick={handleSubmit}
        >
          {s('requestDemo')}
        </Button>
      </StyledForm>
    ),
    [s],
  )

  return (
    <>
      <Row fullWidth justifyCenter mb={9}>
        <Tab active={tabIndex === 0}>
          <Text mb={3} subheader1>
            1. {s('tabs.yourContacts')}
          </Text>
        </Tab>
        <Tab active={tabIndex === 1}>
          <Text mb={3} subheader1>
            2. {s('tabs.companyInfo')}
          </Text>
        </Tab>
      </Row>

      {tabIndex === 0 ? (
        <Form
          render={renderFirstStepForm}
          validate={formValidationFirstStep}
          onSubmit={() => setTabIndex(tabIndex + 1)}
        />
      ) : (
        <Form
          render={renderSecondStepForm}
          validate={formValidationSecondStep}
          onSubmit={submit}
        />
      )}

      <CalendlyWidgetModal
        isOpen={isRequestModalOpen}
        onClose={handleCalendlyWidgetClose}
      />
    </>
  )
}

export default RequestDemoForm
