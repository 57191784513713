// @ts-strict-ignore
import React, { useCallback, useMemo } from 'react'
import { Form } from 'react-final-form'

import { QRCodeSVG } from 'qrcode.react'
import validate from 'validate.js'

import { Button, Column, Row, Text } from 'Components/UI/_v2'
import { InputField } from 'Components/UI/_v2/Forms'

import { TOTP_LENGTH } from 'Constants/auth'

import { Content, QR_CODE_SIZE, QRCodeContainer } from './styles'

enum Field {
  Code = 'code',
}

type Props = {
  factor?: MainSchema.UserTotpFactor
  loading?: boolean
  onCancel?: () => void
  onFinish?: (code: string) => void
}

// TODO: localize
function VerifyTOTPCoordinatorModalSetupContent({
  factor,
  loading,
  onCancel,
  onFinish,
}: Props) {
  const constraints = useMemo(
    () => ({
      [Field.Code]: {
        presence: true,
        length: {
          is: TOTP_LENGTH,
        },
        numericality: {
          onlyInteger: true,
        },
      },
    }),
    [],
  )

  const handleFormSubmit = useCallback(
    values => {
      if (!values[Field.Code]) return
      onFinish?.(values[Field.Code])
    },
    [onFinish],
  )

  return (
    <Content>
      <Row backgroundColor="divider.light" fullWidth height="1px" />
      <Row mt={6}>
        <QRCodeContainer>
          {!!factor?.uri && (
            <QRCodeSVG size={QR_CODE_SIZE} value={factor.uri} />
          )}
        </QRCodeContainer>
        <Form
          validate={values => validate(values, constraints)}
          onSubmit={handleFormSubmit}
        >
          {({ handleSubmit }) => (
            <Column fullWidth ml={6}>
              <Text color="text.heading" subheader2>
                Enter your verification code
              </Text>
              <InputField mt={3} name={Field.Code} type="number" />
              <Button disabled={loading} mt={5} onClick={handleSubmit}>
                Set up
              </Button>
              <Button disabled={loading} mt={3} secondary onClick={onCancel}>
                Cancel
              </Button>
            </Column>
          )}
        </Form>
      </Row>

      {factor?.secret && (
        <>
          <Row backgroundColor="divider.light" fullWidth height="1px" mt={6} />
          <Text body5 color="text.body" mt={6}>
            Trouble scanning? Enter this key in your app instead:
          </Text>
          <Text body5 color="primary500">
            {factor.secret}
          </Text>
        </>
      )}
    </Content>
  )
}

export default VerifyTOTPCoordinatorModalSetupContent
