import styled, { css } from 'styled-components'
import { color, ColorProps, space, SpaceProps } from 'styled-system'

import { themeGet } from 'Theme/v2'

export const LegendDot = styled.div<ColorProps>`
  width: 12px;
  height: 12px;
  border-radius: 999px;

  ${color}
`

export const LegendHorizontalBar = styled.div<ColorProps>`
  width: 8px;
  height: 2px;

  ${color}
`

export const LegendVerticalBar = styled.div<ColorProps>`
  width: 8px;
  height: 2px;

  ${color}
`

interface LegendContainerProps {
  vertical?: boolean
}

const legendContainerVerticalCss = ({ vertical }: LegendContainerProps) =>
  vertical &&
  css`
    flex-direction: column;
    align-items: flex-start;
    gap: ${themeGet('space.3')}px;
  `

export const LegendContainer = styled.div<LegendContainerProps & SpaceProps>`
  display: flex;
  align-items: center;
  gap: ${themeGet('space.7')}px;

  ${legendContainerVerticalCss}
  ${space}
`
