import styled from 'styled-components'

import { themeGet } from 'Theme/v2'

export const Container = styled.section`
  background-color: ${themeGet('colors.background.screen')};
  display: flex;
  min-height: 100vh;
  width: 100vw;
`
