import styled from 'styled-components'
import { layout, MinWidthProps } from 'styled-system'

import { customScrollbar } from 'Theme/styles'
import { themeGet } from 'Theme/v2'

export const PopoverBlock = styled.div<MinWidthProps>`
  margin: 0;
  padding: 0;
  filter: drop-shadow(0px 8px 14px rgba(0, 0, 0, 0.08));
  border-radius: 10px;
  font-size: 13px;
  background: ${themeGet('colors.background.block')};
  width: max-content;
  box-sizing: border-box;
  z-index: ${themeGet('zIndices.dropdown')};

  ${layout}
`

export const ContentWrapper = styled.div`
  overflow: auto;
  max-height: inherit;
  max-width: inherit;
  box-sizing: border-box;

  ${customScrollbar}
`

export const Arrow = styled.div`
  position: absolute;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
  background: ${themeGet('colors.background.block')};
`
