const move = (array, oldIndex, newIndex) => {
  const newArray = array.slice()

  if (newIndex >= array.length) {
    let k = newIndex - array.length + 1
    while (k !== 0) {
      array.push(undefined)
      k -= 1
    }
  }
  newArray.splice(newIndex, 0, newArray.splice(oldIndex, 1)[0])

  return newArray
}

export default {
  move,
}
