import { ThemeSizes } from 'Theme/types'

const sizes: ThemeSizes = {
  chat: 0,
  chat__opened: 540,
  header: 48,
  projectNavigation: 248,
  userMenu: 254,
  userMenuOrganizationPanel: 284,
  electronHeaderHeight: 26,
}

export default sizes
