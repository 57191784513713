// @ts-strict-ignore
import React, { ReactElement, useMemo } from 'react'

import { useTheme } from 'styled-components'
import { SpaceProps } from 'styled-system'

import isNull from 'lodash/isNull'
import isObject from 'lodash/isObject'

import { Column } from 'Components/UI/_v2/Flex'
import Text from 'Components/UI/_v2/Text'

import { CURRENCY } from 'Constants/currencies'
import { HIDE_NUMBERS_STRING } from 'Constants/ids'

import { _ } from 'Services/I18n'

import { themeGet } from 'Theme/v2'

import InlineCurrencyFormatter from './InlineCurrencyFormatter'
import { DelimiterBg, DelimiterFg } from './styles'

enum ValueKind {
  currencyRatioInfo = 'currencyRatioInfo',
  currencyRatioInfoPercent = 'currencyRatioInfoPercent',
  currencyRatioInfoFloat = 'currencyRatioInfoFloat',
  currencyRatioInfoPercentFloat = 'currencyRatioInfoPercentFloat',
}

type RatioValue = {
  current: number
  total?: number
}

type Props = SpaceProps & {
  alwaysHidden?: boolean
  alwaysVisible?: boolean
  large?: boolean
  currency?: string
  kind: ValueKind
  value: RatioValue | number
}

function CurrencyFormatter({
  alwaysHidden = false,
  alwaysVisible = false,
  currency = CURRENCY.USD,
  large,
  kind,
  value = null,
  ...rest
}: Props): ReactElement {
  const theme = useTheme()

  const hideNumbers = !alwaysVisible || alwaysHidden

  const isLeft = useMemo(() => {
    if (!isObject(value)) return null

    return value.current <= value.total
  }, [value])

  const ratioColor = useMemo(() => {
    if (!isObject(value)) return null

    if (hideNumbers) return 'inherit'

    return isLeft
      ? themeGet('colors.success500')({ theme })
      : themeGet('colors.danger500')({ theme })
  }, [value, hideNumbers, isLeft, theme])

  const inlineCurrencyKind = useMemo(() => {
    switch (kind) {
      case ValueKind.currencyRatioInfo:
      case ValueKind.currencyRatioInfoPercent:
        return InlineCurrencyFormatter.ValueKind.currencyInline

      case ValueKind.currencyRatioInfoFloat:
      case ValueKind.currencyRatioInfoPercentFloat:
        return InlineCurrencyFormatter.ValueKind.currencyInlineFloat

      default:
        return InlineCurrencyFormatter.ValueKind.currencyInline
    }
  }, [kind])

  const leftOverCurrencyKind = useMemo(() => {
    switch (kind) {
      case ValueKind.currencyRatioInfo:
        return InlineCurrencyFormatter.ValueKind.currencyInline

      case ValueKind.currencyRatioInfoFloat:
        return InlineCurrencyFormatter.ValueKind.currencyInlineFloat

      case ValueKind.currencyRatioInfoPercent:
        return InlineCurrencyFormatter.ValueKind.currencyInlinePercent

      case ValueKind.currencyRatioInfoPercentFloat:
        return InlineCurrencyFormatter.ValueKind.currencyInlinePercentFloat

      default:
        return InlineCurrencyFormatter.ValueKind.currencyInline
    }
  }, [kind])

  if (isNull(value)) return null

  if (hideNumbers) {
    return HIDE_NUMBERS_STRING as unknown as ReactElement
  }

  const valueIsObject = isObject(value)

  return (
    <Column squeeze {...rest}>
      {(kind === ValueKind.currencyRatioInfo ||
        kind === ValueKind.currencyRatioInfoPercent ||
        kind === ValueKind.currencyRatioInfoFloat ||
        kind === ValueKind.currencyRatioInfoPercentFloat) &&
        valueIsObject && (
          <>
            <Text body caption2={!large} header6={large}>
              {hideNumbers ? (
                HIDE_NUMBERS_STRING
              ) : (
                <InlineCurrencyFormatter
                  alwaysVisible
                  currency={currency}
                  kind={inlineCurrencyKind}
                  value={value.current}
                />
              )}
              {' / '}
              {hideNumbers ? (
                HIDE_NUMBERS_STRING
              ) : (
                <InlineCurrencyFormatter
                  alwaysVisible
                  currency={currency}
                  kind={inlineCurrencyKind}
                  value={value.total}
                />
              )}
            </Text>

            <DelimiterBg large={large} my={large ? '6px' : '2px'}>
              <DelimiterFg
                bg={ratioColor}
                large={large}
                width={`${(value.current / value.total) * 100}%`}
              />
            </DelimiterBg>

            <Text
              caption2={large}
              caption3={!large}
              color={ratioColor}
              lowercase
            >
              {hideNumbers ? (
                HIDE_NUMBERS_STRING
              ) : (
                <>
                  {isLeft ? _('left') : _('over')}
                  {': '}
                  {kind === ValueKind.currencyRatioInfoPercent ||
                  kind === ValueKind.currencyRatioInfoPercentFloat ? (
                    <InlineCurrencyFormatter
                      alwaysVisible
                      currency={currency}
                      kind={leftOverCurrencyKind}
                      value={{
                        current: Math.abs(value.total - value.current),
                        total: value.total,
                      }}
                    />
                  ) : (
                    <InlineCurrencyFormatter
                      alwaysVisible
                      currency={currency}
                      kind={leftOverCurrencyKind}
                      value={Math.abs(value.total - value.current)}
                    />
                  )}
                </>
              )}
            </Text>
          </>
        )}
    </Column>
  )
}

export default Object.assign(CurrencyFormatter, { ValueKind })
