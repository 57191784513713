import { css } from 'styled-components'

import { themeGet } from 'Theme/v2'

const BULLET_SIZE = 10

const swiper = css`
  .swiper-pagination-bullet {
    width: ${BULLET_SIZE}px;
    height: ${BULLET_SIZE}px;
    border-radius: calc(${BULLET_SIZE}px / 2);
    background: ${themeGet('colors.divider.default')};
    transition: all ${themeGet('defaultTransitionTime')};
    cursor: pointer;
  }

  .swiper-pagination-bullet-active {
    cursor: default;
    background: ${themeGet('colors.text.heading')};
  }
`

export default swiper
