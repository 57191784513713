import { ThemeShadow } from 'Theme/types'

const shadows: ThemeShadow = {
  large: '0 1px 64px -2px rgba(0, 0, 0, 0.08)',
  medium: '0 4px 44px 0 rgba(0, 0, 0, 0.04)',
  small: '0 4px 24px 0 rgba(0, 0, 0, 0.04)',
  down: {
    large: '0 16px 64px -3px rgba(0, 0, 0, 0.08)',
    medium: '0 12px 32px -2px rgba(0, 0, 0, 0.08)',
    small: '0 8px 14px -4px rgba(0, 0, 0, 0.08)',
  },
  up: {
    large: '0 -8px 64px -3px rgba(0, 0, 0, 0.08)',
    medium: '0 -8px 32px -1px rgba(0, 0, 0, 0.08)',
    small: '0 -4px 16px -2px rgba(0, 0, 0, 0.08)',
  },
}

export default shadows
