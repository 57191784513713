// @ts-strict-ignore
import React, { useCallback, useRef, useState } from 'react'
import { useHistory } from 'react-router'

import { MagnifyingGlass } from 'phosphor-react'

import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import pick from 'lodash/pick'
import values from 'lodash/values'

import { Text } from 'Components/UI/_v2'

import {
  HelpCenterSearchInputKind,
  HelpCenterSectionBySlug,
} from 'Constants/helpCenter'
import { KEYBOARD_KEYS } from 'Constants/ids'
import { HELP_CENTER_SECTION } from 'Constants/paths'

import { useHelpCenterArticles, useHelpCenterSections } from 'Hooks'

import { useScopedI18n } from 'Services/I18n'

import {
  Container,
  Dropdown,
  DropdownRow,
  StyledInput,
  StyledSpan,
} from './styles'

const MIN_SEARCH_KEY_LENGTH = 3

function getHighlightedString(string: string, substring: string) {
  if (string.toLowerCase().indexOf(substring.toLowerCase()) === -1)
    return [string]

  let cutString = string
  const regular = []
  const highlighted = []

  let substringIndex = null
  while (substringIndex !== -1) {
    substringIndex = cutString.toLowerCase().indexOf(substring.toLowerCase())

    regular.push(cutString.substring(0, substringIndex))
    cutString = cutString.substring(substringIndex)
    highlighted.push(cutString.substring(0, substring.length))
    cutString = cutString.substring(substring.length)

    substringIndex = cutString.toLowerCase().indexOf(substring.toLowerCase())
  }

  regular.push(cutString)

  return regular.map((item, index) => (
    <>
      {item}
      {highlighted.length >= index && (
        <StyledSpan>{highlighted[index]}</StyledSpan>
      )}
    </>
  ))
}

type Props = {
  sectionsBySlug?: HelpCenterSectionBySlug
  kind?: HelpCenterSearchInputKind
}

function HelpCenterSearch({
  sectionsBySlug,
  kind = HelpCenterSearchInputKind.Primary,
}: Props) {
  const s = useScopedI18n('home.helpCenter.hero')
  const history = useHistory()

  const [searchResult, setSearchResult] = useState(undefined)
  const [isSearching, setIsSearching] = useState(false)

  const helpCenterArticles = useHelpCenterArticles(sectionsBySlug)
  const helpCenterSections = useHelpCenterSections(sectionsBySlug)

  const inputRef = useRef(null)

  const handleInputKeyDown = useCallback(event => {
    if (event.key === KEYBOARD_KEYS.ESCAPE) {
      inputRef.current.blur()
    }
  }, [])

  const handleInput = useCallback(() => {
    const searchString = inputRef.current.value.trim()

    setIsSearching(true)

    if (!searchString || searchString.length < MIN_SEARCH_KEY_LENGTH) {
      setSearchResult(undefined)
      return
    }

    const filteredSections = filter(values(helpCenterSections), section => {
      const searchableText = section?.text || ''
      return searchableText.toLowerCase().includes(searchString.toLowerCase())
    }).map(section => pick(section, ['id', 'slug', 'path', 'title']))

    const filteredArticles = filter(values(helpCenterArticles), article => {
      const articleText = article?.text || ''
      const articleTitle = article?.title || ''
      const searchableText = articleText + articleTitle
      return searchableText.toLowerCase().includes(searchString.toLowerCase())
    }).map(article =>
      pick(article, ['id', 'parentSectionTitle', 'path', 'slug', 'title']),
    )

    setSearchResult({ articles: filteredArticles, sections: filteredSections })
  }, [helpCenterArticles, helpCenterSections])

  const handleInputBlur = useCallback(() => {
    setIsSearching(false)
    setSearchResult(undefined)
    inputRef.current.value = null
  }, [])

  const handleTopicSelect = useCallback(
    item => {
      history.push(HELP_CENTER_SECTION(item.path))
    },
    [history],
  )

  const isSecondary = kind === HelpCenterSearchInputKind.Secondary
  const isTertiary = kind === HelpCenterSearchInputKind.Tertiary

  return (
    <Container
      secondary={isSecondary}
      tertiary={isTertiary}
      onBlur={handleInputBlur}
    >
      <StyledInput
        placeholder={s('placeholder')}
        ref={inputRef}
        onInput={handleInput}
        onKeyDown={handleInputKeyDown}
      />

      <MagnifyingGlass size={20} />

      {isSearching && (
        <Dropdown>
          {!isEmpty(searchResult) ? (
            <>
              {searchResult.articles.map(item => (
                <DropdownRow
                  interactive
                  key={`${item.path}-${item.id}`}
                  onMouseDown={() => handleTopicSelect(item)}
                >
                  <Text caption2 heading>
                    {getHighlightedString(
                      item.title,
                      inputRef.current.value.trim(),
                    )}
                  </Text>

                  <Text caption4 muted>
                    {item.parentSectionTitle}
                  </Text>
                </DropdownRow>
              ))}
              {searchResult.sections.map(item => (
                <DropdownRow
                  interactive
                  key={`${item.path}-${item.id}`}
                  onMouseDown={() => handleTopicSelect(item)}
                >
                  <Text caption2 heading>
                    {getHighlightedString(
                      item.title,
                      inputRef.current.value.trim(),
                    )}
                  </Text>
                </DropdownRow>
              ))}
            </>
          ) : (
            searchResult && (
              <DropdownRow>
                <Text caption2 center muted>
                  {s('noOptions')}
                </Text>
              </DropdownRow>
            )
          )}
        </Dropdown>
      )}
    </Container>
  )
}

export default HelpCenterSearch
