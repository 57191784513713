import styled, { css } from 'styled-components'
import {
  position,
  PositionProps,
  space,
  SpaceProps,
  styleFn,
} from 'styled-system'

import { ComponentVariants, createGetParam, themeGet } from 'Theme/v2'

const BORDER_WIDTH = 2

const sizes = {
  size: {
    default: 16,
    small: 8,
  },
}

type SizeProps = {
  small?: boolean
}

export type CounterBadgeVariantsProps = {
  secondary?: boolean
}

type CounterBadgeVariants = ComponentVariants<CounterBadgeVariantsProps>

export type CounterBadgeParams = {
  backgroundColor: CounterBadgeVariants
}

const params: CounterBadgeParams = {
  backgroundColor: {
    default: themeGet('colors.danger500'),
    secondary: themeGet('colors.text.muted'),
  },
}

const getParam = createGetParam<CounterBadgeParams, CounterBadgeVariantsProps>(
  params,
)
const getSizeParam = createGetParam<typeof sizes, SizeProps>(sizes)

export type ContainerProps = SpaceProps &
  PositionProps &
  SizeProps &
  CounterBadgeVariantsProps & {
    active?: boolean
  }

type CircleProps = SizeProps &
  CounterBadgeVariantsProps & { bordered?: boolean; empty?: boolean }

const containerActiveCss: styleFn = ({ active }: ContainerProps) =>
  active &&
  css`
    opacity: 1;
  `

const circleBorderedCss: styleFn = ({ bordered }: CircleProps) =>
  bordered &&
  css`
    border: ${BORDER_WIDTH}px solid ${themeGet('colors.background.block')};
    min-width: calc(${getSizeParam('size')}px + ${BORDER_WIDTH * 2}px);
    height: calc(${getSizeParam('size')}px + ${BORDER_WIDTH * 2}px);
    padding: 0 4px;
  `

const circleEmptyCss: styleFn = ({ empty }: CircleProps) =>
  empty &&
  css`
    min-width: ${getSizeParam('size')}px;
    height: ${getSizeParam('size')}px;
  `

export const Circle = styled.div<CircleProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  font-size: ${themeGet('fontSizes.0')}px;
  color: ${themeGet('colors.white')};
  flex-shrink: 0;
  padding: 0 4px;
  font-weight: 500;
  font-feature-settings: 'tnum' on, 'lnum' on;

  min-width: ${getSizeParam('size')}px;
  height: ${getSizeParam('size')}px;
  background-color: ${getParam('backgroundColor')};

  ${circleBorderedCss}
  ${circleEmptyCss}
`

export const Container = styled.div<ContainerProps>`
  opacity: 0;
  ${position};
  ${space};

  ${containerActiveCss};
`
