export const ROOT = '/'

export const LOGOUT = `${ROOT}logout`

export const LEGAL = `${ROOT}legal`
export const PRIVACY_POLICY = `${LEGAL}/privacy-policy`
export const CLOUD_TERMS_OF_SERVICE = `${LEGAL}/cloud-terms-of-service`
export const SOFTWARE_LICENSE_AGREEMENT = `${LEGAL}/software-license-agreement`
export const THIRD_PARTY_CODE = `${LEGAL}/third-party-code`
export const GOVERNMENT_AMENDMENT = `${LEGAL}/government-amendment`
export const USER_NOTICE = `${LEGAL}/user-notice`
export const COOKIES_NOTICE = `${LEGAL}/cookies-notice`
export const PRODUCT_FAMILY = `${LEGAL}/product-family`
export const ACCEPTABLE_USE_POLICY = `${LEGAL}/acceptable-use-policy`
export const COPYRIGHT_AND_TRADEMARK_VIOLATION = `${LEGAL}/copyright-and-trademark-violation`
export const DATA_TRANSFER_IMPACT_ASSESSMENT = `${LEGAL}/data-transfer-impact-assessment`
export const IMPRESSUM = `${LEGAL}/impressum`
export const OTHER_LEGAL = `${LEGAL}/other-legal`
export const RESELLER_AGREEMENT = `${LEGAL}/reseller-agreement`
export const SUBPROCESSORS = `${LEGAL}/subprocessors`
export const DATA_PROCESSING_APPENDUM = `${LEGAL}/data-processing-appendum`
export const LEGAL_GDPR = `${LEGAL}/gdpr`
export const SECURITY_STATEMENT = `${LEGAL}/security-statement`
export const ARTIFICIAL_INTELLIGENCE = `${LEGAL}/artificial-intelligence`

export const HOME = `${ROOT}`
export const FEATURES = `${ROOT}features`
export const USE_CASES = `${ROOT}use-cases`
export const PRICING = `${ROOT}pricing`
export const AFFILIATE = `${ROOT}affiliate`
export const HELP_CENTER = `${ROOT}help-center`
export const BECOME_PARTNER = `${ROOT}become-partner`
export const SUBMIT_REFERRAL = `${ROOT}submit-referral`
export const HELP_CENTER_SECTION = (slug?: string) =>
  `${HELP_CENTER}/${slug || ':section*'}`
export const FEATURE = (slug?: string) => `${FEATURES}/${slug || ':feature*'}`
export const USE_CASE = (slug?: string) => `${USE_CASES}/${slug || ':useCase*'}`

export const CHAT_SDK = `${ROOT}chat-sdk`
export const CHAT_SDK_COMMUNITY = `${CHAT_SDK}/community`

export const API = `${ROOT}api`

export const AUTH = `${ROOT}auth`

export const SIGN_UP = `${AUTH}/sign-up`
export const LOGIN = `${AUTH}/login`
export const VERIFY = `${AUTH}/verify`
export const VERIFY_EMAIL = `${AUTH}/verify-email`
export const FORGOT_PASSWORD = `${AUTH}/forgot-password`
export const RESET_PASSWORD = `${AUTH}/reset-password`
export const PHONE_VERIFICATION = `${AUTH}/phone-verification`
export const TOTP_VERIFICATION = `${AUTH}/totp-verification`
export const CREATE_PASSWORD = `${AUTH}/create-password`

export const REQUEST_DEMO = `${ROOT}request-demo`

export const APP = `${ROOT}app`

export const SITEMAP = `${ROOT}sitemap`
