import React, { ReactNode, useCallback, useState } from 'react'
import Collapse from 'react-css-collapse'

import { LinkButton } from 'Components/UI/_v2/Buttons'
import { Row } from 'Components/UI/_v2/Flex'

import {
  Chevron,
  Container,
  ContainerProps,
  ContainerVariantProps,
  Content,
} from './styles'

type Props = ContainerProps &
  ContainerVariantProps & {
    disabled?: boolean
    children?: ReactNode
    title?: ReactNode
    icons?: ReactNode
    isInitialClose?: boolean
  }

function Accordion({
  children,
  title,
  icons,
  isInitialClose,
  large,
  small,
  disabled,
  ...rest
}: Props) {
  const [open, setOpen] = useState(!isInitialClose)

  const handleToggle = useCallback(() => {
    setOpen(prevOpen => !prevOpen)
  }, [])

  return (
    <Container {...rest} large={large} small={small}>
      <Row center gap="8px">
        <LinkButton
          disabled={disabled}
          large={large}
          mr="auto"
          secondary
          small={small}
          onClick={handleToggle}
        >
          <Chevron open={open} />

          {title}
        </LinkButton>
        {icons}
      </Row>
      <Collapse isOpen={open}>
        <Content>{children}</Content>
      </Collapse>
    </Container>
  )
}

export default Accordion
