import styled from 'styled-components'
import { margin, MarginProps } from 'styled-system'

import { X } from 'phosphor-react'

import Text from 'Components/UI/_v2/Text'

import { ComponentVariants, createGetParam, themeGet } from 'Theme/v2'

type ToastVariants = ComponentVariants<ToastVariantsProps>

export type ToastVariantsProps = {
  success?: boolean
  warning?: boolean
  danger?: boolean
  info?: boolean
}

export type ToastParams = {
  color: ToastVariants
  borderColor: ToastVariants
}

const params: ToastParams = {
  color: {
    default: themeGet('colors.primary500'),
    success: themeGet('colors.success400'),
    warning: themeGet('colors.warning500'),
    danger: themeGet('colors.danger500'),
    info: themeGet('colors.info500'),
  },
  borderColor: {
    default: themeGet('colors.divider.default'),
    success: themeGet('colors.success900'),
    warning: themeGet('colors.warning800'),
    danger: themeGet('colors.danger900'),
    info: themeGet('colors.info800'),
  },
}

const getParam = createGetParam<ToastParams, ToastVariantsProps>(params)

export type ContainerProps = ToastVariantsProps & MarginProps
export const IconContainer = styled.div`
  display: flex;
  flex-direction: column;

  > svg {
    width: 20px;
    height: 20px;
  }

  svg * {
    stroke-width: 24;
  }
`

export const Footer = styled.div<MarginProps>`
  display: flex;
  width: 100%;
  flex-direction: row;
  ${margin};
`

export const Container = styled.div<ContainerProps>`
  padding: 16px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  background-color: ${themeGet('colors.background.block')};
  border-radius: 10px;
  border: 1px solid ${getParam('borderColor')};
  gap: ${themeGet('space.5')}px;

  ${margin};

  &__progress-bar {
    background: ${getParam('color')};
  }

  ${Footer} {
    color: ${getParam('color')};
    button {
      color: ${getParam('color')};
    }
  }

  ${IconContainer} {
    color: ${getParam('color')};
  }
`

export const StyledText = styled(Text).attrs({
  body5: true,
  body: true,
})`
  * {
    font-size: 12px;
  }
`

export const CloseButtonStyled = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  box-shadow: unset;
  background: transparent;
  margin: 0;
  padding: 0;
  border: 0;
`
export const CloseIcon = styled(X).attrs({
  size: 16,
  weight: 'bold',
})`
  color: ${themeGet('colors.text.muted')};
  flex-shrink: 0;
`
