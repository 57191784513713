import React, { ReactNode } from 'react'

import map from 'lodash/map'

import {
  Container,
  ContainerProps,
  Segment,
  SizeProps,
  Wrapper,
} from './styles'

type Props = ContainerProps &
  SizeProps & {
    active?: number | string
    segments?: string[]
    withSeparator?: boolean
    onChange?: (index: number, segment: string) => void
    renderLabel?: (segment: string) => ReactNode
  }

function SegmentedControl({
  active = 0,
  fullWidth,
  onChange,
  renderLabel,
  segments,
  ...rest
}: Props) {
  return (
    <Container {...rest}>
      <Wrapper fullWidth={fullWidth}>
        {map(segments, (segment, index) => (
          <Segment
            active={active === index || active === segment}
            key={segment}
            large={rest.large}
            small={rest.small}
            onClick={() => onChange?.(index, segment)}
          >
            {renderLabel ? renderLabel(segment) : segment}
          </Segment>
        ))}
      </Wrapper>
    </Container>
  )
}

export default SegmentedControl
