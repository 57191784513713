import React from 'react'
import { Helmet } from 'react-helmet'

import { Modal } from 'Components/UI/_v2'

import { EXTERNAL_LINKS } from 'Constants/ids'

import { useScopedI18n } from 'Services/I18n'

import { Content } from './styles'

type Props = {
  isOpen?: boolean
  onClose?: () => void
}

function CalendlyWidgetModal({ isOpen, onClose }: Props) {
  const s = useScopedI18n('blocks.modals.requestDemo')

  return (
    <Modal isOpen={isOpen} title={s('title')} onClose={onClose}>
      <Helmet>
        <script
          async
          src={EXTERNAL_LINKS.CALENDLY.WIDGET}
          type="text/javascript"
        />
      </Helmet>
      <Content
        className="calendly-inline-widget"
        data-url={EXTERNAL_LINKS.CALENDLY.URL}
      />
    </Modal>
  )
}

export default CalendlyWidgetModal
