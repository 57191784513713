import { Link } from 'react-router-dom'

import styled, { css } from 'styled-components'
import {
  display,
  flexbox,
  layout,
  margin,
  MarginProps,
  padding,
  space,
} from 'styled-system'

import {
  LANDING_MAX_CONTENT_WIDTH,
  LANDING_PAGE_PADDING_X,
} from 'Constants/landing'

import { themeGet } from 'Theme/v2'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${themeGet('colors.background.block')};
  width: 100%;
  overflow-x: hidden;
`

export const ContentWrapper = styled.div.attrs({
  px: LANDING_PAGE_PADDING_X,
})`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;

  ${padding}
`

export const ContentTop = styled.div.attrs({
  flexDirection: ['column', 'column', 'column', 'row'],
  pt: [56, 80, 80, 120],
  pb: [56, 80, 80, 128],
})`
  max-width: ${LANDING_MAX_CONTENT_WIDTH}px;
  width: 100%;
  display: flex;
  border-bottom: 1px solid ${themeGet('colors.divider.contrast')};
  justify-content: space-between;

  ${flexbox}
  ${padding}
`

export const ContentBottom = styled.div.attrs({
  flexDirection: ['column', 'column', 'row'],
  justifyContent: ['center', 'center', 'space-between'],
  py: [32, 32, 56],
})`
  max-width: ${LANDING_MAX_CONTENT_WIDTH}px;
  display: flex;
  width: 100%;

  ${flexbox}
  ${padding}
`

export const DownloadLinksContainer = styled.div.attrs({
  flexDirection: ['row', 'row', 'row', 'column'],
  justifyContent: ['center', 'flex-start'],
})`
  display: flex;
  flex-shrink: 0;
  gap: ${themeGet('space.5')}px;

  ${flexbox}
`

export const DownloadLinkHolder = styled.a.attrs({
  width: [150, 158, 160],
})`
  height: 48px;
  cursor: pointer;
  border: none;
  background: none;
  padding: 0;
  z-index: 1;

  > svg {
    width: 100%;
    height: 100%;
  }

  ${layout}
`
export const CustomLink = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  color: ${themeGet('colors.primary500')};
`

export const Delimiter = styled.div`
  height: 20px;
  width: 1px;
  background-color: ${themeGet('colors.divider.default')};
`

export const WordHolder = styled.div<MarginProps>`
  display: flex;

  > svg {
    width: 90px;
    height: 16px;
  }

  ${margin}
  ${display}
`

const navItemCss = css`
  display: flex;
  text-align: center;
  color: ${themeGet('colors.text.important')};
  font-size: ${themeGet('fontSizes.1')}px;
  font-weight: ${themeGet('fontWeights.0')};
  line-height: 170%;
  text-decoration: none;
  cursor: pointer;
  user-select: none;

  &:hover {
    color: ${themeGet('colors.primary500')};
  }

  ${margin};
  ${space};
`

export const StyledNavLink = styled(Link)`
  ${navItemCss}
`

export const StyledNavButton = styled.button`
  border: none;
  background: none;
  padding-inline: 0;
  ${navItemCss}
`

export const NavMenu = styled.div.attrs({
  flexDirection: ['column', 'column', 'row'],
  pl: [0, 0, 3],
  pr: [0, 0, 0, 6],
  py: [11, 11, 0],
})`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  > div:last-child {
    margin: 0;
  }

  ${padding}
  ${flexbox};
`

export const ViewMobile = styled.div.attrs({
  display: ['block', 'block', 'none'],
})`
  ${layout}
`

export const ViewTablet = styled.div.attrs({
  display: ['none', 'none', 'block'],
})`
  ${layout}
`
