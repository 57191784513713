import styled from 'styled-components'

import { MagnifyingGlass } from 'phosphor-react'

import { themeGet } from 'Theme/v2'

export const SearchIcon = styled(MagnifyingGlass).attrs({
  weight: 'bold',
  size: 16,
})`
  color: ${themeGet('colors.text.body')};
`
