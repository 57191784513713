import React, { ReactNode } from 'react'

import { CheckCircle } from 'phosphor-react'

import { Row, Text } from 'Components/UI/_v2'

import { CheckIconHolder } from '../styles'

type Props = {
  check?: boolean
  label?: ReactNode
}

function PasswordCheck({ check, label }: Props) {
  return (
    <Row center gap={3}>
      <CheckIconHolder check={check}>
        <CheckCircle size={20} />
      </CheckIconHolder>
      <Text body caption2>
        {label}
      </Text>
    </Row>
  )
}

export default PasswordCheck
