import React, { ReactNode, useCallback } from 'react'
import { Form } from 'react-final-form'

import isEmpty from 'lodash/isEmpty'
import isFunction from 'lodash/isFunction'

import { Column, Text } from 'Components/UI/_v2'
import { InputField } from 'Components/UI/_v2/Forms'

import { useScopedI18n } from 'Services/I18n'

import PasswordCheck from './Components/PasswordCheck'
import PasswordConstraints from './PasswordConstraints'

enum Fields {
  Password = 'password',
  ConfirmPassword = 'confirmPassword',
}

interface ChildFunctionProps {
  submitting: boolean
}

type ChildFunction = (props: ChildFunctionProps) => ReactNode

type Props = {
  children?: ReactNode | ChildFunction
  onSubmit: (values: any) => void
}

function PasswordForm({ children, onSubmit }: Props) {
  const s = useScopedI18n('passwordForm')

  const validateForm = useCallback(
    (values: Record<string, string>) => {
      const password = values[Fields.Password]

      if (isEmpty(password)) {
        return {
          [Fields.Password]: s('validations.passwordRequired'),
        }
      }

      if (!PasswordConstraints.Length.check(password)) {
        return {
          [Fields.Password]: s('validations.passwordLength', {
            minLength: PasswordConstraints.Length.MIN_LENGTH,
            maxLength: PasswordConstraints.Length.MAX_LENGTH,
          }),
        }
      }

      if (!PasswordConstraints.UpperCaseLetter.check(password)) {
        return {
          [Fields.Password]: s('validations.passwordUppercaseLetter'),
        }
      }

      if (!PasswordConstraints.LowerCaseLetter.check(password)) {
        return {
          [Fields.Password]: s('validations.passwordLowercaseLetter'),
        }
      }

      if (!PasswordConstraints.OneNumber.check(password)) {
        return {
          [Fields.Password]: s('validations.passwordOneNumber'),
        }
      }

      if (!PasswordConstraints.SpecialCharacters.check(password)) {
        return {
          [Fields.Password]: s('validations.passwordSpecialCharacters', {
            specialCharacters:
              PasswordConstraints.SpecialCharacters.SPECIAL_CHARACTERS,
          }),
        }
      }

      const confirmPassword = values[Fields.ConfirmPassword]

      if (isEmpty(confirmPassword)) {
        return {
          [Fields.ConfirmPassword]: s('validations.passwordRequired'),
        }
      }

      if (password !== confirmPassword) {
        return {
          [Fields.ConfirmPassword]: s('validations.passwordDontMatch'),
        }
      }

      return undefined
    },
    [s],
  )

  return (
    <Form
      render={({ handleSubmit, values, submitting }) => (
        <form onSubmit={handleSubmit}>
          <Column gap={7}>
            <Text caption2 muted>
              {s('descriptions.chooseSecurePasswordBy')}
            </Text>

            <Column gap={3}>
              <PasswordCheck
                check={PasswordConstraints.Length.check(
                  values[Fields.Password],
                )}
                label={s('descriptions.passwordLengthConstraints', {
                  minLength: PasswordConstraints.Length.MIN_LENGTH,
                  maxLength: PasswordConstraints.Length.MAX_LENGTH,
                })}
              />
              <PasswordCheck
                check={PasswordConstraints.UpperCaseLetter.check(
                  values[Fields.Password],
                )}
                label={s('descriptions.passwordOneUpperCharacter')}
              />
              <PasswordCheck
                check={PasswordConstraints.LowerCaseLetter.check(
                  values[Fields.Password],
                )}
                label={s('descriptions.passwordOneLowerCharacter')}
              />
              <PasswordCheck
                check={PasswordConstraints.OneNumber.check(
                  values[Fields.Password],
                )}
                label={s('descriptions.passwordOneNumber')}
              />
              <PasswordCheck
                check={PasswordConstraints.SpecialCharacters.check(
                  values[Fields.Password],
                )}
                label={s('descriptions.passwordSpecialCharacter', {
                  specialCharacters:
                    PasswordConstraints.SpecialCharacters.SPECIAL_CHARACTERS,
                })}
              />
            </Column>

            <InputField
              disabled={submitting}
              label={s('fields.password')}
              name={Fields.Password}
              placeholder={s('fields.passwordPlaceholder')}
              type="password"
            />

            <InputField
              disabled={submitting}
              label={s('fields.confirmPassword')}
              name={Fields.ConfirmPassword}
              placeholder={s('fields.passwordPlaceholder')}
              type="password"
            />

            {isFunction(children) ? children({ submitting }) : children}
          </Column>
        </form>
      )}
      validate={validateForm}
      onSubmit={onSubmit}
    />
  )
}

export default Object.assign(PasswordForm, { Fields })
