import React, { useMemo } from 'react'

import {
  logoWorkLairNarrowBwGlyph,
  logoWorkLairNarrowColorGlyph,
  logoWorkLairWideBwGlyph,
  logoWorkLairWideColorGlyph,
} from 'Assets/Svg'

import { Icon } from 'Components/UI/_v2'

const BASE_SIZE = {
  HEIGHT: 24,
  WIDTH_WIDE: 90,
  WIDTH_NARROW: 24,
}

export enum ApplicationLogoKind {
  WideBlackWhite = 'wideBlackWhite',
  WideColor = 'wideColor',
  NarrowBlackWhite = 'narrowBlackWhite',
  NarrowColor = 'narrowColor',
}

type Props = {
  height?: number
  kind?: ApplicationLogoKind
  size?: number
  width?: number
}

function ApplicationLogo({ height, kind, size, width }: Props) {
  return useMemo(() => {
    switch (kind) {
      case ApplicationLogoKind.WideColor:
        return (
          <Icon
            alt="WorkLair logo"
            glyph={logoWorkLairWideColorGlyph}
            height={height || BASE_SIZE.HEIGHT}
            width={width || BASE_SIZE.WIDTH_WIDE}
          />
        )
      case ApplicationLogoKind.NarrowColor:
        return (
          <Icon
            alt="WorkLair logo"
            glyph={logoWorkLairNarrowColorGlyph}
            size={size || BASE_SIZE.HEIGHT}
          />
        )
      case ApplicationLogoKind.WideBlackWhite:
        return (
          <Icon
            alt="WorkLair logo"
            glyph={logoWorkLairWideBwGlyph}
            height={height || BASE_SIZE.HEIGHT}
            width={width || BASE_SIZE.WIDTH_WIDE}
          />
        )
      case ApplicationLogoKind.NarrowBlackWhite:
      default:
        return (
          <Icon
            alt="WorkLair logo"
            glyph={logoWorkLairNarrowBwGlyph}
            size={size || BASE_SIZE.HEIGHT}
          />
        )
    }
  }, [height, kind, size, width])
}

export default ApplicationLogo
