import React, { useCallback, useEffect, useState } from 'react'
import Collapse from 'react-css-collapse'

import { LinkButton } from 'Components/UI/_v2/Buttons'
import { Row } from 'Components/UI/_v2/Flex'

import { Chevron, Container, ContainerProps, Content } from './styles'

type Props = ContainerProps & {
  large?: boolean
  small?: boolean
  disabled?: boolean
  isOpen?: boolean
  children: React.ReactNode | React.ReactNode[]
  title: React.ReactNode
  icons?: React.ReactNode
  onToggle?: (isOpen: boolean) => void
}

function SideBarAccordion({
  children,
  title,
  icons,
  large,
  small,
  isOpen = false,
  disabled,
  onToggle,
  ...rest
}: Props) {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    // Need this check for correct rendering with opened state
    if (React.Children.count(children)) {
      setOpen(isOpen)
    }
  }, [isOpen, children])

  useEffect(() => {
    if (disabled) setOpen(false)
  }, [disabled])

  const handleToggle = useCallback(() => {
    setOpen(!open)
    onToggle?.(!open)
  }, [open, onToggle])

  return (
    <Container {...rest}>
      <Row
        alignItems="center"
        className="sidebar-accordion__header"
        gap={3}
        my={3}
      >
        <LinkButton
          disabled={disabled}
          large={large}
          mr="auto"
          secondary
          small={small}
          onClick={handleToggle}
        >
          <Chevron open={open} />
          {title}
        </LinkButton>
        {icons}
      </Row>
      <Collapse isOpen={open}>
        <Content large={large} small={small}>
          {children}
        </Content>
      </Collapse>
    </Container>
  )
}

export default SideBarAccordion
