// @ts-strict-ignore
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { renderRoutes } from 'react-router-config'
import { ToastContainer } from 'react-toastify'

import { useApolloClient, useQuery } from '@apollo/client'
import isElectron from 'is-electron'
import { useStoreon } from 'storeon/react'
import validate from 'validate.js'

import forEach from 'lodash/forEach'
import isFunction from 'lodash/isFunction'

import { LocationTracker } from 'Components/Blocks'
import Toast from 'Components/UI/_v2/Toast'

import { IS_STAGING, LINKMINK } from 'Config/app'
import routes from 'Config/Routes'
import validators from 'Config/validators'

import { APP_NAME, LOCAL_STORAGE } from 'Constants/ids'
import { AUTH_STATE, I18N_STATE } from 'Constants/store'

import meQuery from 'Graphql/Queries/me.graphql'

import { ShowConfirmModalParams } from 'Interfaces/Modals'

import { AppContext } from 'Services/AppContext'
import { useInitializeRewardful } from 'Services/Rewardful'
import { useInitializeTidio } from 'Services/Tidio'
import Utils from 'Services/Utils'

import { GlobalStyle, ThemeProvider } from 'Theme/v3'

import ConfirmModalPresenter from './ConfirmModalPresenter'
import ElectronBar from './ElectronBar'
import Fonts from './Fonts'

forEach(validators, validator => {
  validate.validators[validator.key] = validator.value
})

function LocaleRenderer({ children }: { children: JSX.Element }) {
  return children
}

function App() {
  const client = useApolloClient()
  const { [I18N_STATE]: i18n, [AUTH_STATE]: auth } = useStoreon(
    I18N_STATE,
    AUTH_STATE,
  )

  const electron = useMemo(isElectron, [])

  const rewardful = useInitializeRewardful()

  useInitializeTidio()

  const [showConfirmModal, setShowConfirmModal] =
    useState<(params: ShowConfirmModalParams) => Promise<boolean>>()

  const locale = i18n?.locale

  const { loading, data } = useQuery<
    MainQueryData<'me'>,
    MainSchema.UserOrganizationRoleArgs & MainSchema.UserProjectRoleArgs
  >(meQuery, {
    skip: !auth?.accessToken,
    notifyOnNetworkStatusChange: true,
  })

  const me = data?.me || null

  // Global Setup
  useEffect(() => {
    if (isFunction(window.LinkMink)) {
      const linkMinkService = window.LinkMink(LINKMINK.KEY)

      linkMinkService.initTracking().then(() => {
        Utils.LocalStorage.safeSetItem(
          LOCAL_STORAGE.REFERRAL_DATA,
          linkMinkService.getReferralData(),
        )
      })
    }
  }, [])

  const contextValue = useMemo(() => {
    return {
      client,
      me,
      showConfirmModal,
      electron,
    }
  }, [me, showConfirmModal, client, electron])

  const renderToastCloseButton = useCallback(
    ({ closeToast }) => <Toast.CloseButton onClose={closeToast} />,
    [],
  )

  if (!rewardful.loaded || loading) {
    return null
  }

  return (
    <ThemeProvider>
      <AppContext.Provider value={contextValue}>
        <GlobalStyle />
        <LocationTracker />
        <Fonts />

        <Helmet script={[rewardful.script]}>
          <title>{APP_NAME}</title>
          <link
            href="https://unpkg.com/react-day-picker@7.4.8/lib/style.css"
            rel="stylesheet"
          />
          <meta
            content="Elevate your productivity and workflow with Worklair.io's cutting-edge solutions. Optimize your projects effortlessly and explore the future of work management!"
            name="description"
          />
          {IS_STAGING && <meta content="noindex" name="robots" />}
        </Helmet>

        {electron && <ElectronBar />}

        <LocaleRenderer key={locale}>{renderRoutes(routes())}</LocaleRenderer>

        <ToastContainer
          className="toast-container_v2"
          closeButton={renderToastCloseButton}
          closeOnClick={false}
          draggable
          hideProgressBar={false}
          newestOnTop={false}
          pauseOnFocusLoss
          pauseOnHover
          position="top-right"
          rtl={false}
        />

        <ConfirmModalPresenter
          onSetShow={show => setShowConfirmModal(() => show)}
        />
      </AppContext.Provider>
    </ThemeProvider>
  )
}

export default App
