import styled, { css } from 'styled-components'
import {
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  padding,
  PaddingProps,
} from 'styled-system'

import { themeGet } from 'Theme/v2'

type ContainerProps = {
  electron?: boolean
}
const electronCss = ({ electron }: ContainerProps) =>
  electron &&
  css`
    padding-top: ${themeGet('sizes.electronHeaderHeight')}px;
  `

export const Container = styled.section.attrs({
  flexDirection: ['column', 'column', 'column', 'row'],
})<ContainerProps>`
  position: relative;
  display: flex;
  align-items: center;
  min-height: 100dvh;
  width: 100%;
  background-color: ${themeGet('colors.background.block')};
  transition: all ${themeGet('defaultTransitionTime')};

  ${flexbox}

  ${electronCss}
`

export const Content = styled.div.attrs({
  width: ['100%', '100%', '100%', '50%'],
  height: ['auto', 'auto', 'auto', '100dvh'],
  pb: [7, 7, 7, 0],
})<FlexboxProps & LayoutProps & PaddingProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  flex-shrink: 0;

  ${flexbox}
  ${layout}
  ${padding}
`

export const FormHolder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`
