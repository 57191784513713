import palette from '../palette'

export default {
  ...palette,

  transparent: 'transparent',
  bg: '#ffffff',
  primary: '#000000',
  secondary: '#999999',

  background: {
    screen: palette.neutral900,
    block: palette.white,
    subblock: palette.neutral800,
  },

  divider: {
    default: palette.neutral700,
    darker: palette.neutral600,
    light: palette.neutral800,
    lighter: palette.neutral700,
    contrast: palette.neutral600,
  },

  text: {
    heading: palette.neutral100,
    important: palette.neutral300,
    body: palette.neutral400,
    muted: palette.neutral500,
    inverse: palette.white,
  },

  gradient: {
    animated: {
      default: `linear-gradient(to right, ${palette.neutral900} 8%, ${palette.neutral800} 18%, ${palette.neutral900} 33%)`,
      invert: `linear-gradient(to right, ${palette.neutral800} 8%, ${palette.neutral900} 18%, ${palette.neutral800} 33%)`,
    },
  },

  slantedLinesBg: {
    bg: palette.neutral900,
    line: palette.neutral700,
  },

  scrollbar: {
    track: palette.athensGray,
    thumb: palette.neutral600,
  },
}
