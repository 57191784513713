// @ts-strict-ignore
import React, {
  BaseSyntheticEvent,
  useCallback,
  useMemo,
  useState,
} from 'react'

import { CaretDoubleDown, CreditCard } from 'phosphor-react'

import isEmpty from 'lodash/isEmpty'

import Popover from 'Components/UI/_v2/Popover'
import Text from 'Components/UI/_v2/Text'

import {
  Caption,
  Container,
  DangerIcon,
  DropdownButton,
  DropdownContainer,
  DropdownRow,
  InfoIcon,
  Label,
  Separator,
  StyledMaskedInput,
  SuccessIcon,
  Wrapper,
  WrapperProps,
} from './styles'

type CardData = { number?: string; expired?: string; cvv?: string }

type Props = WrapperProps & {
  cardData?: CardData
  existingCards?: CardData[]
  disabled?: boolean
  label?: string
  caption?: string
  onChange?: (data: CardData) => void
}

const INPUT_MASKS = {
  NUMBER: [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
  EXPIRED: [/[0-1]/, /\d/, '/', /[2-9]/, /\d/],
  CVV: [/\d/, /\d/, /\d/],
}

function Index({
  caption,
  cardData,
  danger,
  disabled,
  existingCards,
  label,
  large,
  small,
  success,
  onChange,
  ...rest
}: Props) {
  const [isActive, setIsActive] = useState(false)
  const [isUnfolded, setIsUnfolded] = useState(false)
  const [innerCardData, setInnerCardData] = useState(cardData)

  const captionElement = useMemo(() => {
    if (!caption) return null

    if (success) {
      return (
        <Caption mt={2} success>
          <SuccessIcon mr={2} />
          {caption}
        </Caption>
      )
    }

    if (danger) {
      return (
        <Caption danger mt={2}>
          <DangerIcon mr={2} />
          {caption}
        </Caption>
      )
    }

    return (
      <Caption mt={2}>
        <InfoIcon mr={2} />
        {caption}
      </Caption>
    )
  }, [caption, success, danger])

  const handleExistingCardClick = useCallback((data: CardData) => {
    setInnerCardData(data)
    setIsUnfolded(false)
  }, [])

  const handleNumberChange = useCallback(
    (event: BaseSyntheticEvent) => {
      const { value } = event.target
      onChange?.({ ...cardData, number: value })
    },
    [cardData, onChange],
  )

  const handleExpiredChange = useCallback(
    (event: BaseSyntheticEvent) => {
      const { value } = event.target
      onChange?.({ ...cardData, expired: value })
    },
    [cardData, onChange],
  )

  const handleCvvChange = useCallback(
    (event: BaseSyntheticEvent) => {
      const { value } = event.target
      onChange?.({ ...cardData, cvv: value })
    },
    [cardData, onChange],
  )

  return (
    <Wrapper {...rest} large={large} small={small}>
      {label && <Label mb={2}>{label}</Label>}

      <Container
        active={isActive}
        danger={danger}
        disabled={disabled}
        large={large}
        small={small}
        success={success}
      >
        <CreditCard />

        <StyledMaskedInput
          disabled={disabled}
          mask={INPUT_MASKS.NUMBER}
          ml={4}
          placeholder="XXXX-XXXX-XXXX-XXXX"
          value={innerCardData?.number}
          onBlur={() => setIsActive(false)}
          onChange={handleNumberChange}
          onFocus={() => setIsActive(true)}
        />

        <Separator mx={4} />

        <StyledMaskedInput
          disabled={disabled}
          expired
          mask={INPUT_MASKS.EXPIRED}
          placeholder="MM/YY"
          value={innerCardData?.expired}
          onBlur={() => setIsActive(false)}
          onChange={handleExpiredChange}
          onFocus={() => setIsActive(true)}
        />

        <Separator mx={4} />

        <StyledMaskedInput
          cvv
          disabled={disabled}
          mask={INPUT_MASKS.CVV}
          placeholder="CVV"
          value={innerCardData?.cvv}
          onBlur={() => setIsActive(false)}
          onChange={handleCvvChange}
          onFocus={() => setIsActive(true)}
        />

        {!isEmpty(existingCards) && (
          <>
            <Separator mx={4} />

            <Popover
              content={
                <DropdownContainer>
                  {existingCards.map(card => (
                    <DropdownRow
                      key={card.number}
                      onClick={() => handleExistingCardClick(card)}
                    >
                      <Text body body1 mr={6}>
                        {card.number}
                      </Text>
                      <Text body body1 heading>
                        {card.expired}
                      </Text>
                    </DropdownRow>
                  ))}
                </DropdownContainer>
              }
              interactive
              offset={[0, 20]}
              placement="bottom-end"
              trigger="click"
              visible={isUnfolded}
              zIndex={9999}
              onClickOutside={() => setIsUnfolded(false)}
            >
              <DropdownButton
                up={isUnfolded}
                onClick={() => setIsUnfolded(!isUnfolded)}
              >
                <CaretDoubleDown size={16} />
              </DropdownButton>
            </Popover>
          </>
        )}
      </Container>

      {captionElement}
    </Wrapper>
  )
}

export default Index
