import React from 'react'
import { components, MultiValueRemoveProps } from 'react-select'

import { FiX } from 'react-icons/fi'

function MultiValueRemove(props: MultiValueRemoveProps) {
  return (
    <components.MultiValueRemove {...props}>
      <FiX />
    </components.MultiValueRemove>
  )
}

export default MultiValueRemove
