import { Link } from 'react-router-dom'

import styled, { css } from 'styled-components'
import { layout, LayoutProps } from 'styled-system'

import { themeGet } from 'Theme/v2'

type TabActiveProps = {
  active: boolean
}

export const StyledForm = styled.form.attrs({
  width: [340, 360],
})<LayoutProps>`
  display: flex;
  flex-direction: column;

  ${layout}
`

const tabActiveCss = ({ active }: TabActiveProps) =>
  active &&
  css`
    color: ${themeGet('colors.primary500')};
    border-bottom: 2px solid ${themeGet('colors.primary500')};
  `

export const Tab = styled.div<TabActiveProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: content-box;
  width: 100%;
  height: 52px;
  border-bottom: 1px solid ${themeGet('colors.divider.default')};

  ${tabActiveCss}
`

export const StyledLink = styled(Link)`
  color: ${themeGet('colors.primary500')};
  text-decoration: none;
`
