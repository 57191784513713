function downloadFile(file) {
  if (typeof document === 'undefined') return

  const link = document.createElement('a')
  link.href = file.url
  link.target = '_blank'
  link.download = file.name || 'attachment'
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export default {
  downloadFile,
}
