import React, { ComponentProps, FC } from 'react'
import { Field } from 'react-final-form'

import InputRange from './InputRange'

type Props = ComponentProps<typeof InputRange> & {
  name: string
}

const InputRangeField: FC<Props> = ({ name, ...rest }) => (
  <Field name={name}>
    {({ input }) => <InputRange {...input} {...rest} />}
  </Field>
)

export default InputRangeField
