import { BaseColors } from 'Theme/types'

export const breakpoints = [32, 48, 64, 80]

//                    0  1  2  3  4   5   6   7   8   9   10  11  12  13
export const space = [0, 2, 4, 8, 12, 16, 20, 24, 28, 32, 36, 40, 44, 48]
//                        0   1   2   3   4   5   6   7   8   9
export const fontSizes = [12, 14, 16, 20, 24, 32, 48, 64, 72, 96]

export const weights = [400, 700]

// todo: flatten

export const colors: BaseColors = {
  black: '#000',
  white: '#fff',
}

export const radius = 4
export const font = `-apple-system, BlinkMacSystemFont, sans-serif`
export const monospace = '"SF Mono", Menlo, monospace'

const DefaultTheme = {
  name: 'default',
  breakpoints,
  space,
  fontSizes,
  weights,
  font,
  monospace,
  colors,
  radius,
}

export default DefaultTheme
