import { ComponentProps } from 'react'

import styled, { css } from 'styled-components'
import { margin, MarginProps } from 'styled-system'

import { themeGet } from 'Theme/v2'

import Adapter from './Adapter'

export const Title = styled.div`
  display: flex;
  align-items: center;
  font-weight: ${themeGet('fontWeights.2')};
  font-size: 18px;
  margin-right: auto;
  color: ${themeGet('colors.text.heading')};
`

export interface StyledModalProps extends ComponentProps<typeof Adapter> {}

export const StyledModal = styled(Adapter).attrs({
  modalClassName: {
    base: 'Modal',
    afterOpen: 'Modal--after-open',
    beforeClose: 'Modal--before-close',
  },
  overlayClassName: {
    base: 'Overlay',
    afterOpen: 'Overlay--after-open',
    beforeClose: 'Overlay--before-close',
  },
})`
  .Overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${themeGet('colors.neutral100')}B3;
    transition: all 100ms;
    z-index: ${themeGet('zIndices.modalFullscreen')};
    outline: none;
    opacity: 0;

    &--after-open {
      opacity: 1;
    }

    &--before-close {
      opacity: 0;
    }
  }

  .Modal {
    display: flex;
    flex-direction: column;
    max-height: 98vh;
    min-width: 400px;
    width: min-content;
    background: ${themeGet('colors.background.block')};
    box-shadow: 0 1px 64px -2px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    padding: ${themeGet('space.6')}px;
    color: ${themeGet('colors.text.body')};
    overflow-y: auto;
    overflow-x: hidden;
    transition: all 100ms;
    outline: none;
    opacity: 0;
    position: relative;

    > button:first-of-type {
      position: absolute;
      top: ${themeGet('space.6')}px;
      right: ${themeGet('space.6')}px;
    }

    &--after-open {
      opacity: 1;
    }

    &--before-close {
      opacity: 0;
    }
  }

  & hr {
    margin: 20px -20px;
    border: none;
    background: ${themeGet('colors.divider.default')};
    height: 1px;

    &:first-of-type {
      margin-top: 0;
    }
  }
`

interface MainIconHolderProps {
  danger?: boolean
}

const dangerCss = ({ danger }: MainIconHolderProps) =>
  danger &&
  css`
    color: ${themeGet('colors.danger500')};
  `

export const MainIconHolder = styled.div<MainIconHolderProps & MarginProps>`
  display: flex;
  justify-content: center;
  font-size: 64px;
  color: ${themeGet('colors.primary500')};

  ${dangerCss}

  ${margin}
`

export const ChildrenHolder = styled.div`
  text-align: center;
`
