import loadable from '@loadable/component'

const Affiliate = loadable(() => import('./Affiliate'))
const BecomePartner = loadable(() => import('./BecomePartner'))
const HelpCenter = loadable(() => import('./HelpCenter'))
const Landing = loadable(() => import('./Landing'))
const Pricing = loadable(() => import('./Pricing'))
const Solutions = loadable(() => import('./Solutions'))
const SubmitReferral = loadable(() => import('./SubmitReferral'))

export {
  Affiliate,
  BecomePartner,
  HelpCenter,
  Landing,
  Pricing,
  Solutions,
  SubmitReferral,
}
