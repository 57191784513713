import React from 'react'

import { Container, ContainerProps, IconContainer, Inner } from './styles'

type Props = ContainerProps & {
  children?: React.ReactNode
  icon?: React.ReactNode
  onClick?: () => void
}

function Tab({ children, icon, large, small, onClick, ...rest }: Props) {
  return (
    <Container {...rest} onClick={onClick}>
      <Inner large={large} small={small}>
        {icon && <IconContainer mr="14px">{icon}</IconContainer>}
        {children}
      </Inner>
    </Container>
  )
}

export default Tab
