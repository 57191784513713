import styled from 'styled-components'
import { layout } from 'styled-system'

import { themeGet } from 'Theme/v2'

export const Container = styled.div.attrs({
  width: ['unset', 'unset', 420],
})`
  display: flex;
  flex-direction: column;
  z-index: 9999;
  gap: ${themeGet('space.6')}px;

  ${layout}
`

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${themeGet('colors.divider.default')};
`

export const StyledLink = styled.a`
  color: ${themeGet('colors.primary500')};
  cursor: pointer;
`
