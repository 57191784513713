import styled from 'styled-components'
import { space, SpaceProps } from 'styled-system'

import { themeGet } from 'Theme/v2'

export const Container = styled.div<SpaceProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  height: 20px;
  border: 1.5px solid ${themeGet('colors.divider.default')};
  border-radius: 12px;
  font-weight: 500;
  font-size: 12px;
  color: ${themeGet('colors.text.body')};
  font-weight: ${themeGet('fontWeights.1')};
  font-size: ${themeGet('fontSizes.0')}px;

  ${space};
`
