// @ts-strict-ignore
import React, { ReactElement, useMemo } from 'react'

import { useTheme } from 'styled-components'
import { SpaceProps } from 'styled-system'

import isNull from 'lodash/isNull'
import isObject from 'lodash/isObject'

import { Column } from 'Components/UI/_v2/Flex'
import Text from 'Components/UI/_v2/Text'

import { _ } from 'Services/I18n'

import { themeGet } from 'Theme/v2'

import { DelimiterBg, DelimiterFg } from './styles'

enum ValueKind {
  ratioInfo = 'ratioInfo',
}

type RatioValue = {
  current: number
  total?: number
}

type Props = SpaceProps & {
  large?: boolean
  currency?: string
  kind: ValueKind
  nowrap?: boolean
  value: RatioValue
}

function RatioFormatter({
  kind,
  nowrap,
  value = null,
  large,
  ...rest
}: Props): ReactElement {
  const theme = useTheme()

  const isLeft = useMemo(() => {
    if (!isObject(value)) return null

    return value.current <= value.total
  }, [value])

  const ratioColor = useMemo(() => {
    if (!isObject(value)) return null

    return isLeft
      ? themeGet('colors.success500')({ theme })
      : themeGet('colors.danger500')({ theme })
  }, [value, isLeft, theme])

  if (isNull(value)) return null

  const valueIsObject = isObject(value)

  return (
    <Column squeeze {...rest}>
      {kind === ValueKind.ratioInfo && valueIsObject && (
        <>
          <Text body caption2={!large} header6={large} nowrap={nowrap}>
            {value.current}
            {' / '}
            {value.total}
          </Text>

          <DelimiterBg large={large} my={large ? '6px' : '2px'}>
            <DelimiterFg
              bg={ratioColor}
              large={large}
              width={`${(value.current / value.total) * 100}%`}
            />
          </DelimiterBg>

          <Text
            caption2={large}
            caption3={!large}
            color={ratioColor}
            lowercase
            nowrap={nowrap}
          >
            <>
              {isLeft ? _('left') : _('over')}
              {': '}
              {Math.abs(value.total - value.current)}
            </>
          </Text>
        </>
      )}
    </Column>
  )
}

export default Object.assign(RatioFormatter, { ValueKind })
