import styled from 'styled-components'
import {
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
} from 'styled-system'

import { createGetParam, themeGet } from 'Theme/v2'

interface SizeProps {
  large?: boolean
  small?: boolean
}

const sizes = {
  height: {
    large: 48,
    default: 40,
    small: 32,
  },
  gap: {
    large: 12,
    default: 10,
    small: 8,
  },
  fontSize: {
    large: themeGet('fontSizes.2'),
    default: themeGet('fontSizes.1'),
    small: themeGet('fontSizes.0'),
  },
}

const getSizeParam = createGetParam<typeof sizes, SizeProps>(sizes)

interface Props extends SizeProps, FlexboxProps, LayoutProps, SpaceProps {}

const SideBarButton = styled.button.attrs(props => ({
  type: props.type || 'button',
}))<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  margin: 0;
  padding: 0 12px;
  background: none;
  border: 2px solid transparent;
  border-radius: 6px;
  outline: none;
  text-decoration: none;
  font-weight: ${themeGet('fontWeights.2')};
  font-size: ${getSizeParam('fontSize')}px;
  gap: ${getSizeParam('gap')}px;
  height: ${getSizeParam('height')}px;

  color: ${themeGet('colors.text.heading')};
  background: ${themeGet('colors.background.block')};

  &:focus-visible {
    border-color: ${themeGet('colors.primary700')};
  }

  &:hover {
    color: ${themeGet('colors.primary500')};
  }

  &:active {
    color: ${themeGet('colors.primary500')};
    background: ${themeGet('colors.primary900')};
  }

  &:disabled {
    color: ${themeGet('colors.text.muted')};
    cursor: default;
  }

  & > span {
    margin-right: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & > svg {
    flex-shrink: 0;
  }

  ${flexbox}
  ${layout}
  ${space}
`

export default SideBarButton
