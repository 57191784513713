import React, { ReactNode } from 'react'
import { Field as FormField } from 'react-final-form'

export default function ConditionField({
  when,
  is,
  children,
}: {
  when: string
  is: string
  children: ReactNode
}) {
  return (
    <FormField name={when} subscription={{ value: true }}>
      {({ input: { value } }) => (value === is ? children : null)}
    </FormField>
  )
}
