import { createStyledBreakpointsTheme } from 'styled-breakpoints'

import { Breakpoints } from 'Constants/ids'

import { space } from 'Theme/defaultTheme'
import { ThemeV3 } from 'Theme/types'

import colors from './colors'
import shadows from './shadows'
import sizes from './sizes'
import zIndices from './zIndices'

const breakpoints = {
  extraSmall: `${Breakpoints.ExtraSmall}px`,
  small: `${Breakpoints.Small}px`,
  medium: `${Breakpoints.Medium}px`,
  large: `${Breakpoints.Large}px`,
  extraLarge: `${Breakpoints.ExtraLarge}px`,
} as const

const breakpointsTheme = createStyledBreakpointsTheme({
  breakpoints,
})

const theme: ThemeV3 = {
  name: 'default',
  font: `Inter, BlinkMacSystemFont, sans-serif`,
  webfonts: ['Inter:400,500,600,700,900'],
  defaultTransitionTime: '0.3s',
  sizes,
  space,
  colors,
  zIndices,

  //          0   1   2   3   4   5   6   7   8   9
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 72, 96],
  //             0    1    2    3    4
  fontWeights: [400, 500, 600, 700, 900],
  //               0   1   2   3   4
  controlHeights: [24, 32, 40, 48, 56],

  shadow: shadows,

  breakpoints: [
    `${Breakpoints.ExtraSmall}px`,
    `${Breakpoints.Small}px`,
    `${Breakpoints.Medium}px`,
    `${Breakpoints.Large}px`,
    `${Breakpoints.ExtraLarge}px`,
  ],

  styledBreakpoints: breakpointsTheme.breakpoints,
}

export default theme
