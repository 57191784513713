import { DateTime } from 'luxon'

function dateToOrganizationZoneISO(
  date: Date,
  organization: MainSchema.Organization,
) {
  return DateTime.fromJSDate(date)
    .setZone(organization.timezone, { keepLocalTime: true })
    .toISO()
}

function organizationZoneISOToDateTime(
  iso: string,
  organization: MainSchema.Organization,
): DateTime {
  return DateTime.fromISO(iso)
    .setZone(organization.timezone)
    .setZone('local', { keepLocalTime: true })
}

const Dates = {
  dateToOrganizationZoneISO,
  organizationZoneISOToDateTime,
}

export default Dates
