import React from 'react'

import { ImageSquare } from 'phosphor-react'

import { Container } from './styles'

type Props = {
  square?: boolean
  size?: number
}

function LogoPlaceholder({ square, size = 64 }: Props) {
  return (
    <Container size={size} square={square}>
      <ImageSquare size={size / 2} />
    </Container>
  )
}

export default LogoPlaceholder
