import MaskedInput from 'react-text-mask'

import styled, { css } from 'styled-components'
import { margin, MarginProps, space, SpaceProps, styleFn } from 'styled-system'

import { checkCircleGlyph, informationGlyph, warningGlyph } from 'Assets/Svg'

import { createGetParam, themeGet } from 'Theme/v2'

import Icon from '../../Icon'

export type VariantProps = {
  active?: boolean
  success?: boolean
  danger?: boolean
  disabled?: boolean
}

export type SizeProps = {
  small?: boolean
  medium?: boolean
  large?: boolean
}

export type ContainerProps = VariantProps & SizeProps

export type WrapperProps = ContainerProps & MarginProps

export type InputProps = SpaceProps &
  SizeProps & {
    expired?: boolean
    cvv?: boolean
  }

export type ButtonProps = {
  up?: boolean
}

const variants = {
  borderColor: {
    default: themeGet('colors.divider.default'),
    active: themeGet('colors.primary500'),
    success: themeGet('colors.success500'),
    danger: themeGet('colors.danger500'),
  },
  captionColor: {
    default: themeGet('colors.text.muted'),
    success: themeGet('colors.success400'),
    danger: themeGet('colors.danger400'),
  },
}

const sizes = {
  padding: {
    large: 12,
    default: 10,
    small: 8,
  },
  labelFontSize: {
    large: themeGet('fontSizes.2'),
    default: themeGet('fontSizes.1'),
    small: themeGet('fontSizes.0'),
  },
  inputFontSize: {
    large: themeGet('fontSizes.2'),
    default: themeGet('fontSizes.1'),
    small: themeGet('fontSizes.0'),
  },
  containerIconSize: {
    large: 24,
    default: 20,
    small: 18,
  },
}

const getSizeParam = createGetParam<typeof sizes, SizeProps>(sizes)
const getParam = createGetParam<typeof variants, VariantProps>(variants)

const disabledCss: styleFn = ({ disabled }: ContainerProps) =>
  disabled &&
  css`
    color: ${themeGet('colors.text.muted')};
    input {
      color: ${themeGet('colors.text.muted')};
    }
    svg {
      fill: ${themeGet('colors.text.muted')};
    }
  `

const hoverCss: styleFn = ({ active }: ContainerProps) =>
  !active &&
  css`
    :hover {
      border-color: ${themeGet('colors.divider.contrast')} !important;
    }
  `

const inputExpiredCss: styleFn = ({ expired }: InputProps) =>
  expired &&
  css`
    text-align: center;
    min-width: unset;
    width: 60px;
  `

const inputCvvCss: styleFn = ({ cvv }: InputProps) =>
  cvv &&
  css`
    text-align: center;
    min-width: unset;
    width: 40px;
  `

export const Label = styled.div<MarginProps & SizeProps>`
  user-select: none;
  cursor: text;
  font-weight: ${themeGet('fontWeights.2')};
  color: ${themeGet('colors.text.heading')};
  font-size: ${getSizeParam('labelFontSize')}px;
  ${margin};
`

export const Caption = styled.div<
  MarginProps & {
    danger?: boolean
    success?: boolean
  }
>`
  display: flex;
  align-items: center;
  width: 100%;
  font-size: ${themeGet('fontSizes.0')}px;
  color: ${getParam('captionColor')};

  ${margin};

  > svg {
    fill: ${getParam('captionColor')};
  }
`

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;

  & input {
    font-size: ${getSizeParam('inputFontSize')}px;
  }

  ${margin};
`

export const Separator = styled.div<SpaceProps>`
  background-color: ${themeGet('colors.divider.default')};
  width: 1px;
  align-self: stretch;
  flex-shrink: 0;
  ${space}
`

export const StyledMaskedInput = styled(MaskedInput)<InputProps>`
  position: relative;
  margin: 0;
  border: none;
  outline: none;
  min-width: 190px;
  appearance: none;
  background: transparent;
  color: ${themeGet('colors.text.heading')};
  width: 100%;

  ::placeholder {
    color: ${themeGet('colors.text.muted')};
  }

  ${space}

  ${inputExpiredCss}
  ${inputCvvCss}
`

export const InfoIcon = styled(Icon).attrs({
  glyph: informationGlyph,
  size: 12,
})<MarginProps>``

export const DangerIcon = styled(Icon).attrs({
  glyph: warningGlyph,
  size: 12,
})<MarginProps>``

export const SuccessIcon = styled(Icon).attrs({
  glyph: checkCircleGlyph,
  size: 12,
})<MarginProps>``

export const Container = styled.div<ContainerProps>`
  display: flex;
  border: 1px solid ${getParam('borderColor')};
  border-radius: 6px;
  align-items: center;
  padding: ${getSizeParam('padding')}px;

  &:disabled {
    cursor: default;
  }

  svg {
    flex-shrink: 0;
    fill: ${themeGet('colors.text.body')};
    width: ${getSizeParam('containerIconSize')}px;
    height: ${getSizeParam('containerIconSize')}px;
  }

  ${disabledCss};
  ${hoverCss};
`

const buttonUpCss: styleFn = ({ up }: ButtonProps) =>
  up &&
  css`
    transform: rotateZ(180deg);
  `

export const DropdownButton = styled.button<ButtonProps>`
  padding: 0;
  border: none;
  cursor: pointer;
  background: none;
  transform: rotateZ(0deg);
  transition: all ${themeGet('defaultTransitionTime')};

  ${buttonUpCss}
`

export const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${themeGet('space.5')}px px ${themeGet('space.7')}px;
  background-color: ${themeGet('colors.background.subblock')};

  > button:not(:last-child) {
    margin-bottom: 8px;
  }
`

export const DropdownRow = styled.button`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  min-width: 280px;
  border: none;
  background: none;
  cursor: pointer;
  padding: ${themeGet('space.3')}px;
`
