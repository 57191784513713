import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { Flex } from 'rebass'

import { mailVerifyImage } from 'Assets/Images'

import { Text as TextUI } from 'Components/UI/_v2'

export const Container = styled(Flex).attrs({
  flexDirection: ['column-reverse', 'column-reverse', 'row'],
  justifyContent: 'space-between',
})`
  width: 720px;
  height: 304px;
  border-radius: 8px;
  background-color: ${themeGet('colors.white')};

  @media screen and (max-width: 768px) {
    width: 320px;
    height: 570px;
  }
`

export const Content = styled(Flex).attrs({
  flexDirection: 'column',
  justifyContent: 'center',
  pl: 48,
  pb: [4, 4, 0],
  width: 270,
})`
  z-index: 1;
`

export const ImageHolder = styled(Flex).attrs({
  justifyContent: 'flex-end',
})`
  flex-grow: 1;
  position: relative;
  max-width: 60%;

  background: url(${mailVerifyImage}) no-repeat center;
  background-size: contain;
`

export const Title = styled(TextUI).attrs({
  fontSize: 4,
  fontWeight: 600,
})`
  white-space: nowrap;
  color: ${themeGet('colors.cornflowerBlue')};
`
export const Text = styled(TextUI).attrs({
  fontSize: 2,
})`
  color: ${themeGet('colors.gunPowder')};

  > span {
    color: ${themeGet('colors.gunPowder')};
    text-decoration: underline;
  }
`
