import { useMemo } from 'react'

import { useMediaQuery } from 'styled-breakpoints/use-media-query'
import { useTheme } from 'styled-components'

import { themeGet } from 'Theme/v3'

export default function useResponsiveLayout() {
  const theme = useTheme()

  const breakpoints = useMemo(
    () => themeGet('styledBreakpoints')({ theme }),
    [theme],
  )

  const isMobilePortrait = useMediaQuery(breakpoints?.down('small'))

  const isMobileLandscapeAndNarrower = useMediaQuery(
    breakpoints?.down('medium'),
  )

  const isTabletOnly = useMediaQuery(breakpoints.only('medium'))

  const isTabletAndWider = useMediaQuery(breakpoints.up('medium'))

  const isDesktop = useMediaQuery(breakpoints?.up('large'))

  const isWideDesktop = useMediaQuery(breakpoints?.up('extraLarge'))

  return {
    isMobilePortrait,
    isTabletOnly,
    isMobileLandscapeAndNarrower,
    isTabletAndWider,
    isDesktop,
    isWideDesktop,
  }
}
