import { useEffect } from 'react'

const INSTANCES = new Set<string>()

function useSingleInstance(id: string) {
  useEffect(() => {
    if (INSTANCES.has(id)) {
      throw new Error(`Only one component with id "${id}" is allowed`)
    }

    INSTANCES.add(id)

    return () => {
      INSTANCES.delete(id)
    }
  }, [id])
}

export default useSingleInstance
