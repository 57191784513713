// @ts-strict-ignore
import React from 'react'

import { ApolloQueryResult } from '@apollo/client/core/types'

import { ShowConfirmModalParams } from 'Interfaces/Modals'

export const AppContext = React.createContext<{
  user?: MainSchema.User
  me?: MainSchema.User
  isAcceptedTeamMember?: MainSchema.TeamMember['isAccepted']
  refetchMe?: (
    variables?: Partial<
      MainSchema.UserOrganizationRoleArgs & MainSchema.UserProjectRoleArgs
    >,
  ) => Promise<ApolloQueryResult<MainQueryData<'me'>>>
  showConfirmModal?: (params?: ShowConfirmModalParams) => Promise<boolean>
  electron?: boolean
}>({})

export function withAppContext(Component) {
  return function ComponentWithAppContext(props) {
    return (
      <AppContext.Consumer>
        {appContext => <Component {...props} {...appContext} />}
      </AppContext.Consumer>
    )
  }
}
