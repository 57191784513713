import styled from 'styled-components'

import { Box, Flex } from 'rebass'

export const Container = styled(Flex)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 500;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`

export const Panel = styled(Box)`
  width: 320px;
  border-radius: 8px;
  padding: 16px;
  z-index: 501;
  background: white;
  transition: 0.3s ease all;
  font-family: Montserrat, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  :hover {
    box-shadow: 0 0 10px #3f3f3f;
  }
  h4 {
    margin-bottom: 8px;
    margin-top: 0;
  }
`

export const OpenReport = styled(Box).attrs({
  as: 'span',
})`
  display: inline;
  color: #449aff;
  cursor: pointer;
  text-decoration: underline;
`
