import { MODAL_ACTIONS, MODAL_STATE, MODAL_TYPES } from 'Constants/store'

export default function taskState(store) {
  store.on('@init', () => ({
    [MODAL_STATE]: null,
  }))

  store.on(MODAL_ACTIONS.OPEN_DELETE_TAG_GLOBALLY, (state, modalParams) => ({
    [MODAL_STATE]: {
      type: MODAL_TYPES.DELETE_TAG_GLOBALLY,
      ...modalParams,
    },
  }))

  store.on(MODAL_ACTIONS.LOCK_MILESTONE, (state, modalParams) => ({
    [MODAL_STATE]: {
      type: MODAL_TYPES.LOCK_MILESTONE,
      ...modalParams,
    },
  }))

  store.on(MODAL_ACTIONS.UNLOCK_MILESTONE, (state, modalParams) => ({
    [MODAL_STATE]: {
      type: MODAL_TYPES.UNLOCK_MILESTONE,
      ...modalParams,
    },
  }))

  store.on(MODAL_ACTIONS.ADD_PARTICIPANTS, (state, modalParams) => ({
    [MODAL_STATE]: {
      type: MODAL_TYPES.ADD_PARTICIPANTS,
      ...modalParams,
    },
  }))

  store.on(MODAL_ACTIONS.CLOSE, () => ({
    [MODAL_STATE]: null,
  }))
}
