import { useMemo } from 'react'

import { useLocation } from 'Context/LocationContext'

import { Location } from 'Constants/billing'
import { CurrencyKey } from 'Constants/Landing/Pricing/prices'

function useUserLocation() {
  const location = useLocation()

  const currencyKey =
    location === Location.Au ? CurrencyKey.AUD : CurrencyKey.USD
  const currencySymbol = location === Location.Au ? 'A$' : '$'

  return useMemo(
    () => ({
      currencySymbol,
      currencyKey,
      location: location ?? Location.Au,
    }),
    [currencySymbol, currencyKey, location],
  )
}

export default useUserLocation
