import OldMainTheme from 'Theme/Main'
import { Themes } from 'Theme/types'
import v2DarkTheme from 'Theme/v2/Dark'
import v2defaultTheme from 'Theme/v2/Default'

import darkTheme from './Dark'
import defaultTheme from './Default'

const OldTheme = {
  // TODO: Remove after redesign finished
  ...OldMainTheme,
}

const themes: Themes = {
  default: {
    ...OldTheme,
    v2: { ...v2defaultTheme },
    v3: { ...defaultTheme },
  },
  dark: {
    ...OldTheme,
    v2: { ...v2DarkTheme },
    v3: { ...darkTheme },
  },
}

export default themes
