// @ts-strict-ignore
import React, {
  ComponentProps,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

import { Check } from 'phosphor-react'

import { LinkButton } from 'Components/UI/_v2/Buttons'
import Tooltip from 'Components/UI/_v2/Tooltip'

type Props = Omit<ComponentProps<typeof LinkButton>, 'onClick' | 'success'> & {
  copyText?: string
  tooltip?: ReactNode
}

function CopyIconButton({ copyText, tooltip, children, ...rest }: Props) {
  const [copied, setCopied] = useState(false)
  const timeout = useRef(null)

  const handleCopy = useCallback(async () => {
    await navigator.clipboard.writeText(copyText)
    setCopied(true)
    clearTimeout(timeout.current)
    timeout.current = setTimeout(() => {
      setCopied(false)
    }, 1000)
  }, [copyText])

  useEffect(() => () => clearTimeout(timeout.current), [])

  return (
    <Tooltip content={tooltip} delay={Tooltip.Delay} small trigger="mouseenter">
      <LinkButton {...rest} success={copied} onClick={handleCopy}>
        {copied ? <Check weight="bold" /> : children}
      </LinkButton>
    </Tooltip>
  )
}

export default CopyIconButton
