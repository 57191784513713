import { themeGet } from '@styled-system/theme-get'

import get from 'lodash/get'

import { appearance } from 'Config/app'

import colors from './Main/colors'
import GlobalStyle from './GlobalStyle'
import ThemeProvider from './Provider'
import themes from './themes'

export const getColorFromBg = (bg: keyof typeof colors) => (props: any) =>
  `${get(props.theme, `colors.${String(bg)}`, 'primary')}`

const theme = themes[appearance.theme as keyof typeof themes]

export const headerHeight = (props: any) => themeGet('sizes.header')(props)

export { GlobalStyle, theme, ThemeProvider }

export default themes
