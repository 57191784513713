// @ts-strict-ignore
import { ApolloLink, HttpLink } from '@apollo/client'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import fetch from 'node-fetch'

export default function createHttpLink(uri: string) {
  return ApolloLink.split(
    operation => operation.getContext().batch === true,
    new BatchHttpLink({
      uri,
      fetch,
      headers: { Batch: 'true' },
    }),
    new HttpLink({ uri, fetch }),
  )
}
