import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'
import { size } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { Flex } from 'rebass'

const borderLeftColorCss = ({ color }) => color || themeGet('colors.santasGray')

export const Item = styled(Flex)`
  align-items: center;
  justify-content: center;
  height: 15px;
  width: 15px;

  border-radius: 50%;
  border-top: 2px solid ${themeGet('colors.frenchGray')};
  border-right: 2px solid ${themeGet('colors.frenchGray')};
  border-bottom: 2px solid ${themeGet('colors.frenchGray')};
  border-left: 2px solid ${borderLeftColorCss};

  transform: translate(-50%, -50%, 0);
  animation: spin 1.1s infinite linear;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  ${size};
`

function Loader({ color = '', ...rest }) {
  return <Item color={color} {...rest} />
}

Loader.displayName = 'SpinLoader'

Loader.defaultProps = {
  color: '',
}

Loader.propTypes = {
  color: PropTypes.string,
}

export default Loader
