import isEqual from 'lodash/isEqual'
import pick from 'lodash/pick'

import { PERSIST_ACTIONS, PERSIST_STATE } from 'Constants/store'

export const PERSIST_DEFAULTS = {
  isCookieAccepted: false,
}

export default function persistState(store) {
  store.on('@init', () => ({
    [PERSIST_STATE]: PERSIST_DEFAULTS,
  }))

  store.on(PERSIST_ACTIONS.SET, (state, variables) => {
    const values = pick(variables, Object.keys(PERSIST_DEFAULTS))

    const nextState = { ...state[PERSIST_STATE], ...values }

    if (isEqual(nextState, state[PERSIST_STATE])) return null

    return { [PERSIST_STATE]: nextState }
  })

  store.on(PERSIST_ACTIONS.CLEAR, () => ({ [PERSIST_STATE]: PERSIST_DEFAULTS }))
}
