import styled, { css } from 'styled-components'
import {
  display,
  DisplayProps,
  flexbox,
  FlexboxProps,
  height,
  HeightProps,
  minHeight,
  MinHeightProps,
  minWidth,
  MinWidthProps,
  OverflowProps,
  overflowX,
  padding,
  PaddingProps,
  space,
  SpaceProps,
  width,
  WidthProps,
} from 'styled-system'

import { customScrollbar } from 'Theme/styles'
import { createGetParam, themeGet } from 'Theme/v2'
import { gap, GapProps } from 'Theme/v2/system'

type RowColumnProps =
  | { row?: true; column?: never }
  | { column?: true; row?: never }

const rowColumnCss = ({ row, column }: RowColumnProps) => {
  if (row) {
    return css`
      display: flex;
      flex-direction: row;
    `
  }

  if (column) {
    return css`
      display: flex;
      flex-direction: column;
    `
  }

  return null
}

type VariantProps = {
  flatten?: boolean
  overflowVisible?: boolean
}

const params = {
  backgroundColor: {
    flatten: themeGet('colors.background.subblock'),
    default: themeGet('colors.background.block'),
  },
  boxShadow: {
    flatten: 'none',
    default: '0px 1px 16px -2px rgba(0, 0, 0, 0.05)',
  },
  cornerRadius: {
    default: '10px',
    topCornersSharp: '0 0 10px 10px',
    bottomCornersSharp: '10px 10px 0 0',
  },
  padding: {
    default: '16px',
    noPadding: '0',
  },
  overflow: {
    default: 'hidden',
    overflowVisible: 'visible',
  },
}

const getParam = createGetParam<typeof params, VariantProps>(params)

const Card = styled.div<
  SpaceProps &
    FlexboxProps &
    VariantProps &
    HeightProps &
    WidthProps &
    MinHeightProps &
    MinWidthProps &
    DisplayProps &
    OverflowProps &
    PaddingProps &
    GapProps &
    RowColumnProps & {
      bottomCornersSharp?: boolean
      noOverflowHidden?: boolean
      noPadding?: boolean
      topCornersSharp?: boolean
    }
>`
  display: flex;
  flex-direction: column;
  background-color: ${getParam('backgroundColor')};
  box-shadow: ${getParam('boxShadow')};
  padding: ${getParam('padding')};
  border-radius: ${getParam('cornerRadius')};
  overflow: ${getParam('overflow')};

  ${customScrollbar}

  ${flexbox}
  ${space}
  ${height}
  ${width}
  ${minHeight}
  ${minWidth}
  ${display}
  ${gap}
  ${padding}
  ${overflowX}
  ${rowColumnCss}
`
export default Card
