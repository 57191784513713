// @ts-strict-ignore
import React from 'react'

import { MarginProps } from '@styled-system/space'

import { User } from 'phosphor-react'

import Utils from 'Services/Utils'

import { Acronym, Container, Image, SizeProps, VariantProps } from './styles'

type Props = SizeProps &
  MarginProps &
  VariantProps & {
    entity?:
      | MainSchema.UserProfile
      | MainSchema.Project
      | MainSchema.Client
      | MainSchema.Organization
  }

function Picture({ bordered, entity, squared, ...rest }: Props) {
  const url = entity?.photoUrl || entity?.logoUrl

  if (url)
    return (
      <Container {...rest}>
        <Image bordered={bordered} squared={squared} src={url} />
      </Container>
    )

  let entityName = ''

  if (entity?.name) entityName = entity?.name
  if (entity?.firstName) entityName = `${entity?.firstName} ${entity?.lastName}`

  if (entityName)
    return (
      <Container {...rest}>
        <Acronym bordered={bordered}>
          {Utils.Strings.acronym(entityName, 3)}
        </Acronym>
      </Container>
    )

  return (
    <Container {...rest}>
      <User size="1.4em" weight="bold" />
    </Container>
  )
}

export default Picture
