import { BREAKPOINTS } from 'Constants/ids'

import { space } from 'Theme/defaultTheme'

import buttons from './buttons'
import colors from './colors'
import sizes from './sizes'

export const breakpoints = {
  [BREAKPOINTS.MOBILE]: '420px',
  [BREAKPOINTS.TABLET]: '768px',
  [BREAKPOINTS.DESKTOP]: '1200px',
}

export const zIndices = {
  modal: 500,
  header: 1000,
  dropdown: 1500,
  modalFullscreen: 2000,
}

export const metrics = {
  smartphone: {
    min: 320,
    max: 750,
  },
  tablet: {
    min: 750,
    max: 1024,
  },
  mediumScreen: {
    min: 1024,
    max: 1440,
  },
  largeScreen: {
    min: 1440,
  },
  veryLargeScreen: {
    min: 1661,
  },
}

const theme = {
  name: 'Startupcraft main theme',
  colors,
  font: 'Inter, ProximaNova, "Helvetica Neue", Arial, Helvetica, sans-serif;',
  transitionTime: '.17s',
  sizes,
  breakpoints: Object.values(breakpoints),
  metrics,
  shadow: '0 0 8px 0 rgba(0, 0, 0, 0.1)',
  shadowSelected: '0 0 0 3px rgba(102, 68, 234, 0.5)',
  zIndices,
  space,
  ...buttons,
}

export default theme
