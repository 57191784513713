import React, { ComponentProps } from 'react'
import { ControlProps } from 'react-select'

import { Row } from 'Components/UI/_v2/Flex'
import Select from 'Components/UI/_v2/Forms/Select'
import * as Components from 'Components/UI/_v2/Forms/Select/Components'

import { SearchIcon } from './styles'

const Control = ({ children, ...props }: ControlProps<unknown, false>) => {
  return (
    <Components.Control {...props}>
      <Row ml={3}>
        <SearchIcon />
      </Row>
      {children}
    </Components.Control>
  )
}

const DropdownIndicator = () => {
  return null
}

type Props = ComponentProps<typeof Select>

function Search(props: Props) {
  return <Select {...props} components={{ Control, DropdownIndicator }} />
}

export default Search
