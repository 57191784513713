import React, { ReactNode } from 'react'

import { Column } from 'Components/UI/_v2/Flex'
import Text from 'Components/UI/_v2/Text'

import {
  CloseButtonStyled,
  CloseIcon,
  Container,
  ContainerProps,
  Footer,
  IconContainer,
  StyledText,
} from './styles'

type Props = ContainerProps & {
  label?: ReactNode
  text: ReactNode
  icon?: ReactNode
  footer?: ReactNode
}

type CloseButtonProps = {
  onClose?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
}

function Toast({ label, text, icon, footer, ...rest }: Props) {
  return (
    <Container {...rest}>
      {icon && <IconContainer>{icon}</IconContainer>}

      <Column maxWidth={316}>
        {label && (
          <Text heading mb={2} subheader2>
            {label}
          </Text>
        )}
        <StyledText>{text}</StyledText>

        {footer && <Footer mt={4}> {footer} </Footer>}
      </Column>
    </Container>
  )
}

function CloseButton({ onClose }: CloseButtonProps) {
  return (
    <CloseButtonStyled type="button" onClick={onClose}>
      <CloseIcon />
    </CloseButtonStyled>
  )
}

Toast.CloseButton = CloseButton

export default Toast
