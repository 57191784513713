import React, { ReactNode, useCallback, useRef, useState } from 'react'

import { SpaceProps } from 'styled-system'

import { Instance, Placement, Props as TippyProps } from 'tippy.js'

import { Row } from 'Components/UI/_v2/Flex'

import {
  ChevronIcon,
  Container,
  Label,
  MenuItem,
  SizeProps,
  StyledDropdown,
  StyledMenu,
  StyledTippy,
  ValueContainer,
} from './styles'

export type DropdownOption = {
  label: string
  value: string | number
  [key: string]: any
}

type Props = SizeProps &
  SpaceProps & {
    className?: string
    disabled?: boolean
    endAdornment?: ReactNode
    hasDivider?: boolean
    markSelected?: boolean
    menuPlacement?: Placement
    options?: DropdownOption[]
    placeholder?: string
    startAdornment?: ReactNode
    value?: DropdownOption
    renderOption?: (option: DropdownOption) => ReactNode
    onChange?: (option: DropdownOption) => void
  }

function Dropdown({
  className,
  disabled,
  endAdornment,
  hasDivider,
  large,
  markSelected,
  menuPlacement,
  options,
  placeholder,
  small,
  startAdornment,
  value,
  renderOption,
  onChange,
  ...rest
}: Props) {
  const tippyRef = useRef<Instance<TippyProps>>()

  const [selectedLabel, setSelectedLabel] = useState<string>()
  const [open, setOpen] = useState<boolean>(false)

  const handleItemClick = useCallback(
    (option: DropdownOption) => {
      onChange?.(option)
      tippyRef.current?.hide()
      setSelectedLabel(option.label)
    },
    [onChange],
  )

  return (
    <Container {...rest}>
      <StyledTippy
        content={
          <StyledMenu hasDivider={hasDivider} large={large} small={small}>
            {options?.map(option => (
              <MenuItem
                key={option.value}
                selected={markSelected && option === value}
                onClick={() => handleItemClick(option)}
              >
                {renderOption ? renderOption(option) : option.label}
              </MenuItem>
            ))}
          </StyledMenu>
        }
        disabled={disabled}
        duration={0}
        interactive
        maxWidth="100%"
        placement={menuPlacement || 'bottom'}
        trigger="click"
        onCreate={instance => {
          tippyRef.current = instance
        }}
        onHide={() => setOpen(false)}
        onShow={() => setOpen(true)}
      >
        <StyledDropdown
          active={!!selectedLabel}
          className={className}
          disabled={disabled}
          large={large}
          small={small}
          tabIndex={!disabled ? -1 : undefined}
        >
          <ValueContainer>
            {startAdornment && (
              <Row alignItems="center" display="flex" mr={3}>
                {startAdornment}
              </Row>
            )}
            <Label>{selectedLabel || placeholder}</Label>
            {endAdornment && (
              <Row alignItems="center" display="flex" mx={3}>
                {endAdornment}
              </Row>
            )}
            <ChevronIcon large={large} open={open} small={small} />
          </ValueContainer>
        </StyledDropdown>
      </StyledTippy>
    </Container>
  )
}

export default Dropdown
