import styled from 'styled-components'
import { layout, LayoutProps, space, SpaceProps } from 'styled-system'

import { themeGet } from 'Theme/v2'

const CirclePlaceholder = styled.div<SpaceProps & LayoutProps>`
  position: relative;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: linear-gradient(
    to right,
    ${themeGet('colors.background.subblock')} 8%,
    ${themeGet('colors.divider.lighter')} 18%,
    ${themeGet('colors.background.subblock')} 33%
  );
  background-size: 1200px 104px;
  border-radius: 50%;
  position: relative;

  @keyframes placeHolderShimmer {
    0% {
      background-position: -1168px 0;
    }
    100% {
      background-position: 1168px 0;
    }
  }

  ${space}
  ${layout}
`

export default CirclePlaceholder
