import styled, { css } from 'styled-components'
import {
  layout,
  LayoutProps,
  padding,
  PaddingProps,
  space,
  SpaceProps,
  styleFn,
} from 'styled-system'

import { themeGet } from 'Theme/v2'

export const Arrow = styled.div<{ ref?: any }>`
  position: absolute;
  width: 10px;
  height: 10px;

  &:before {
    position: absolute;
    width: 10px;
    height: 10px;
    content: '';
    transform: rotate(45deg);
    background: ${themeGet('colors.background.block')};
  }
`

export type PopoverBlockProps = SpaceProps &
  LayoutProps &
  PaddingProps & {
    withoutBackground?: boolean
  }

const popoverBlockWithoutBgCss: styleFn = ({
  withoutBackground,
}: PopoverBlockProps) =>
  withoutBackground &&
  css`
    background: none;
    border-radius: 0;
    box-shadow: none;
    padding: 0;
  `

export const PopoverBlock = styled.div<PopoverBlockProps>`
  margin: 0;
  padding: 0;
  box-shadow: 0 0 15px ${themeGet('colors.black')}1A;
  border-radius: 10px;
  font-size: 13px;
  background-color: ${themeGet('colors.background.block')};

  &[data-placement^='top'] ${Arrow} {
    bottom: -5px;
  }

  &[data-placement^='bottom'] ${Arrow} {
    top: -5px;
  }

  &[data-placement^='left'] ${Arrow} {
    right: -5px;
  }

  &[data-placement^='right'] ${Arrow} {
    left: -5px;
  }

  ${space};
  ${layout};
  ${padding};

  ${popoverBlockWithoutBgCss};
`

export const ContentWrapper = styled.div`
  position: relative;
`
