import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'

import { Location } from 'Constants/billing'

import Utils from 'Services/Utils'

const LocationContext = createContext<Location | null>(null)

type Props = {
  children: ReactNode
}

export function LocationProvider({ children }: Props) {
  const [location, setLocation] = useState<Location | null>(null)

  useEffect(() => {
    if (!location) {
      Utils.Location.getUserLocation()
        .then(result => {
          setLocation(result === Location.Au ? Location.Au : Location.Default)
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error('Error fetching location:', error)
          setLocation(Location.Au)
        })
    }
  }, [location])

  return (
    <LocationContext.Provider value={location ?? Location.Au}>
      {children}
    </LocationContext.Provider>
  )
}

export function useLocation(): Location | null {
  const context = useContext(LocationContext)
  if (!context) {
    throw new Error('useLocation must be used within a LocationProvider')
  }
  return context
}
