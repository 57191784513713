// @ts-strict-ignore
import { NavLink } from 'react-router-dom'

import styled, { css } from 'styled-components'
import { padding } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

export const Container = styled.div.attrs({
  py: 7,
  pb: [7, 7, 8],
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: ${themeGet('space.7')}px;
  border-top: 1px solid ${themeGet('colors.divider.default')};

  ${padding}
`

export const NavMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${themeGet('space.7')}px;
`

const navItemActiveCss = ({ active }) =>
  active &&
  css`
    color: ${themeGet('colors.text.heading')};
    border-bottom: 2px solid ${themeGet('colors.primary500')};
    cursor: default;

    &:hover {
      color: ${themeGet('colors.text.heading')};
    }
  `

export const StyledNavLink = styled(NavLink)`
  display: flex;
  height: 32px;
  color: ${themeGet('colors.text.muted')};
  font-size: 16px;
  font-weight: 300;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  border-bottom: 2px solid transparent;

  &:hover {
    color: ${themeGet('colors.primary500')};
  }

  ${navItemActiveCss}
`
