import { NavLink } from 'react-router-dom'

import styled from 'styled-components'
import { padding } from 'styled-system'

import { themeGet } from 'Theme/v2'

export const Container = styled.div.attrs({
  px: [20, 20, 40, 64],
})`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-block: ${themeGet('space.6')}px;
  background-color: ${themeGet('colors.background.block')}f0;
  backdrop-filter: blur(2.5px);
  z-index: 1999;

  ${padding}
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1156px;
`

export const StyledLink = styled(NavLink)`
  display: flex;
  align-items: center;
  text-decoration: none;
  white-space: nowrap;
  gap: ${themeGet('space.4')}px;
  text-transform: uppercase;
  font-weight: ${themeGet('fontWeights.2')};
  color: ${themeGet('colors.text.heading')};
  font-size: ${themeGet('fontSizes.2')}px;
`

export const StyledButton = styled.button`
  background: none;
  border: none;
  padding: ${themeGet('space.1')}px;
  color: ${themeGet('colors.text.muted')};
  cursor: pointer;
  position: absolute;
  right: ${themeGet('space.6')}px;

  &:hover {
    color: ${themeGet('colors.text.heading')};
  }
`
