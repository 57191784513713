import pick from 'lodash/pick'
import size from 'lodash/size'

const createMutationPayload = (
  object = {},
  filterKeys = [],
  options = {
    update: null,
    optimisticResponse: null,
  },
) => ({
  variables: {
    input: size(filterKeys) > 0 ? pick(object, filterKeys) : object,
  },

  ...options,
})

export default {
  createMutationPayload,
}
