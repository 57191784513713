import { nanoid } from 'nanoid'

type Event = string
type Callback = (...args: any[]) => any

interface Subscription {
  callback: Callback
}

interface Token {
  clear: () => void
}

const actions = {
  oAuth: {
    present: 'oauth/present',
    dismiss: 'oauth/dismiss',
  },
  task: {
    update: 'task/update',
    updateDescription: 'task/update-description',
    addAttachments: 'task/add-attachments',
    deleteAttachments: 'task/delete-attachments',
    cancelUploadingAttachment: 'task/cancel-uploading-attachment',
  },
  chat: {
    addAttachments: 'chat/add-attachments',
    cancelAttachment: 'chat/cancel-attachment',
    replyToMessage: 'chat/reply-to-message',
    editMessage: 'chat/edit-message',
    deleteMessage: 'chat/delete-message',
    markUnread: 'chat/mark-unread',
    showForceNotify: 'chat/show-force-notify',
    changeCurrentDate: 'chat/changeCurrentDate',
    jumpToMessage: 'chat/jumpToMessage',
    jumpToDate: 'chat/jumpToDate',
  },
  attachmentPreview: {
    open: 'attachmentPreview/open',
    close: 'attachmentPreview/close',
  },
  userMenu: {
    setNotificationModalOpened: 'userMenu/setNotificationModalOpened',
    setStatusModalOpened: 'userMenu/setStatusModalOpened',
    setCreateOrganizationModalOpened:
      'userMenu/setCreateOrganizationModalOpened',
    preventClose: 'userMenu/preventClose',
  },
  team: {
    openShareTeamMemberModal: 'team/openShareTeamMemberModal',
    openMoveTeamMemberModal: 'team/openMoveTeamMemberModal',
    openObsoleteShareTeamMemberModal: 'team/openObsoleteShareTeamMemberModal',
    openRemoveTeamMemberModal: 'team/openRemoveTeamMemberModal',
    openResetUserPhoneVerificationModal:
      'team/openResetUserPhoneVerificationModal',
    openResetUserTOTPVerificationModal:
      'team/openResetUserTOTPVerificationModal',
  },
  billing: {
    openSelectPlanModal: 'billing/openSelectPlanModal',
    openOrderSummaryModal: 'billing/openOrderSummaryModal',
  },
}

function EventBus() {
  const subscriptions = new Map<Event, Map<string, Subscription>>()

  return {
    on(event: Event, callback: Callback) {
      if (!subscriptions.has(event)) {
        subscriptions.set(event, new Map<string, Subscription>())
      }

      const subscriptionId = nanoid()

      subscriptions.get(event)?.set(subscriptionId, {
        callback,
      })

      return {
        clear: () => {
          const eventSubscriptions = subscriptions.get(event)
          eventSubscriptions?.delete(subscriptionId)
          if (eventSubscriptions?.size === 0) {
            subscriptions.delete(event)
          }
        },
      } as Token
    },
    trigger(event: Event, ...args: any[]) {
      subscriptions.get(event)?.forEach(subscription => {
        subscription.callback(...args)
      })
    },
    actions,
  }
}

export default EventBus()
