import React, { useMemo } from 'react'

import { useTheme } from 'styled-components'

import { CheckCircle } from 'phosphor-react'

import { Button, Column, Modal, Text } from 'Components/UI/_v2'

import { themeGet } from 'Theme/v2'

import { Content } from './styles'

type Props = {
  subtitle?: string
  title?: string
  onConfirm?: () => void
}

// TODO: localize
function VerifyTOTPCoordinatorModalSuccessContent({
  subtitle,
  title,
  onConfirm,
}: Props) {
  const theme = useTheme()
  const colors = useMemo(
    () => ({ icon: themeGet('colors.success500')({ theme }) }),
    [theme],
  )

  return (
    <Content>
      <Column center>
        <CheckCircle color={colors.icon} size={64} />
        <Text color="text.heading" mt={4} subheader1>
          {title ?? 'Success'}
        </Text>
        {subtitle && (
          <Text caption2 color="text.muted" mt={1}>
            {subtitle}
          </Text>
        )}
      </Column>
      <Modal.Buttons mt={6}>
        <Button type="submit" onClick={onConfirm}>
          Got it
        </Button>
      </Modal.Buttons>
    </Content>
  )
}

export default VerifyTOTPCoordinatorModalSuccessContent
