import qs from 'qs'

import get from 'lodash/get'

import { FRONTEND_URL, PUBLIC_URL } from 'Config/app'

function getLocationHashParams(location) {
  return qs.parse(get(location, 'hash', '').replace('#', ''))
}

function getLocationSearchParams(location) {
  return qs.parse(get(location, 'search', '').replace('?', ''))
}

function getFullLocation(location) {
  return `${get(location, 'pathname', '/')}${get(location, 'search', '')}${get(
    location,
    'hash',
    '',
  )}`
}

function navigateBackToApp() {
  const { backTo } = getLocationSearchParams(window.location)

  if (backTo) {
    try {
      const backToURL = new URL(backTo)
      if (backToURL.hostname.endsWith(new URL(PUBLIC_URL).hostname)) {
        window.location.href = backTo
        return
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Invalid backTo URL:', error)
    }
  }

  window.location.href = FRONTEND_URL
}

export default {
  getLocationHashParams,
  getLocationSearchParams,
  getFullLocation,
  navigateBackToApp,
}
