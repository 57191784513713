// @ts-strict-ignore
import React, { ReactNode } from 'react'

import styled from 'styled-components'
import { margin, MarginProps } from '@styled-system/space'

import { themeGet } from 'Theme/v2'

import Card from '../Card'

export const Left = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  width: 240px;
  border-right: 1px solid ${themeGet('colors.neutral700')};
  padding: 16px;
`

export const Right = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 16px;
`

export const Container = styled(Card)`
  padding: 0;
  flex-direction: row;

  ${margin}
`

export default function SplittedCard({
  renderLeft,
  renderRight,
  ...rest
}: {
  renderLeft?: ReactNode
  renderRight?: ReactNode
} & MarginProps) {
  return (
    <Container {...rest}>
      <Left>{renderLeft}</Left>
      <Right>{renderRight}</Right>
    </Container>
  )
}
