import React from 'react'

import { Button } from 'Components/UI/_v2'

import { useScopedI18n } from 'Services/I18n'

import { Container, NavMenu, StyledNavLink } from './styles'

type Props = {
  locationPath: string
  navItems: { name: string; to: string }[]
  onClose: () => void
  onContactSales: () => void
}

function MobileMenu({
  locationPath,
  navItems,
  onClose,
  onContactSales,
}: Props) {
  const s = useScopedI18n('home.header')

  return (
    <Container>
      <NavMenu onClick={onClose}>
        {navItems.map(item => (
          <StyledNavLink
            active={item.to === locationPath}
            key={item.name}
            to={item.to}
          >
            {s(item.name)}
          </StyledNavLink>
        ))}
      </NavMenu>

      <Button tertiary width={[1, 'unset']} onClick={onContactSales}>
        {s('contactSales')}
      </Button>
    </Container>
  )
}

export default MobileMenu
