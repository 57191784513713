import includes from 'lodash/includes'
import some from 'lodash/some'

const SPECIAL_CHARACTERS = '!@#$%_'
const SPECIAL_CHARACTERS_FULL = '!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~'

function check(password?: string) {
  if (!password) return false
  return some(SPECIAL_CHARACTERS_FULL, char => includes(password, char))
}

export default {
  SPECIAL_CHARACTERS,
  check,
}
