import styled, { css } from 'styled-components'
import {
  color,
  ColorProps,
  flex,
  flexbox,
  FlexProps,
  justifyContent,
  JustifyContentProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  styleFn,
} from 'styled-system'

import { themeGet } from 'Theme/v2'
import { gap, GapProps } from 'Theme/v2/system'

type PopupContainerProps = SpaceProps & LayoutProps & FlexProps & GapProps

export const PopupContainer = styled.div<PopupContainerProps>`
  display: flex;
  flex-direction: column;
  background: ${themeGet('colors.background.block')};
  border-radius: 6px;
  padding: ${themeGet('space.4')}px;

  ${space}
  ${layout}
  ${flex}
  ${gap}
`

export const PopupDivider = styled.div<SpaceProps>`
  margin: 0 -${themeGet('space.4')}px;
  border-top: 1px solid ${themeGet('colors.divider.default')};

  ${space}
`

interface CapitalizeProps {
  capitalize?: boolean
}

const capitalizeCss = ({ capitalize }: CapitalizeProps) =>
  capitalize &&
  css`
    text-transform: capitalize;
  `

export const PopupTitle = styled.div<SpaceProps & CapitalizeProps>`
  font-size: ${themeGet('fontSizes.1')}px;
  font-weight: ${themeGet('fontWeights.2')};
  color: ${themeGet('colors.text.heading')};

  ${capitalizeCss}
  ${space}
`

interface OpenerProps
  extends LayoutProps,
    SpaceProps,
    FlexProps,
    GapProps,
    JustifyContentProps {
  disabled?: boolean
}

const openerDisabledCss: styleFn = (props: OpenerProps) =>
  props.disabled
    ? css`
        cursor: default;
      `
    : css`
        cursor: pointer;
      `

export const Opener = styled.button.attrs({ type: 'button' })<OpenerProps>`
  display: flex;
  align-items: center;
  padding: 0;
  background: none;
  border: none;
  color: inherit;
  font: inherit;
  cursor: pointer;
  white-space: nowrap;
  width: fit-content;
  max-width: 100%;
  height: fit-content;

  &:focus-visible {
    outline: 2px solid ${themeGet('colors.primary700')};
    border-radius: 6px;
  }

  ${openerDisabledCss};
  ${layout}
  ${space}
  ${flexbox}
  ${gap}
  ${justifyContent}
`

interface IconTurnerProps {
  turnUp?: boolean
}

const iconTurnerTurnUpCss: styleFn = ({ turnUp }: IconTurnerProps) =>
  turnUp &&
  css`
    transform: rotate(-180deg);
  `

export const IconTurner = styled.div<IconTurnerProps & SpaceProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform ${themeGet('defaultTransitionTime')};

  ${iconTurnerTurnUpCss}
  ${space}
`

interface IconHolderProps {
  turnUp?: boolean
}

const iconHolderTurnUpCss: styleFn = ({ turnUp }: IconHolderProps) =>
  turnUp &&
  css`
    transform: rotate(-180deg);
  `

export const IconHolder = styled.div<IconHolderProps & SpaceProps & ColorProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform ${themeGet('defaultTransitionTime')};

  ${iconHolderTurnUpCss}
  ${space}
  ${color}
`
