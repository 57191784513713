import React from 'react'

import { Column } from 'Components/UI/_v2/Flex'

import {
  CaptionText,
  Circle,
  Container,
  ContainerProps,
  InvisibleCheckbox,
  LabelText,
  ToggleHolder,
} from './styles'

interface Props extends ContainerProps {
  name?: string
  checked?: boolean
  defaultChecked?: boolean
  caption?: React.ReactNode
  disabled?: boolean
  label?: React.ReactNode
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  onFocus?: React.FocusEventHandler<HTMLInputElement>
  onBlur?: React.FocusEventHandler<HTMLInputElement>
}

function Toggle({
  name,
  checked,
  defaultChecked,
  disabled,
  label,
  caption,
  onChange,
  onFocus,
  onBlur,
  small,
  large,
  ...rest
}: Props) {
  return (
    <Container {...rest} large={large} small={small}>
      {(label || caption) && (
        <Column gap="2px">
          {label && <LabelText>{label}</LabelText>}
          {caption && <CaptionText>{caption}</CaptionText>}
        </Column>
      )}
      <InvisibleCheckbox
        checked={checked}
        defaultChecked={defaultChecked}
        disabled={disabled}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
      />
      <ToggleHolder large={large} small={small}>
        <Circle large={large} small={small} />
      </ToggleHolder>
    </Container>
  )
}

export default Toggle
