import loadable from '@loadable/component'

import { APP, LOGOUT, SITEMAP } from 'Constants/paths'

import AppRedirect from 'Containers/AppRedirect'
import { Root } from 'Containers/Layout'

import authRoutes from './auth'
import landingRoutes from './landing'
import legalRoutes from './legal'

const Logout = loadable(() => import('Containers/Pages/Logout'))
const NotFound = loadable(() => import('Containers/Pages/NotFound'))
const Sitemap = loadable(() => import('Containers/Pages/Sitemap'))

export default function routes() {
  return [
    {
      component: Root,
      routes: [
        {
          path: LOGOUT,
          exact: true,
          component: Logout,
        },
        {
          path: APP,
          component: AppRedirect,
        },
        ...authRoutes,
        ...landingRoutes,
        ...legalRoutes,
        { path: SITEMAP, exact: true, component: Sitemap },
        { component: NotFound },
      ],
    },
  ]
}
