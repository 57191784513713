import { DateTime } from 'luxon'

export enum PeriodName {
  ThisMonth = 'thisMonth',
  LastMonth = 'lastMonth',
  ThisQuarter = 'thisQuarter',
  LastQuarter = 'lastQuarter',
  ThisYear = 'thisYear',
  LastYear = 'lastYear',
  ActiveMilestone = 'activeMilestone',
}

type DateRange = {
  from: string
  to: string
}

export function makeRange(start: DateTime, end: DateTime, zone = 'local') {
  return {
    from: start.setZone(zone, { keepLocalTime: true }).toISO(),
    to: end.setZone(zone, { keepLocalTime: true }).toISO(),
  }
}

function toRange(
  period: PeriodName,
  date?: DateTime,
  zone: string = 'local',
): DateRange | null {
  const now = date || DateTime.now()
  switch (period) {
    case PeriodName.ThisMonth: {
      const start = now.startOf('month')
      const end = start.endOf('month')
      return makeRange(start, end, zone)
    }
    case PeriodName.LastMonth: {
      const lastMonth = now.minus({ month: 1 })
      return toRange(PeriodName.ThisMonth, lastMonth, zone)
    }
    case PeriodName.ThisQuarter: {
      const start = now.startOf('quarter')
      const end = start.endOf('quarter')
      return makeRange(start, end, zone)
    }
    case PeriodName.LastQuarter: {
      const lastQuarter = now.minus({ quarter: 1 })
      return toRange(PeriodName.ThisQuarter, lastQuarter, zone)
    }
    case PeriodName.ThisYear: {
      const start = now.startOf('year')
      const end = start.endOf('year')
      return makeRange(start, end, zone)
    }
    case PeriodName.LastYear: {
      const lastYear = now.minus({ year: 1 })
      return toRange(PeriodName.ThisYear, lastYear, zone)
    }
    default:
      return null
  }
}

const Period = {
  toRange,
  PeriodName,
}

export default Period
