import { darken, opacify } from 'polished'

import colors from './colors'

const HOVER_VALUE = 0.05
const ACTIVE_VALUE = 0.1

export default {
  buttonBackground: {
    link: colors.clearFromBlack,
    outline: colors.white,
    default: colors.primary,
  },
  buttonColor: {
    link: colors.font.primary,
    outline: colors.primary,
    default: colors.white,
  },
  buttonHover: {
    link: opacify(HOVER_VALUE, colors.clearFromBlack),
    outline: darken(HOVER_VALUE, colors.white),
    default: darken(HOVER_VALUE, colors.primary),
  },
  buttonActive: {
    link: opacify(ACTIVE_VALUE, colors.clearFromBlack),
    outline: darken(ACTIVE_VALUE, colors.white),
    default: darken(ACTIVE_VALUE, colors.primary),
  },
  // TODO: button shadow
  buttonShadow: {
    enabled: `box-shadow: 0 6px 15px 0 rgba(0, 0, 0, 0.2);`,
    disabled: `box-shadow: 0 6px 15px 0 rgba(0, 0, 0, 0.2);`,
    active: `box-shadow: 0 6px 15px 0 rgba(0, 0, 0, 0.2)`,
  },
}
