import { css } from 'styled-components'

import { themeGet } from 'Theme/v2'

export default css`
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-corner {
    background: none;
  }

  ::-webkit-scrollbar-track {
    height: 8px;
    width: 8px;
    border-radius: 4px;
    background-color: ${themeGet('colors.scrollbar.track')};
  }

  ::-webkit-scrollbar-thumb {
    height: 8px;
    width: 8px;
    border-radius: 4px;
    background-color: ${themeGet('colors.scrollbar.thumb')};
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: ${themeGet('colors.neutral500')};
  }
`
