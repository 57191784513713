import styled, { css } from 'styled-components'
import {
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  padding,
  PaddingProps,
  space,
  SpaceProps,
  styleFn,
} from 'styled-system'

import { createGetParam, themeGet } from 'Theme/v2'

const variants = {
  color: {
    default: themeGet('colors.text.heading'),
    danger: themeGet('colors.danger500'),
  },
  hoverBackground: {
    default: themeGet('colors.primary500'),
    danger: themeGet('colors.danger500'),
  },
  activeBackground: {
    default: themeGet('colors.primary400'),
    danger: themeGet('colors.danger400'),
  },
}

interface VariantProps {
  active?: boolean
  danger?: boolean
}

const getVariantsParam = createGetParam<typeof variants, VariantProps>(variants)

interface SizeProps {
  large?: boolean
  small?: boolean
}

const sizes = {
  height: {
    large: 48,
    default: 40,
    small: 32,
  },
  fontSize: {
    large: themeGet('fontSizes.2'),
    default: themeGet('fontSizes.1'),
    small: themeGet('fontSizes.0'),
  },
}

const getSizeParam = createGetParam<typeof sizes, SizeProps>(sizes)

export interface MenuItemProps
  extends FlexboxProps,
    LayoutProps,
    SpaceProps,
    PaddingProps,
    VariantProps {}

const menuItemActiveCss: styleFn = ({ active }: VariantProps) =>
  active &&
  css`
    cursor: default;
    color: white;
    background: ${getVariantsParam('activeBackground')};

    &:hover {
      background: ${getVariantsParam('activeBackground')};
    }
  `

export const MenuItem = styled.button.attrs(props => ({
  type: props.type || 'button',
}))<MenuItemProps>`
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  margin: 0;
  padding: 0 ${themeGet('space.7')}px;
  background: none;
  border: none;
  outline: none;
  text-decoration: none;
  white-space: nowrap;
  font-weight: ${themeGet('fontWeights.1')};
  gap: ${themeGet('space.4')}px;
  color: ${getVariantsParam('color')};

  :hover,
  :focus-visible {
    color: white;
    background: ${getVariantsParam('hoverBackground')};
  }

  :active {
    color: white;
    background: ${getVariantsParam('activeBackground')};
  }

  :disabled,
  :disabled:hover,
  :disabled:active {
    color: ${themeGet('colors.text.muted')};
    background: ${themeGet('colors.background.block')};
    cursor: default;
  }

  & > span {
    margin-right: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & > svg {
    flex-shrink: 0;
  }

  ${flexbox}
  ${layout}
  ${space}
  ${padding}
  
  ${menuItemActiveCss}
`

type DropdownMenuProps = SpaceProps & LayoutProps & SizeProps

export const DropdownMenu = styled.div<DropdownMenuProps>`
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  overflow: hidden;
  background: ${themeGet('colors.background.block')};

  & ${MenuItem} {
    font-size: ${getSizeParam('fontSize')}px;
    height: ${getSizeParam('height')}px;
    min-width: ${getSizeParam('height')}px;
  }

  ${layout}
  ${space}
`

export const MultiColumnDropdownMenu = styled(DropdownMenu)`
  flex-direction: row;

  & > *:not(:last-child) {
    border-right: 1px solid ${themeGet('colors.divider.default')};
  }
`
