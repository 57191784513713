import React, { ComponentProps } from 'react'

import styled from 'styled-components'

import { UserCirclePlus } from 'phosphor-react'

import { createGetParam, themeGet } from 'Theme/v2'

const sizes = {
  size: {
    megaLarge: 80,
    extraLarge: 48,
    large: 40,
    default: 32,
    small: 24,
  },
  fontSize: {
    megaLarge: 42,
    extraLarge: 26,
    large: 22,
    default: 18,
    small: 14,
  },
}

interface SizeProps {
  extraLarge?: boolean
  megaLarge?: boolean
  large?: boolean
  small?: boolean
}

const getSizeParam = createGetParam<typeof sizes, SizeProps>(sizes)

const Container = styled.div<SizeProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${getSizeParam('size')}px;
  width: ${getSizeParam('size')}px;
  background: ${themeGet('colors.background.subblock')};
  border: 1px solid ${themeGet('colors.divider.default')};
  border-radius: 999px;
  color: ${themeGet('colors.text.body')};
  font-size: ${getSizeParam('fontSize')}px;
  cursor: pointer;

  &:hover {
    color: ${themeGet('colors.primary500')};
  }
`

type Props = ComponentProps<typeof Container>

function AddAssigneeIcon(props: Props) {
  return (
    <Container {...props}>
      <UserCirclePlus />
    </Container>
  )
}

export default AddAssigneeIcon
