import React, { useCallback, useState } from 'react'
import Collapse from 'react-css-collapse'
import { useHistory, useLocation } from 'react-router'

import { List, SignOut } from 'phosphor-react'

import {
  ApplicationLogo,
  ApplicationLogoKind,
  ContactSalesForm,
} from 'Components/Blocks/_v2'
import { Button, Modal, NavLink, Row } from 'Components/UI/_v2'

import { FRONTEND_URL } from 'Config/app'

import {
  API,
  HOME,
  LOGIN,
  LOGOUT,
  PRICING,
  ROOT,
  SIGN_UP,
} from 'Constants/paths'

import { useAppContext } from 'Hooks'

import { _, useScopedI18n } from 'Services/I18n'

import MobileMenu from './MobileMenu'
import {
  ContactButton,
  Container,
  Content,
  HamburgerButton,
  NavMenu,
  StyledNavLink,
  ViewMobile,
  ViewTablet,
} from './styles'

function Header() {
  const s = useScopedI18n('home.header')

  const history = useHistory()

  const { me } = useAppContext()
  const location = useLocation()
  const locationPath = location.pathname

  const [isContactSalesOpen, setIsContactSalesOpen] = useState(false)
  const [showMobileMenu, setShowMobileMenu] = useState(false)

  const navItems = [
    { name: 'product', to: HOME },
    { name: 'pricing', to: PRICING },
    { name: 'api', to: API },
  ]

  const handleContactSales = useCallback(() => {
    setIsContactSalesOpen(!isContactSalesOpen)
  }, [isContactSalesOpen])

  const handleSignIn = useCallback(() => {
    history.push(LOGIN)
  }, [history])

  const handleSignUp = useCallback(() => {
    history.push(SIGN_UP)
  }, [history])

  const handleGoApp = useCallback(() => {
    window.open(FRONTEND_URL)
  }, [])

  const handleLogout = useCallback(() => {
    history.push(LOGOUT)
  }, [history])

  return (
    <Container>
      <Row center fullWidth justifyCenter>
        <Content>
          <NavLink href={ROOT} to={ROOT}>
            <Row center>
              <ViewTablet>
                <ApplicationLogo
                  height={34}
                  kind={ApplicationLogoKind.WideColor}
                  width={135}
                />
              </ViewTablet>
              <ViewMobile>
                <ApplicationLogo
                  kind={ApplicationLogoKind.NarrowColor}
                  size={34}
                />
              </ViewMobile>
            </Row>
          </NavLink>

          <>
            <NavMenu>
              {navItems.map(item => (
                <StyledNavLink
                  active={item.to === locationPath}
                  key={item.name}
                  to={item.to}
                >
                  {s(item.name)}
                </StyledNavLink>
              ))}
            </NavMenu>

            {!me ? (
              <Row>
                <ContactButton mr={9} onClick={handleContactSales}>
                  {s('contactSales')}
                </ContactButton>

                <Button mr={4} secondary small onClick={handleSignIn}>
                  {s('signIn')}
                </Button>
                <Button small tertiary onClick={handleSignUp}>
                  {s('signUp')}
                </Button>
              </Row>
            ) : (
              <Row>
                <Button small tertiary onClick={handleGoApp}>
                  {s('goToApp')}
                </Button>
                <Button ml={3} secondary small onClick={handleLogout}>
                  <SignOut />
                </Button>
              </Row>
            )}
          </>
        </Content>

        <HamburgerButton
          ml={[7, 11]}
          primary={showMobileMenu}
          onClick={() => setShowMobileMenu(!showMobileMenu)}
        >
          <List size={20} />
        </HamburgerButton>
      </Row>

      <>
        <Collapse isOpen={showMobileMenu}>
          <MobileMenu
            locationPath={locationPath}
            navItems={navItems}
            onClose={() => setShowMobileMenu(false)}
            onContactSales={handleContactSales}
          />
        </Collapse>

        <Modal
          isOpen={isContactSalesOpen}
          title={_('blocks.modals.contactSales.title')}
          onClose={handleContactSales}
        >
          <ContactSalesForm onCancel={handleContactSales} />
        </Modal>
      </>
    </Container>
  )
}

export default Header
