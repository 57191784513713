// @ts-strict-ignore
import { BillingTransferKind } from 'Constants/mainGraphQL'

interface Address {
  address1?: string
  address2?: string
  city?: string
  country?: string
  state?: string
  zip?: string
}

function addressToString(address: Address) {
  const addressParts: string[] = []

  if (address?.address1) addressParts.push(address.address1)
  if (address?.address2) addressParts.push(address.address2)
  if (address?.city) addressParts.push(address.city)
  if (address?.state) addressParts.push(address.state)
  if (address?.zip) addressParts.push(address.zip)
  if (address?.country) addressParts.push(address.country)

  return addressParts.join(', ')
}

function organizationBillingDetailsToAddress({
  companyAddress,
  billingDetails,
  transferKind,
}: {
  companyAddress?: MainSchema.Address
  billingDetails?: MainSchema.OrganizationBillingDetails
  transferKind?: BillingTransferKind
}): MainSchema.Address {
  switch (transferKind) {
    case BillingTransferKind.DomesticWire:
      return {
        address1: billingDetails?.domesticWire?.address1,
        address2: billingDetails?.domesticWire?.address2,
        city: billingDetails?.domesticWire?.city,
        state: billingDetails?.domesticWire?.state,
        zip: billingDetails?.domesticWire?.zip,
        country: billingDetails?.domesticWire?.country,
      }
    case BillingTransferKind.InternationalWire:
      return {
        address1: billingDetails?.internationalWire?.address1,
        address2: billingDetails?.internationalWire?.address2,
        city: billingDetails?.internationalWire?.city,
        state: billingDetails?.internationalWire?.state,
        zip: billingDetails?.internationalWire?.zip,
        country: billingDetails?.internationalWire?.country,
      }
    default:
      return companyAddress
  }
}

export default { addressToString, organizationBillingDetailsToAddress }
