import React, { useMemo } from 'react'

import { Circle, Container, ContainerProps } from './styles'

type Props = ContainerProps & {
  count: number
  active?: boolean
  bordered?: boolean
}

function CounterBadge({
  count,
  secondary,
  active,
  bordered = true,
  small,
  ...rest
}: Props) {
  const caption = useMemo(() => {
    if (count === 0) return ''
    if (count > 99) return '…'
    return count
  }, [count])

  return (
    <Container {...rest} active={active || count > 0}>
      <Circle
        bordered={bordered}
        empty={count === 0}
        secondary={secondary}
        small={small}
      >
        {caption}
      </Circle>
    </Container>
  )
}

export default CounterBadge
