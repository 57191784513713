import { useEffect } from 'react'

import { TIDIO } from 'Config/app'

import { useScript } from 'Hooks'

export function useTidio() {
  // @ts-ignore
  return { tidio: window?.tidioChatApi }
}

export function useInitializeTidio() {
  const [loaded] = useScript(`https://code.tidio.co/${TIDIO.API_KEY}.js`)

  useEffect(() => {
    // @ts-ignore
    if (!loaded || !window?.tidioChatApi) return

    // @ts-ignore
    window.tidioChatApi.display(false)
  }, [loaded])
}
